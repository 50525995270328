import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers';

export interface CreateChannelState {
    active: boolean;
    name: string;
    avatar: File | null;
    avatarSrc: string | null;
}

export const initialState: CreateChannelState = {
    active: false,
    name: '',
    avatar: null,
    avatarSrc: null,
}

export const createChannelSlice = createSlice({
    name: 'createChannel',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    setCreateChannelActive,
    setCreateChannelName,
    setCreateChannelAvatar,
    setCreateChannelAvatarSrc,
    resetCreateChannel
} = createChannelSlice.actions

export default createChannelSlice.reducer

import LoadingSpinner from "../LoadingSpinner";
interface Props {
  loading: boolean;
}
function PaginationLoader({
  loading
}: Props): JSX.Element | null {
  if (!loading) return null;
  return <div className='absolute top-1 left-1/2 -translate-x-1/2 rounded-xl p-4 bg-primary z-50 w-fit flex items-center justify-center py-2'>
            <LoadingSpinner size='5' border='2' spinnerClass='border-light-blue border-t-accent' />
            <span className='ml-2 font-light text-sm text-white'>Loading messages</span>
        </div>;
}
export default PaginationLoader;
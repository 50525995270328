import { type ContactsState } from ".."
import { type ContactMessageData, type DecryptedContactMessageData } from 'src/types/Contact'
import { type User } from "src/types/User";
import { type GroupMessage } from 'src/types/Group';
import { type CallEvent } from "src/types/Ejabberd/Message";

import messageReducers from './messages'
import contactReducers from './contact'
import groupReducers from './group'
import rosterReducers from './roster'
import galleryReducers from './gallery'

export interface AddMessageParams {
    data: ContactMessageData;
    user: User;
}

export interface AddDecryptedMessageParams {
    data: DecryptedContactMessageData;
    user: User;
}

export interface AddGroupMessageParams {
    message: GroupMessage;
    user: User;
}

export interface AddCallEventParams {
    event: CallEvent;
    user: User;
}



const reducers = {
    ...messageReducers,
    ...contactReducers,
    ...groupReducers,
    ...rosterReducers,
    ...galleryReducers,
    startLoading: (state: ContactsState) => {
        state.loading = true
    },
    finishLoading: (state: ContactsState) => {
        state.loading = false
    }
}

export default reducers

import moment from "moment";
import { parseTimestamp } from "src/helpers/message";
import { useContacts } from "src/hooks/store";
import { type DecryptedMessage, type LocationShareAttachment } from "src/types/Message";
import { useCallback, useMemo, useState } from "react";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import MessageDeliveryTicks from "src/components/UI/MessageDeliveryTicks";
declare const google: any;
const containerStyle = {
  width: '100%',
  height: '100%'
};
interface Props {
  message: DecryptedMessage;
}
function LocationShare({
  message
}: Props): JSX.Element | null {
  const {
    activeContact
  } = useContacts();
  const {
    timestamp
  } = message;
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const {
    latitude,
    longitude
  } = (message.attachment as LocationShareAttachment);
  const [, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = useCallback((map: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    });
    map.fitBounds(bounds);
    setMap(map);
    const newMarker = new google.maps.Marker({
      position: {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
      },
      map,
      title: activeContact?.name
    });
    return newMarker;
  }, [activeContact?.name, latitude, longitude]);
  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);
  const renderMap = useMemo(() => {
    return <GoogleMap mapContainerStyle={containerStyle} center={{
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    }} zoom={16} onLoad={onLoad} onUnmount={onUnmount}>
            </GoogleMap>;
  }, [latitude, longitude, onLoad, onUnmount]);
  const redirect = useCallback(() => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
  }, [latitude, longitude]);
  return <div onClick={redirect} className='w-80 h-56 flex flex-col text-xs'>
            <div className='flex items-center justify-between ml-auto text-xs w-full h-full rounded-sm pl-2 pr-1 py-2'>
                {renderMap}
                <div className="relative flex -bottom-1 ml-2 self-end text-2xs">
                    <span className='relative text-gray-400'>{formattedTimestamp}</span>
                    <MessageDeliveryTicks message={message} />
                </div>
            </div>
        </div>;
}
export default LocationShare;
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
interface Props {
  children: React.ReactNode;
  containerId?: string;
}
function Modal({
  children,
  containerId
}: Props): JSX.Element | null {
  const [container, $container] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    const element = containerId !== undefined ? document.getElementById(containerId) : document.body;
    $container(element);
  }, [containerId]);
  if (container === null) return null;
  return createPortal(children, container);
}
export default Modal;
import { useEffect, useState } from "react";
import { defaultAvatar } from "src/constants/contact";
import { type Contact } from "src/types/Contact";
import LoadingImage from "../LoadingImage";
import FallbackAvatar from "../Group/FallbackAvatar";
import LoadingSpinner from "../LoadingSpinner";
interface Props {
  user: Contact;
  onAdd: (user: Contact, username: string) => void;
  onClose: () => void;
  addContactInProgress?: boolean;
}
function AddUserModal({
  user,
  onAdd,
  onClose,
  addContactInProgress
}: Props): JSX.Element | null {
  const [username, $username] = useState<string>(user.name);
  useEffect(() => {
    $username(user.name);
  }, [user]);
  if (user === null) return null;
  return <div className="absolute top-0 left-0 w-full h-screen z-80 bg-modal-overlay">
            <div className="absolute rounded-xl top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-accent rounded shadow w-1/3 z-50 py-10 px-10">
                <div className="relative flex flex-col items-center">
                    {addContactInProgress !== true && <button onClick={onClose} className="absolute top-0 right-0 clickable">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9959 1.00488L1.00586 12.9924" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13 13L1 1" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>}

                    <LoadingImage key={user.jid} url={user.avatar} className='shadow bg-white p-0.5 w-16 h-16 object-cover rounded-xl shrink-0' spinnerSize="16" alt="avatar" {...user.isGroup === true ? {
          fallbackElement: <FallbackAvatar contact={user} />
        } : {
          fallback: defaultAvatar
        }} />
                    {user.isGroup === true && <p className="text-xs mt-2">Public channel</p>}

                    <input type='text' autoFocus value={username} disabled={addContactInProgress === true} onChange={e => {
          $username(e.target.value);
        }} className='shadow rounded my-6 text-center px-3 py-2 text-primary outline-none w-1/2 text-lg font-semibold' />
                    {addContactInProgress === true ? <LoadingSpinner /> : <button disabled={username === ""} onClick={() => {
          onAdd(user, username);
        }} className="bg-primary text-accent px-6 py-2 rounded transition hover:opacity-80">
                        {user.isGroup === true ? 'Join' : 'Add'}
                    </button>}
                </div>
            </div>
        </div>;
}
export default AddUserModal;
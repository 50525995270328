import { type PayloadAction } from "@reduxjs/toolkit"
import { type User } from "src/types/User"
import { initialState, type UserState } from ".."

const reducers = {
    setUser: (state: UserState, action: PayloadAction<User>) => {
        return {
            user: action.payload
        }
    },
    setUserData: (state: UserState, action: PayloadAction<Partial<User>>) => {
        if (state.user === null) throw new Error('User is null')

        return {
            user: {
                ...state.user,
                ...action.payload
            }
        }
    },
    resetUserState: (state: UserState) => {
        return initialState
    }
}

export default reducers

import { type MessageType } from 'src/types/Ejabberd/MessageType';
import { type MessageParameters } from '../Message'
import { type CallFormat } from '../call';

export interface EncryptedMessage extends MessageParameters {
    t: MessageType;     // type
    mid: string;        // id
    qid?: string;       // replied message id
    fid: string;        // from id
    tid: string;        // to id
    c?: string;         // content
    mc?: string;        // my content
    // at?: {
    //     md?: {
    //         n?: string; // name
    //         w?: string; // width
    //         h?: string; // height
    //         s?: string; // size
    //         d?: string; // duration
    //     },
    //     tb?: string; // thumbnail
    //     dt: string; // data
    //     mdt?: string; // my data
    // };
    at?: any;
    //
    uk: string;         // user key
    ut?: number;        // user type
    sk?: string;         // server key
    ts: string;         // timestamp
    props?: string;     // properties,
}

export interface EncryptedGroupMessage extends MessageParameters {
    t: MessageType;     // type
    mid: string;        // id
    qid?: string;       // replied message id
    fid: string;        // from id
    tid: string;        // to id
    c?: string;         // content
    sk?: string;         // user key
    at?: any,
    ts: string;         // timestamp
}

export interface CallEvent {
    t: MessageType;
    c: string;
    cl: {
        id: string;
        t: CallFormat;
        d?: {
            e: string;
            s: string;
        }
    }
    fid: string;
    tid: string;
    mid: string;
    ts: string;
}

export interface GroupEvent {
    t: MessageType;
    c: string;
    fid: string;
    tid: string;
    tgid: string;
    mid: string;
    ts: string;
    props?: string;
}

export enum GroupEventTypes {
    USER_REMOVED_BY_ADMIN = 'USER_REMOVED_BY_ADMIN',
    USER_ADDED = 'USER_ADDED',
    GROUP_CREATION = 'GROUP_CREATION',
    GROUP_CALL_ENDED = 'GROUP_CALL_ENDED',
    GROUP_CALL_STARTED = 'GROUP_CALL_STARTED',
    UNKNOWN = 'UNKNOWN',
    ADMIN_SET = 'ADMIN_SET',
    USER_LEFT_GROUP = 'USER_LEFT_GROUP',
    GROUP_SETTINGS_CHANGED = 'SETTINGS_CHANGED',
    ADMIN_REMOVED = 'ADMIN_REMOVED',
    JOINED_VIA_LINK = 'JOINED_VIA_LINK',
    JOINED = 'JOINED',
    USER_KICKED = 'USER_KICKED',
    CREATED = 'CREATED',
}

export enum CallEventTypes {
    STARTED = 'STARTED',
    MISSED = 'MISSED',
    ENDED = 'ENDED',
    REJECTED = 'REJECTED',
    ONGOING = 'ONGOING',

}

export type GroupEventType = `${GroupEventTypes}`
export type CallEventType = `${CallEventTypes}`

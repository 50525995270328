import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { strLimit } from 'src/helpers/common';
import { changeContactChatSetting, fetchContactChatSettings, getNodeFromJid } from 'src/helpers/contact';
import { parseTimestamp } from 'src/helpers/message';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { type RootState } from 'src/store';
import { type DecryptedMessage } from 'src/types/Message';
import { type Contact, type ChatSettingType, type ParsedChatSettings } from 'src/types/Contact';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import LoadingImage from '../LoadingImage';
import { clearMessages, removeContact, setActiveChatSettings } from 'src/store/slices/contacts';
import { xmpp } from 'src/constants/xmpp';
import ContextMenu, { type ContextMenuItem } from '../ContextMenu';
import clsx from 'clsx';
import LastMessageDetails from '../UI/LastMessageDetails';
interface Props {
  onPress: () => void;
  contact: Contact;
  isSelected?: boolean;
}
function ContactItem({
  contact,
  isSelected,
  onPress
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const id = getNodeFromJid(contact.jid);
  const [chatSettings, $chatSettings] = useState<ParsedChatSettings | null>(null);
  const {
    deletedMessages,
    messages,
    rosterState,
    activeChatSettings
  } = useAppSelector((state: RootState) => state.contacts);
  const lastMessages = useMemo(() => messages[id] ?? [], [id, messages]);
  const contactDeletedMessages = useMemo(() => deletedMessages[id] ?? [], [deletedMessages, id]);
  const filteredMessages = useMemo(() => lastMessages.filter((message: DecryptedMessage) => !contactDeletedMessages.includes(message.id)), [lastMessages, contactDeletedMessages]);
  const lastMessage = filteredMessages.length > 0 ? filteredMessages[filteredMessages.length - 1] : null;
  const timestamp = lastMessage !== null && moment.unix(parseTimestamp(lastMessage.timestamp)).fromNow();
  const isTyping = rosterState[id]?.typing;
  const unreadMessages = useMemo(() => {
    return lastMessages.filter(message => message.isRead === false && message.received);
  }, [lastMessages]);

  // const lastUnreadMessage = useMemo(() => {
  //     return unreadMessages.length > 0 ? unreadMessages[unreadMessages.length - 1] : null
  // }, [unreadMessages])

  const getLastMessage = useMemo(() => {
    // const isPinned = Math.floor(Math.random() * 5) === 1
    // const unReadCount = Math.floor(Math.random() * 3)

    // if (isPinned) return (
    //     <div className='flex items-center justify-between'>
    //         <span className='text-xs text-primary-light group-hover:text-hover-text'>Lorem ipsum..</span>
    //         <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    //             <path d="M20.824 9.22822L14.7723 3.176C14.6877 3.09187 14.5801 3.03463 14.463 3.01149C14.346 2.98835 14.2247 3.00033 14.1144 3.04593C14.0041 3.09153 13.9098 3.16871 13.8433 3.26777C13.7768 3.36683 13.741 3.48335 13.7405 3.60268V5.36993L7.831 8.74707H4.96552C4.8462 8.74757 4.72969 8.78333 4.63063 8.84987C4.53158 8.91641 4.45441 9.01074 4.40881 9.12102C4.36322 9.2313 4.35124 9.35259 4.37438 9.46966C4.39752 9.58673 4.45475 9.69434 4.53887 9.77897L8.22436 13.4678L3.1258 20.0254C3.03754 20.1399 2.99321 20.2823 3.00084 20.4267C3.00848 20.5711 3.06757 20.7081 3.16742 20.8127C3.26727 20.9173 3.40127 20.9827 3.54516 20.997C3.68906 21.0114 3.83333 20.9737 3.95186 20.8908L10.8236 16.0672L14.2216 19.4625C14.3062 19.5467 14.4138 19.6039 14.5309 19.627C14.6479 19.6502 14.7692 19.6382 14.8795 19.5926C14.9898 19.547 15.0841 19.4698 15.1506 19.3708C15.2172 19.2717 15.2529 19.1552 15.2534 19.0358V15.8736L18.6212 10.2601H20.3974C20.5167 10.2596 20.6332 10.2239 20.7323 10.1573C20.8313 10.0908 20.9085 9.99644 20.9541 9.88617C20.9997 9.77589 21.0117 9.65459 20.9885 9.53753C20.9654 9.42046 20.9081 9.31285 20.824 9.22822V9.22822ZM6.56014 17.5833L9.08976 14.3333L9.95515 15.1987L6.56014 17.5833ZM18.2793 9.04968C18.1742 9.05084 18.0712 9.07936 17.9805 9.13242C17.8897 9.18548 17.8144 9.26126 17.7619 9.35229L14.1308 15.4045C14.0754 15.4959 14.0451 15.6003 14.0431 15.7071V17.5742L6.427 9.95751H7.99137C8.09738 9.95824 8.20173 9.93111 8.29396 9.87883L14.6482 6.2475C14.7406 6.19418 14.8172 6.1174 14.8704 6.02493C14.9235 5.93247 14.9513 5.8276 14.9508 5.72096V5.06429L18.9359 9.04968H18.2793Z" fill="#121212" />
    //         </svg>

    //     </div>
    // )

    // if (unReadCount > 0) return (
    //     <div className='flex items-center justify-between'>
    //         <span className='text-xs text-primary-light group-hover:text-hover-text'>Lorem ipsum..</span>
    //         <div className="bg-primary flex items-center font-bold justify-center text-white text-2xs rounded-full w-5 h-5">
    //             {unReadCount}
    //         </div>
    //     </div>
    // )

    if (isTyping) return <div className='flex items-center'>
                <i className='bi bi-chat-left-dots text-primary-light group-hover:text-hover-text text-xs mr-1' />
                <span className='text-xs text-primary-light group-hover:text-hover-text'>Typing...</span>
            </div>;else if (lastMessage === null) return <div className='flex items-center'>
                <span className='text-xs text-primary-light group-hover:text-hover-text'>No messages yet</span>
            </div>;else if (MessageType.TEXT === lastMessage.type && lastMessage.content !== undefined) return <div className='flex items-center justify-between'>
                <span className={clsx('text-xs group-hover:text-hover-text', unreadMessages.length > 0 && lastMessage.id === unreadMessages[unreadMessages.length - 1]?.id ? 'text-primary' : 'text-primary-light')}>{strLimit(lastMessage.content, 30)}</span>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.IMAGE === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <i className='bi bi-image-fill text-primary-light group-hover:text-hover-text text-xs mr-1' />
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>Image</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.GIF === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <i className='bi bi-image-fill text-primary-light group-hover:text-hover-text text-xs mr-1' />
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>GIF</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.VIDEO === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <svg className='w-4 h-4 mr-1' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.15 6.17C20.74 5.95 19.88 5.72 18.71 6.54L17.24 7.58C17.13 4.47 15.78 3.25 12.5 3.25H6.5C3.08 3.25 1.75 4.58 1.75 8V16C1.75 18.3 3 20.75 6.5 20.75H12.5C15.78 20.75 17.13 19.53 17.24 16.42L18.71 17.46C19.33 17.9 19.87 18.04 20.3 18.04C20.67 18.04 20.96 17.93 21.15 17.83C21.56 17.62 22.25 17.05 22.25 15.62V8.38C22.25 6.95 21.56 6.38 21.15 6.17ZM11 11.38C9.97 11.38 9.12 10.54 9.12 9.5C9.12 8.46 9.97 7.62 11 7.62C12.03 7.62 12.88 8.46 12.88 9.5C12.88 10.54 12.03 11.38 11 11.38Z" fill="#8F8F8F" />
                    </svg>
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>Video</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.LOCATION === lastMessage.type || MessageType.LIVE_LOCATION === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <i className='bi bi-pin-map-fill text-primary-light group-hover:text-hover-text text-sm mr-1' />
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>Location</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.FILE === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <i className='bi bi-file-binary text-primary-light group-hover:text-hover-text text-xs mr-1' />
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>File</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.CONTACT === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <i className='bi bi-person-lines-fill text-primary-light group-hover:text-hover-text text-sm mr-1' />
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>Contact</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.VOICE === lastMessage.type) return <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <i className='bi bi-mic-fill text-primary-light group-hover:text-hover-text text-xs mr-1' />
                    <span className='text-xs text-primary-light group-hover:text-hover-text'>Voice message</span>
                </div>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;else if (MessageType.CALL_EVENT === lastMessage.type) return <div className='flex items-center justify-between'>
                <span className='text-xs text-primary-light group-hover:text-hover-text'>{lastMessage.content}</span>
                <LastMessageDetails message={lastMessage} unreadMessagesLength={unreadMessages.length} />
            </div>;
  }, [isTyping, lastMessage, unreadMessages]);
  const contextCallback = useCallback((event: MouseEvent) => {
    void fetchContactChatSettings(contact).then(data => {
      if (data !== false) {
        $chatSettings(data);
        dispatch(setActiveChatSettings(data));
      }
    });
  }, [contact, dispatch]);
  const changeFetchChatSetting = useCallback(async (type: ChatSettingType, value: boolean) => {
    if (activeChatSettings === null) return;
    await changeContactChatSetting(contact, type, value);
    const newSettings: ParsedChatSettings = {
      ...activeChatSettings,
      from: {
        ...activeChatSettings.from,
        [type]: value
      }
    };
    $chatSettings(newSettings);
    dispatch(setActiveChatSettings(newSettings));
  }, [activeChatSettings, contact, dispatch]);
  const deleteContact = useCallback(async () => {
    xmpp.connection.roster.unsubscribe(contact.jid);
    xmpp.connection.roster.remove(contact.jid);
    dispatch(removeContact(contact.jid));
  }, [contact.jid, dispatch]);
  const clearChat = useCallback(async () => {
    dispatch(clearMessages(contact.jid));
  }, [contact.jid, dispatch]);
  const contextItems = useMemo((): ContextMenuItem[] => {
    if (chatSettings === null) return [{
      label: 'Delete contact',
      onClick: () => {
        void deleteContact();
      }
    }, {
      label: 'Clear chat',
      onClick: () => {
        void clearChat();
      }
    }];
    const isNotificationEnabled = chatSettings.from.NOTIFICATION;
    const isBlocked = chatSettings.from.BLOCK;
    const isReadStatusActive = chatSettings.from.READ_RECEIPT;
    return [{
      label: isNotificationEnabled ? 'Mute' : 'Unmute',
      onClick: () => {
        void changeFetchChatSetting("NOTIFICATION", !isNotificationEnabled);
      }
    }, {
      label: isBlocked ? 'Unblock' : 'Block',
      onClick: () => {
        void changeFetchChatSetting("BLOCK", !isBlocked);
      }
    }, {
      label: isReadStatusActive ? 'Disable read status' : 'Enable read status',
      onClick: () => {
        void changeFetchChatSetting("READ_RECEIPT", !isReadStatusActive);
      }
    }, {
      label: 'Delete contact',
      onClick: () => {
        void deleteContact();
      }
    }, {
      label: 'Clear chat',
      onClick: () => {
        void clearChat();
      }
    }];
  }, [changeFetchChatSetting, chatSettings, deleteContact, clearChat]);
  return <ContextMenu width={48} callback={contextCallback} items={contextItems} onClick={onPress} className={clsx('group flex items-center px-3 py-2 transition rounded-lg cursor-pointer [content-visibility: auto]', isSelected === true && 'bg-gray-200', isSelected === true ? 'hover:bg-gray-200' : 'hover:bg-hover-bg')}>
            <LoadingImage url={contact.avatar} fallbackElement={<div className='shadow bg-white p-0.5 w-16 h-16 rounded-xl shrink-0 object-cover'>
                    <svg fill="#333E5B" className='w-full h-full' viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                        <path d="M 28.0117 27.3672 C 33.0508 27.3672 37.3867 22.8672 37.3867 17.0078 C 37.3867 11.2187 33.0274 6.9297 28.0117 6.9297 C 22.9961 6.9297 18.6367 11.3125 18.6367 17.0547 C 18.6367 22.8672 22.9961 27.3672 28.0117 27.3672 Z M 13.2930 49.0703 L 42.7305 49.0703 C 46.4101 49.0703 47.7226 48.0156 47.7226 45.9531 C 47.7226 39.9062 40.1523 31.5625 28.0117 31.5625 C 15.8477 31.5625 8.2774 39.9062 8.2774 45.9531 C 8.2774 48.0156 9.5898 49.0703 13.2930 49.0703 Z" />
                    </svg>
                </div>} className='shadow bg-white p-0.5 w-16 h-16 rounded-xl shrink-0 object-cover' spinnerSize='16' alt="avatar" />

            <div className="flex flex-col w-full ml-3">
                <div className="flex items-center justify-between">
                    <span className='text-primary font-semibold'>{contact.name}</span>
                    <span className='whitespace-nowrap text-description text-2xs'>{timestamp}</span>
                </div>
                {getLastMessage}
            </div>
        </ContextMenu>;
}
export default ContactItem;
import { useCallback, useRef, useState } from "react";
import { useAppDispatch, useCreateChannel, useUser } from "src/hooks/store";
import { addPendingGroup, generateGroupSecret } from "src/helpers/group";
import API from "src/services/Api";
import { xmpp } from 'src/constants/xmpp';
import { type CreateGroupResponse } from "src/types/Group";
import clsx from "clsx";
import EmojiPicker from "src/components/EmojiPicker";
import { useEmoji } from "src/contexts/Emoji";
import { setActiveGroup } from "src/store/slices/contacts";
import LoadingSpinner from "src/components/LoadingSpinner";
import { resetCreateChannel, setCreateChannelActive, setCreateChannelAvatar, setCreateChannelAvatarSrc, setCreateChannelName } from "src/store/slices/createChannel";
import { ContactTypes } from "src/types/Contact";
function ChannelCreate(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const avatarRef = useRef<HTMLInputElement>(null);
  const {
    user
  } = useUser();
  const emojiData = useEmoji();
  const createChannel = useCreateChannel();
  const [loading, $loading] = useState<boolean>(false);
  const [showEmoji, $showEmoji] = useState<boolean>(false);
  const onChangeAvatar = useCallback((e: any) => {
    if (e.target.files[0] === null) return;
    const avatar: File = e.target.files[0];
    const src = URL.createObjectURL(avatar);
    dispatch(setCreateChannelAvatar(avatar));
    dispatch(setCreateChannelAvatarSrc(src));
  }, [dispatch]);
  const onChangeName = useCallback((e: any) => {
    dispatch(setCreateChannelName(e.target.value));
  }, [dispatch]);
  const onPressBack = useCallback(() => {
    dispatch(setCreateChannelActive(false));
    dispatch(setCreateChannelName(""));
    dispatch(setCreateChannelAvatar(null));
    dispatch(setCreateChannelAvatarSrc(null));
  }, [dispatch]);
  const onEmojiSelect = useCallback((emoji: any) => {
    dispatch(setCreateChannelName(createChannel.name + (emoji.native as string)));
  }, [createChannel.name, dispatch]);
  const onCreateChannel = useCallback(async (channel: CreateGroupResponse) => {
    if (xmpp.connection === undefined || user === null) return;
    const {
      id
    } = channel;
    const addedChannel = await addPendingGroup(dispatch, user, id);
    if (addedChannel === undefined) return;
    dispatch(resetCreateChannel());
    dispatch(setActiveGroup(addedChannel));
  }, [dispatch, user]);
  const postCreateChannel = useCallback(async () => {
    try {
      if (loading) return;
      $loading(true);
      const secret = generateGroupSecret();
      if (secret === false) return;
      const payload = new FormData();
      payload.append('name', createChannel.name);
      payload.append('type', ContactTypes.CHANNEL);
      payload.append('secret', secret);
      payload.append('photo', (createChannel.avatar as Blob));
      console.log('[CREATE CHANNEL] payload', {
        name: createChannel.name,
        secret,
        photo: createChannel.avatar
      });
      const response = await API.post<CreateGroupResponse>('/muc/rooms', payload, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.status === 201) {
        await onCreateChannel(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      $loading(false);
    }
  }, [createChannel.avatar, createChannel.name, loading, onCreateChannel]);
  const pickAvatar = useCallback(() => {
    if (avatarRef.current === null) return;
    avatarRef.current.click();
  }, []);
  if (!createChannel.active) return null;
  return <div className="relative w-[25%] h-screen min-w-[300px] shrink-0 overflow-hidden bg-white">

            <div className='p-4 flex items-center'>
                <button onClick={onPressBack} className='transition hover:opacity-80'>
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none">
                        <path d="M1.25 7.27441L16.25 7.27441" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.2998 13.2988L1.2498 7.27476L7.2998 1.24976" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <span className='ml-5 font-bold text-xl'>New channel</span>
            </div>

            <div className={clsx('group transition hover:opacity-80 cursor-pointer relative w-48 h-48 mx-auto my-10 rounded-full flex items-center justify-center', createChannel.avatarSrc !== null ? 'bg-white' : 'bg-avatar-placeholder')}>
                {createChannel.avatarSrc !== null ? <>
                        <img className='rounded-full w-48 h-48 cursor-pointer object-cover transition hover:opacity-80' src={createChannel.avatarSrc} alt='channel avatar' onClick={() => avatarRef.current?.click()} />
                        <div className="absolute hidden group-hover:flex top-0 left-0 w-full h-full center-flex">
                            <button onClick={pickAvatar} className="w-10 h-10 rounded-full bg-primary shadow clickable">
                                <i className="bi bi-pencil text-accent"></i>
                            </button>
                        </div>
                    </> : <div className="flex flex-col items-center" onClick={pickAvatar}>
                        <svg width="30" height="27" viewBox="0 0 30 27" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.5602 2.07701C21.0752 2.68001 21.5387 4.78001 22.1582 5.45501C22.7777 6.13001 23.6642 6.35951 24.1547 6.35951C26.7617 6.35951 28.8752 8.47301 28.8752 11.0785V19.771C28.8752 23.266 26.0402 26.101 22.5452 26.101H7.45524C3.95874 26.101 1.12524 23.266 1.12524 19.771V11.0785C1.12524 8.47301 3.23874 6.35951 5.84574 6.35951C6.33474 6.35951 7.22124 6.13001 7.84224 5.45501C8.46174 4.78001 8.92374 2.68001 10.4387 2.07701C11.9552 1.47401 18.0452 1.47401 19.5602 2.07701Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M23.2432 10.25H23.2567" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M19.7682 15.6921C19.7682 13.0581 17.6337 10.9236 14.9997 10.9236C12.3657 10.9236 10.2312 13.0581 10.2312 15.6921C10.2312 18.3261 12.3657 20.4606 14.9997 20.4606C17.6337 20.4606 19.7682 18.3261 19.7682 15.6921Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div className='w-[100px] text-white mt-3 text-center font-bold text-sm'>ADD CHANNEL PICTURE</div>
                    </div>}
            </div>

            <div className='flex flex-col px-6 mb-4'>
                <span className='text-hover-text text-sm mb-1'>Channel Subject</span>
                <div className="relative flex items-center border-b pb-2">
                    <input value={createChannel.name} onChange={onChangeName} type='text' placeholder='' className='w-full text-sm outline-none' maxLength={25} />
                    <span className='text-xs mr-2 text-hover-text'>{25 - createChannel.name.length}</span>
                    <button className='w-5 h-5 cursor-pointer transition hover:opacity-80' onClick={() => {
          $showEmoji(prev => !prev);
        }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9941 4.47962 15.5204 0.00590124 10 0ZM10 18.5714C5.26612 18.5714 1.42856 14.7339 1.42856 10C1.42856 5.26612 5.26612 1.42856 10 1.42856C14.7339 1.42856 18.5714 5.26612 18.5714 10C18.5663 14.7318 14.7318 18.5663 10 18.5714Z" fill="#1A1F27" fillOpacity="0.4" />
                            <path d="M6.42856 10.0007C7.21754 10.0007 7.85712 9.36109 7.85712 8.57211C7.85712 7.78314 7.21754 7.14355 6.42856 7.14355C5.63959 7.14355 5 7.78314 5 8.57211C5 9.36109 5.63959 10.0007 6.42856 10.0007Z" fill="#1A1F27" fillOpacity="0.4" />
                            <path d="M13.5721 10.0007C14.3611 10.0007 15.0007 9.36109 15.0007 8.57211C15.0007 7.78314 14.3611 7.14355 13.5721 7.14355C12.7831 7.14355 12.1436 7.78314 12.1436 8.57211C12.1436 9.36109 12.7831 10.0007 13.5721 10.0007Z" fill="#1A1F27" fillOpacity="0.4" />
                            <path d="M12.7354 12.4136C12.4995 12.0974 12.0519 12.0324 11.7358 12.2684C11.7221 12.2786 11.7088 12.2892 11.6959 12.3004C10.6858 13.0382 9.31454 13.0382 8.30442 12.3004C8.00597 12.0424 7.55488 12.0753 7.29694 12.3737C7.03896 12.6722 7.07182 13.1233 7.37027 13.3812C7.38316 13.3924 7.39647 13.4031 7.41016 13.4133C8.93908 14.5819 11.0612 14.5819 12.5902 13.4133C12.9064 13.1773 12.9714 12.7297 12.7354 12.4136Z" fill="#1A1F27" fillOpacity="0.4" />
                        </svg>
                    </button>

                    {showEmoji && <div className='absolute top-10 right-0 h-72 z-0 overflow-hidden'>
                        <EmojiPicker data={emojiData} onEmojiSelect={onEmojiSelect} maxFrequentRows={1} theme='auto' perLine={8} previewEmoji='none' previewPosition='none' />
                    </div>}
                </div>
            </div>
            <input type='file' accept='image/*' className='hidden' ref={avatarRef} onChange={onChangeAvatar} />

            {loading && <LoadingSpinner wrapperClass="absolute right-1/2 top-1/2 -translate-y-1/2" size="12" />}

            {!loading && <div className="flex justify-center mt-6">
                <button className='transition hover:opacity-80' onClick={postCreateChannel}>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <rect x="50" y="50" width="50" height="50" rx="25" transform="rotate(-180 50 50)" fill="#121212" />
                        <path d="M36.5 18.5L20 32L14 25" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>}
        </div>;
}
export default ChannelCreate;
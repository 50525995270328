import { createSlice } from '@reduxjs/toolkit'
import { type Contact, type ParsedChatSettings } from 'src/types/Contact'
import { type DecryptedMessage } from 'src/types/Message';
import { type RosterState } from 'src/types/Roster';
import { type GroupMessage } from 'src/types/Group';

import reducers from './reducers'

export type MessagesState = Record<string | number, DecryptedMessage[]>;

export type PendingGroupMessagesState = Record<string | number, GroupMessage[]>;

export interface ContactsState {
    contacts: Contact[];

    messages: MessagesState,
    pendingGroupMessages: PendingGroupMessagesState,
    threadMessageId: string | null,
    threadGroupId: string | null,

    activeContact: Contact | null;
    activeGroup: Contact | null,
    activeChatSettings: ParsedChatSettings | null,

    paginationLimitedContacts: string[];

    showContactInfo: boolean;
    groupMenu: boolean,
    groupAddMember: boolean,

    rosterState: Record<string, RosterState>,
    pendingRosters: string[],

    galleryItems: DecryptedMessage[];
    mainGalleryItem: DecryptedMessage | null;

    deletedMessages: Record<string | number, string[]>,

    loading: boolean;
}

export const initialState: ContactsState = {
    contacts: [],

    messages: {},
    pendingGroupMessages: {},
    threadMessageId: null,
    threadGroupId: null,

    activeContact: null,
    activeGroup: null,
    activeChatSettings: null,

    paginationLimitedContacts: [],

    showContactInfo: false,
    groupMenu: false,
    groupAddMember: false,

    rosterState: {},
    pendingRosters: [],

    galleryItems: [],
    mainGalleryItem: null,

    deletedMessages: {},

    loading: true,
}


export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    setContacts,
    setContactData,
    setRosterState,
    resetContacts,
    resetPendingRosters,
    setActiveContact,
    setActiveGroup,
    updateContact,
    updateGroupContact,
    addContact,
    addPaginationLimitedContact,
    removePaginationLimitedContacts,
    setShowContactInfo,
    removeContact,
    forceRemoveContact,
    addDeletedMessage,
    deleteMessage,
    removeDeletedMessage,
    sortContacts,
    syncContacts,
    addMessage,
    updateMessage,
    updateMessageDeliveryStatuses,
    addDecryptedMessage,
    addGroup,
    fetchContactMessages,
    setGroupThread,
    removeGroup,
    leaveGroup,
    addExistingGroup,
    addGroupMember,
    updateGroupMember,
    removeGroupMember,
    setGroupMenu,
    setGroupAddMember,
    addGroupMessage,
    addGroupEvent,
    startLoading,
    finishLoading,
    updateGroup,
    updateGroupMembers,
    setActiveChatSettings,
    resetContactState,
    updateMessageUploadingStatus,
    updateMessageDeliveryData,
    updateMessageDeliveryStatusesForGroup,
    readAllContactMessages,
    readAllGroupMessages,
    showGallery,
    hideGallery,
    clearMessages,
    fixDateHeaders,
    fixGroupDateHeaders,
    deleteGroupMessage,
    updateMessageUploadingStatusForGroup,
    updateGroupMessage,
    addCallEvent,
    addGroupCallEvent,
    sendReceivedEvent,
} = contactsSlice.actions

export default contactsSlice.reducer

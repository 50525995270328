import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import AgoraRTC, { type ClientConfig, type ILocalAudioTrack, type ILocalTrack, type ILocalVideoTrack, type IRemoteAudioTrack, type IRemoteVideoTrack, type UID, type IRemoteTrack } from "agora-rtc-sdk-ng";
import { useAppDispatch, useCall, useContacts } from "src/hooks/store";
import API from "src/services/Api";
import clsx from "clsx";
import { resetCallState } from "src/store/slices/call";
import { CallFormats, type CallType, CallTypes } from "src/types/call";
import { toast } from "react-hot-toast";
import { getNodeFromJid } from "src/helpers/contact";
import { AGORA_APP_ID } from "src/constants/agora";
import { xmpp } from "src/constants/xmpp";
import { useEventBus } from "src/services/EventBus";
import { UupEvents } from "src/constants/events";
import LoadingImage from '../LoadingImage';
interface ChannelParameters {
  localAudioTrack: ILocalAudioTrack | null;
  localVideoTrack: ILocalVideoTrack | null;
  remoteAudioTrack: IRemoteAudioTrack | null;
  remoteVideoTrack: IRemoteVideoTrack | null;
  remoteUid: UID | null;
}
const clientOptions: ClientConfig = {
  mode: "live",
  codec: "h264",
  role: 'host'
};
AgoraRTC.setLogLevel(4);
const callRingtone = new Audio(require('src/media/audio/ringtone.wav'));
function ContactAgoraCall(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const client = useMemo(() => AgoraRTC.createClient(clientOptions), []);
  const {
    format,
    type,
    channelId,
    caller,
    callId: _callId
  } = useCall();
  const {
    contacts
  } = useContacts();
  const isVideoCall = format === CallFormats.VIDEO;
  const [status, $status] = useState<CallType | null>(type);
  const [isAudioEnabled, $isAudioEnabled] = useState(true);
  const [isCameraEnabled, $isCameraEnabled] = useState(isVideoCall);
  const [callTime, $callTime] = useState(0);
  const [callId, $callId] = useState(_callId);
  const channelParameters = useRef<ChannelParameters>({
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
    remoteUid: null
  });
  const contact = contacts.find(contact => getNodeFromJid(contact.jid) === caller?.id);
  const localPlayer = useRef<HTMLDivElement>(null);
  const remotePlayer = useRef<HTMLDivElement>(null);
  const callTimer = useRef<NodeJS.Timeout | null>(null);
  const getRTCToken = useCallback(async (channel: string) => {
    try {
      const uid = 0;
      const response = await API.post<{
        token: string;
      }>('/agora/rtc-tokens', {
        channelName: channel,
        uid
      });
      return {
        token: response.data.token,
        uid
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);
  const sendNotification = useCallback(async () => {
    if (caller === null) return null;
    const response = await API.post<{
      channel: string;
      id: string;
    }>('/notification/calls', {
      type: format,
      to: [caller.id],
      production: true
    });
    const {
      data
    } = response;
    console.log('[AGORA] NOTIFICATION SENT', data);
    $callId(data.id);
    return data;
  }, [caller, format]);
  useEventBus(UupEvents.CALL_ENDED, () => {
    leave().catch(console.error);
    callRingtone.pause();
  });
  const leave = useCallback(async () => {
    console.log("[AGORA] You left the call");
    channelParameters.current.localAudioTrack?.close();
    channelParameters.current.localVideoTrack?.close();
    await client.leave();
    callRingtone.pause();
    dispatch(resetCallState());
  }, [client, dispatch]);
  const cancelCall = useCallback(async () => {
    if (callId !== null) {
      await API.post(`/notification/calls/${callId}/cancel`, {}).catch(console.error);
    }
    leave().catch(console.error);
  }, [callId, leave]);
  const clearCallTimer = useCallback(() => {
    if (callTimer.current === null) return;
    clearInterval(callTimer.current);
    callTimer.current = null;
  }, []);
  const playLocalVideo = useCallback(() => {
    if (isVideoCall && localPlayer.current != null && channelParameters.current.localVideoTrack != null) {
      channelParameters.current.localVideoTrack.play(localPlayer.current, {
        fit: 'cover'
      });
    }
  }, [isVideoCall]);
  const activateCallTimer = useCallback(() => {
    clearCallTimer();
    $callTime(0);
    callTimer.current = setInterval(() => {
      $callTime(prev => prev + 1);
    }, 1000);
  }, [clearCallTimer]);
  const enableLocalStream = useCallback(async () => {
    const channels: ILocalTrack[] = [];
    let localAudioTrack: ILocalAudioTrack | null = null;
    let localVideoTrack: ILocalVideoTrack | null = null;
    localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    if (isVideoCall) {
      localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      channels.push(localVideoTrack);
    }
    channels.push(localAudioTrack);
    channelParameters.current = {
      ...channelParameters.current,
      localAudioTrack,
      localVideoTrack
    };
    await client.publish(channels);
    playLocalVideo();
  }, [client, isVideoCall, playLocalVideo]);
  const join = useCallback(async (isOutgoing: boolean = false) => {
    console.log('joining', client, channelId, client?.connectionState, isOutgoing);
    let _channelId = channelId;
    if (isOutgoing) {
      const response = await sendNotification();
      if (response === null) return;
      _channelId = response.channel;
    }
    if (client === null || _channelId === null || client.connectionState !== 'DISCONNECTED') return;
    const rtcData = await getRTCToken(_channelId);
    if (rtcData === null) return;
    const token = rtcData.token;
    const uid = getNodeFromJid(xmpp.connection.jid);
    client.on('connection-state-change', (curState, reason) => {
      console.log('[AGORA]', 'Status: ', curState, reason);
    });
    client.on("user-unpublished", user => {
      console.log('[AGORA]', "User unpublished: ", user.uid);
      clearCallTimer();
    });
    client.on('user-joined', user => {
      console.log('[AGORA]', user.uid, "joined");
      $status(CallTypes.ON_CALL);
      void enableLocalStream();
      activateCallTimer();
    });
    client.on('user-left', user => {
      console.log('[AGORA]', "User left: ", user.uid);
      clearCallTimer();
      toast('Call ended');
      void leave();
    });
    client.on("user-published", (user, mediaType) => {
      console.log('[AGORA] USER PUBLISHED', mediaType);
      client.subscribe(user, mediaType).then((track: IRemoteTrack) => {
        if (mediaType === "audio") {
          if (user.audioTrack !== undefined) {
            console.log("[AGORA]", 'User connected to AUDIO channel:', user.uid);
            channelParameters.current.remoteUid = user.uid;
            channelParameters.current.remoteAudioTrack = user.audioTrack;
            channelParameters.current.remoteAudioTrack.play();
          }
        }
        if (mediaType === "video") {
          console.log("[AGORA]", 'User connected to VIDEO channel:', user.uid, user.videoTrack);
          if (user.videoTrack !== undefined) {
            channelParameters.current.remoteUid = user.uid.toString();
            channelParameters.current.remoteVideoTrack = user.videoTrack;
            if (remotePlayer.current != null) {
              channelParameters.current.remoteVideoTrack.play(remotePlayer.current, {
                fit: 'contain'
              });
            }
          }
        }
      }).catch(console.error);
    });
    await client.join(AGORA_APP_ID, _channelId, token, uid).catch(console.error);
  }, [activateCallTimer, channelId, clearCallTimer, client, enableLocalStream, getRTCToken, leave, sendNotification]);
  const toggleAudio = useCallback(async () => {
    if (channelParameters.current.localAudioTrack === null) return;
    const changed = !isAudioEnabled;
    console.log("[AGORA] Audio toggled", changed);
    await channelParameters.current.localAudioTrack.setEnabled(changed);
    $isAudioEnabled(changed);
  }, [isAudioEnabled]);
  const toggleVideo = useCallback(async () => {
    if (channelParameters.current.localVideoTrack === null) return;
    const changed = !isCameraEnabled;
    console.log("[AGORA] Video toggled", changed);
    await channelParameters.current.localVideoTrack.setEnabled(changed);
    $isCameraEnabled(changed);
    if (changed && localPlayer.current != null && channelParameters.current.localVideoTrack != null) {
      channelParameters.current.localVideoTrack.play(localPlayer.current, {
        fit: 'cover'
      });
    }
  }, [isCameraEnabled]);
  const setupOutgoingCall = useCallback(async () => {
    const isOutgoing = type === CallTypes.OUTGOING;
    if (isOutgoing && status !== CallTypes.ON_CALL) {
      void join(isOutgoing);
    }
  }, [join, status, type]);
  useEffect(() => {
    console.log('SETUP CALL');
    setupOutgoingCall().catch(console.error);
  }, [setupOutgoingCall]);
  const getAvatar = useMemo((): string => {
    const activeContact = contacts.find(contact => getNodeFromJid(contact.jid) === caller?.id);
    return activeContact?.avatar ?? '';
  }, [caller?.id, contacts]);
  const getCallDuration = useMemo((): string => {
    const hours = Math.floor(callTime / 3600);
    const minutes = Math.floor(callTime / 60);
    const seconds = callTime % 60;
    let str = `${minutes < 10 ? '0' + minutes.toString() : minutes}:${seconds < 10 ? '0' + seconds.toString() : seconds}`;
    if (hours > 0) {
      str = `${hours < 10 ? '0' + hours.toString() : hours}:${str}`;
    }
    return str;
  }, [callTime]);
  const acceptIncomingCall = useCallback(async () => {
    $status(CallTypes.ON_CALL);
    await join();
  }, [join]);
  const containerClass = useMemo((): string => {
    if (status === CallTypes.ON_CALL) {
      if (format === CallFormats.VIDEO) return 'top-0 left-0 z-80 w-full h-full';else return 'top-20 right-4 z-80 bg-gray-300 w-fit h-auto rounded-3xl';
    } else if (status === CallTypes.INCOMING || status === CallTypes.OUTGOING) return 'top-20 right-4 z-80 bg-gray-300 w-fit h-auto rounded-3xl';else return '';
  }, [format, status]);
  useEffect(() => {
    if (status === CallTypes.INCOMING || status === CallTypes.OUTGOING) {
      callRingtone.currentTime = 0;
      callRingtone.play().catch(console.error);
    } else {
      callRingtone.pause();
    }
  }, [status]);
  const IncomingCall = (): JSX.Element => {
    return <div className={clsx(status !== CallTypes.INCOMING && 'hidden', 'absolute bg-gray-200 top-20 overflow-hidden rounded-xl right-8 w-96 h-64')}>
                <div className="absolute z-60 w-full h-full">
                    <div className="flex flex-col items-center text-accent text-center mt-8">
                        <span className="font-bold text-2xl mb-4">{contact?.name}</span>
                        <div className="flex items-center h-10">
                            <span>Uup Anonymous</span>
                            {format === CallFormats.VIDEO && <span className="bg-call-hd-hover px-3 py-1 rounded-2xl ml-3">HD</span>}
                        </div>
                    </div>

                    <div className="flex mt-10 justify-center w-full">
                        <div onClick={cancelCall} className="clickable flex flex-col items-center">
                            <div className="w-10 h-10 bg-red-600 rounded-full center-flex">
                                <svg className="w-4 h-4" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.86444 4.57991C5.30193 4.58041 6.95821 7.04498 4.69042 7.04577C2.50365 7.04608 1.6561 7.45556 1.65649 4.68494C1.69057 4.37192 1.11526 1.59131 8.86438 1.59023C16.6144 1.58915 16.0412 4.36992 16.0752 4.68293C16.0753 7.46074 15.2279 7.04383 13.0411 7.04413C10.7729 7.04445 12.4269 4.57942 8.86444 4.57991Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <span className="text-accent mt-2">Decline</span>
                        </div>

                        <div onClick={acceptIncomingCall} className="clickable flex flex-col items-center ml-24">
                            <div className="w-10 h-10 bg-primary rounded-full center-flex">
                                {format === CallFormats.VIDEO ? <svg className="w-4 h-4" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.6984 8.35904C10.7523 9.58041 9.76661 10.6132 8.4969 10.6651C8.40338 10.6691 3.84403 10.6599 3.84403 10.6599C2.58046 10.7558 1.47459 9.84782 1.37492 8.63104C1.36741 8.54037 1.36946 3.64829 1.36946 3.64829C1.31348 2.4256 2.29785 1.39015 3.56824 1.33628C3.66313 1.33168 8.21633 1.34022 8.21633 1.34022C9.48604 1.24561 10.5953 2.16017 10.6936 3.38286C10.7005 3.4709 10.6984 8.35904 10.6984 8.35904Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.7012 4.65355L12.8965 2.85688C13.4405 2.41155 14.2565 2.79955 14.2558 3.50155L14.2478 8.40088C14.2472 9.10288 13.4305 9.48755 12.8878 9.04222L10.7012 7.24555" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg> : <svg className="w-4 h-4" viewBox="0 0 22 22" fill="none">
                                        <path d="M13.353 1.5C17.054 1.911 19.978 4.831 20.393 8.532" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M13.353 5.04297C15.124 5.38697 16.508 6.77197 16.853 8.54297" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0315 11.4724C14.0205 15.4604 14.9254 10.8467 17.4653 13.3848C19.9138 15.8328 21.3222 16.3232 18.2188 19.4247C17.8302 19.737 15.3613 23.4943 6.68447 14.8197C-1.99341 6.144 1.76157 3.67244 2.07394 3.28395C5.18377 0.173846 5.66682 1.58938 8.11539 4.03733C10.6541 6.5765 6.04254 7.48441 10.0315 11.4724Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>}
                            </div>
                            <span className="text-accent mt-2">Accept</span>
                        </div>
                    </div>
                </div>

                <div className="absolute z-50 top-0 left-0 w-full h-full">
                    <LoadingImage url={getAvatar} className='absolute top-0 left-0 w-full h-full object-contain' spinnerSize='16' alt="avatar" />
                    <div className="absolute top-0 left-0 w-96 h-96 bg-primary bg-opacity-50"></div>
                </div>
            </div>;
  };
  const OutgoingCall = (): JSX.Element => {
    return <div className={clsx(status !== CallTypes.OUTGOING && 'hidden', 'absolute bg-gray-200 top-20 overflow-hidden rounded-xl right-8 w-96 h-64')}>
                <div className="absolute z-60 w-full h-full">

                    <div className="flex flex-col items-center text-accent text-center mt-8">
                        <span className="font-bold text-2xl mb-4">{contact?.name}</span>
                        <div className="flex items-center h-10">
                            <span>
                                {format === CallFormats.VOICE && 'Voice call'}
                                {format === CallFormats.VIDEO && 'Video call'}
                            </span>
                            {format === CallFormats.VIDEO && <span className="bg-call-hd-hover px-3 py-1 rounded-2xl ml-3">HD</span>}
                        </div>
                    </div>

                    <div className="flex mt-10 justify-center w-full">
                        <div className="clickable flex flex-col items-center" onClick={cancelCall}>
                            <div className="w-10 h-10 bg-red-600 rounded-full center-flex">
                                <svg className="w-4 h-4" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.86444 4.57991C5.30193 4.58041 6.95821 7.04498 4.69042 7.04577C2.50365 7.04608 1.6561 7.45556 1.65649 4.68494C1.69057 4.37192 1.11526 1.59131 8.86438 1.59023C16.6144 1.58915 16.0412 4.36992 16.0752 4.68293C16.0753 7.46074 15.2279 7.04383 13.0411 7.04413C10.7729 7.04445 12.4269 4.57942 8.86444 4.57991Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <span className="text-accent mt-2">Cancel</span>
                        </div>
                    </div>

                </div>

                <div className="absolute z-50 top-0 left-0 w-full h-full">
                    <LoadingImage url={getAvatar} className='absolute top-0 left-0 w-full h-full object-contain' spinnerSize='16' alt="avatar" />
                    <div className="absolute top-0 left-0 w-96 h-96 bg-primary bg-opacity-50"></div>
                </div>
            </div>;
  };
  const VoiceCall = (): JSX.Element => {
    return <div className={clsx(!(status === CallTypes.ON_CALL && format === CallFormats.VOICE) && 'hidden', 'absolute bg-gray-200 top-20 overflow-hidden rounded-xl right-8 w-96 h-64')}>

                <div className="absolute z-60 w-full h-full">
                    <div className="flex flex-col items-center text-accent text-center mt-8">
                        <span className="font-bold text-2xl mb-4">{contact?.name}</span>
                        <div className="flex items-center h-10">
                            <span>Voice call</span>
                            <span className="mx-3">|</span>
                            <span>{getCallDuration}</span>
                        </div>
                    </div>

                    <div className="flex mt-10 justify-center w-full">
                        <div className="clickable flex flex-col items-center" onClick={leave}>
                            <div className="w-10 h-10 bg-red-600 rounded-full center-flex">
                                <svg className="w-4 h-4" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.86444 4.57991C5.30193 4.58041 6.95821 7.04498 4.69042 7.04577C2.50365 7.04608 1.6561 7.45556 1.65649 4.68494C1.69057 4.37192 1.11526 1.59131 8.86438 1.59023C16.6144 1.58915 16.0412 4.36992 16.0752 4.68293C16.0753 7.46074 15.2279 7.04383 13.0411 7.04413C10.7729 7.04445 12.4269 4.57942 8.86444 4.57991Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <span className="text-accent mt-2">Hang up</span>
                        </div>
                    </div>
                </div>

                <div className="absolute z-50 top-0 left-0 w-full h-full">
                    <LoadingImage url={getAvatar} className='absolute top-0 left-0 w-full h-full object-contain' spinnerSize='16' alt="avatar" />
                    <div className="absolute top-0 left-0 w-96 h-96 bg-primary bg-opacity-50"></div>
                </div>

                <button onClick={alert} className="clickable absolute z-70 top-6 right-6">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9959 1.00488L1.00586 12.9924" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13 13L1 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>;
  };
  return <>
            <IncomingCall />
            <OutgoingCall />
            <VoiceCall />

            <div className={clsx(containerClass, !(status === CallTypes.ON_CALL && format === CallFormats.VIDEO) && 'hidden', 'absolute top-0 right-0 w-full h-screen shadow overflow-hidden z-100')}>
                <div className={clsx('relative w-full h-full')}>

                    <div className="absolute top-10 z-80 w-full center-flex flex-col">
                        <div className="flex flex-col items-center dark-overlay px-2 py-1 rounded">
                            <span className="font-semibold text-accent text-xl">{contact?.name}</span>
                            <span className="text-xs mt-1 text-gray-100">{getCallDuration}</span>
                        </div>
                    </div>

                    <div className='relative w-full h-full bg-gray-200'>
                        <div ref={remotePlayer} id="remote-player" className="w-full border-2 h-full"></div>
                        <div ref={localPlayer} id="local-player" className="absolute bottom-20 right-0 z-50 w-40 h-40 border rounded-xl border-gray-300 m-4"></div>
                    </div>

                    <div className="absolute bottom-6 w-full center-flex">
                        <button onClick={toggleAudio} className={clsx(isCameraEnabled ? 'bg-call-hd-hover' : 'bg-call-hd-hover-deactive', 'w-10 h-10 shadow center-flex rounded mx-3 clickable')}>
                            {isAudioEnabled ? <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17V21M12 21H9M12 21H15" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <rect x="10" y="3" width="4" height="10" rx="2" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.7378 12.7542C17.3674 13.9659 16.6228 15.0293 15.6109 15.7918C14.599 16.5544 13.3716 16.977 12.1047 16.9991C10.8378 17.0212 9.59647 16.6417 8.55854 15.9149C7.52061 15.1881 6.73941 14.1515 6.32689 12.9534" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> : <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.00004 7.91421V11C9.00004 12.6569 10.3432 14 12 14C12.8503 14 13.6179 13.6463 14.1638 13.078L12.7487 11.6629C12.5655 11.8697 12.298 12 12 12C11.4478 12 11 11.5523 11 11V9.91422L9.00004 7.91421ZM13 9.08579V5C13 4.44772 12.5523 4 12 4C11.4478 4 11 4.44772 11 5V7.08579L9.00004 5.08579V5C9.00004 3.34315 10.3432 2 12 2C13.6569 2 15 3.34315 15 5V11C15 11.0283 14.9997 11.0565 14.9989 11.0846L13 9.08579ZM15.5782 14.4924C15.4023 14.6727 15.2121 14.8402 15.0091 14.9932C14.1658 15.6286 13.143 15.9808 12.0873 15.9992C12.0594 15.9997 12.0315 16 12.0036 16C10.977 16.0007 9.97424 15.6854 9.13216 15.0958C8.26722 14.4901 7.61622 13.6262 7.27245 12.6278C7.09264 12.1056 6.52356 11.8281 6.00136 12.0079C5.47917 12.1877 5.20161 12.7568 5.38141 13.279C5.86269 14.6767 6.77409 15.8862 7.98501 16.7341C8.88694 17.3656 9.92054 17.7724 11 17.9282V20H9.00004C8.44776 20 8.00004 20.4477 8.00004 21C8.00004 21.5523 8.44776 22 9.00004 22H12H15C15.5523 22 16 21.5523 16 21C16 20.4477 15.5523 20 15 20H13V17.9282C14.1618 17.7605 15.2678 17.3025 16.2127 16.5904C16.4905 16.3812 16.7509 16.1525 16.9925 15.9067L15.5782 14.4924ZM18.1876 14.2733L16.6785 12.7642C16.716 12.6648 16.7504 12.5639 16.7816 12.4619C16.943 11.9337 17.5021 11.6365 18.0302 11.7979C18.5584 11.9594 18.8556 12.5184 18.6942 13.0466C18.5639 13.4729 18.3938 13.8834 18.1876 14.2733Z" fill="#000000" />
                                    <path d="M5 5L19 19" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>}
                        </button>

                        {format === CallFormats.VIDEO && <button onClick={toggleVideo} className={clsx(isCameraEnabled ? 'bg-call-hd-hover' : 'bg-call-hd-hover-deactive', 'w-10 h-10 shadow center-flex rounded mx-3 clickable')}>
                            {isCameraEnabled ? <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 11V6.5C3 5.94772 3.44772 5.5 4 5.5H15C15.5523 5.5 16 5.94772 16 6.5V17.5C16 18.0523 15.5523 18.5 15 18.5H4C3.44772 18.5 3 18.0523 3 17.5V16M16 15L21 17V7L18.5 8" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> : <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 8V17.5C3 18.0523 3.44772 18.5 4 18.5H14M3 3L21 21M10 5.5H15C15.5523 5.5 16 5.94772 16 6.5V9M16 9V11M16 9L21 7V17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>}
                        </button>}

                        <button onClick={leave} className="bg-red-500 w-10 h-10 center-flex rounded mx-3 clickable">
                            <svg className="w-5 h-5" viewBox="0 0 24 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.2693 5.36987C6.92556 5.37061 9.40998 9.06747 6.00829 9.06866C2.72812 9.06912 1.45681 9.68334 1.45739 5.5274C1.50851 5.05788 0.645543 0.886961 12.2692 0.885343C23.8943 0.883725 23.0345 5.05488 23.0855 5.52439C23.0856 9.69111 21.8145 9.06574 18.5343 9.0662C15.1319 9.06667 17.6131 5.36913 12.2693 5.36987Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>;
}
export default ContactAgoraCall;
import React from 'react';
interface Props {
  wrapperClass?: string;
  spinnerClass?: string;
  size?: string;
  border?: string;
}
function LoadingSpinner({
  wrapperClass = '',
  spinnerClass = '',
  size = '10',
  border = '2'
}: Props): JSX.Element {
  const sizeClasses = size !== undefined ? `w-${size} h-${size}` : '';
  const borderClass = `border-${border}`;
  return <div className={`rounded-full flex items-center justify-center p-0 m-0 ${sizeClasses} ${wrapperClass}`}>
            <div className={`loader border-t-light-blue rounded-full ${sizeClasses} ${spinnerClass} ${borderClass}`}></div>
        </div>;
}
export default React.memo(LoadingSpinner);
import clsx from "clsx";
import moment from "moment";
import { useCallback } from "react";
import MessageDeliveryTicks from "src/components/UI/MessageDeliveryTicks";
import { parseTimestamp } from "src/helpers/message";
import { type DecryptedMessage } from "src/types/Message";
interface Props {
  message: DecryptedMessage;
}
function TextMessage({
  message
}: Props): JSX.Element {
  const {
    content,
    received,
    timestamp,
    updatedAt
  } = message;
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const parseContent = useCallback(() => {
    if (typeof content !== 'string') return '';
    let htmlContent = content;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (urlRegex.test(htmlContent)) {
      htmlContent = htmlContent.replace(urlRegex, '<a href="$1" class="text-light-blue underline" target="_blank" rel="noopener noreferrer">$1</a>');
    }
    return htmlContent;
  }, [content]);
  return <div className='flex flex-col w-full text-sm p-4 pb-2' onClick={() => {
    console.log(message);
  }}>
            <span className={clsx('break-words break-all', received ? 'text-primary' : 'text-accent')} dangerouslySetInnerHTML={{
      __html: parseContent()
    }}></span>
            <div className="flex items-center mt-1 self-end text-2xs">
                <span className='text-gray-400'>{formattedTimestamp}</span>
                {updatedAt !== undefined && <span className='text-2xs text-gray-600 ml-4'>Edited</span>}
                <MessageDeliveryTicks message={message} />
            </div>
        </div>;
}
export default TextMessage;
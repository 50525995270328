import axios from "axios";
import API from "src/services/Api";
import { type Contact } from "src/types/Contact";
import { type EncryptedGroupMessage, type EncryptedMessage } from "src/types/Ejabberd/Message";
import { MessageType } from "src/types/Ejabberd/MessageType";
import { type User } from "src/types/User";
import { type SignedUrlData } from "src/types/files";
import { encryptAES, generateAes, generateEncryptedMessageContent, generateIv } from "./message";
import { getNodeFromJid } from "./contact";
import { generateEncryptedMessageContentForGroup } from "./group";

export const uploadDocument = async (blob: Blob, filename: string, controller: AbortController): Promise<string | false> => {
    const path = `media/files/${filename}`
    const signedData = await getAWSSignedUrl(path, 'PUT_OBJECT')

    if (signedData === false) return false

    const upload = await axios.put(signedData.url, blob, {
        signal: controller.signal,
        onUploadProgress: (evt) => {
            // console.log('[UPLOAD DOCUMENT PROGRESS]', Math.round((evt.loaded / evt.total) * 100))
        }
    })

    if (upload.status !== 200) return false

    return filename
}

export async function prepareFileMessageForContact(
    contact: Contact,
    originalFileName: string,
    filename: string,
    filesize: string,
    userData: User,
    additional: object = {}
): Promise<EncryptedMessage | false> {
    const aes = generateAes()
    const iv = generateIv()
    const encryptedFilePath = encryptAES(filename, aes, iv)
    const myEncryptedFilePath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedFilePath,
        mdt: myEncryptedFilePath,
        md: JSON.stringify({
            n: originalFileName,
            s: filesize
        })
    }

    return generateEncryptedMessageContent(
        MessageType.FILE,
        JSON.stringify(attachment),
        getNodeFromJid(contact.jid),
        contact.key, aes, iv, userData, additional
    )
}

export async function prepareFileMessageForGroup(
    group: Contact,
    originalFileName: string,
    filename: string,
    filesize: string,
    userData: User,
    additional: object = {}
): Promise<EncryptedGroupMessage | false> {
    const [iv, aes] = group.key.split(',')
    const encryptedFilePath = encryptAES(filename, aes, iv)
    const encryptedMyFilePath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedFilePath,
        mdt: encryptedMyFilePath,
        md: JSON.stringify({
            n: originalFileName,
            s: filesize
        })
    }

    return generateEncryptedMessageContentForGroup(
        MessageType.FILE,
        JSON.stringify(attachment),
        group,
        userData, additional
    )
}

export const findFolderNameByExtension = (fileType: string): string => {
    let folder: string = 'files';

    const isAudio = fileType.includes('audio')
    const isVideo = fileType.includes('video')
    const isImage = fileType.includes('image')

    if (isAudio) folder = 'voices'
    else if (isVideo) folder = 'videos'
    else if (isImage) folder = 'images'

    return folder
}

export const findMessageTypeByExtension = (fileType: string): MessageType => {
    let docType: MessageType = MessageType.FILE;

    const isAudio = fileType.includes('audio')
    const isVideo = fileType.includes('video')
    const isImage = fileType.includes('image')

    if (isAudio) docType = MessageType.AUDIO
    else if (isVideo) docType = MessageType.VIDEO
    else if (isImage) docType = MessageType.IMAGE

    return docType
}

export function calculateFileSize(file: File | Blob): string {
    let _size = file.size;
    const extensions = ['B', 'KB', 'MB', 'GB']

    let i = 0;

    while (_size > 900) {
        _size /= 1024;
        i++;
    }

    const exactSize = (Math.round(_size * 100) / 100).toString() + ' ' + extensions[i];

    return exactSize
}

export function convertBytesToReadable(size: number): string {
    const extensions = ['B', 'KB', 'MB', 'GB']

    let i = 0;

    while (size > 900) {
        size /= 1024;
        i++;
    }

    const exactSize = (Math.round(size * 100) / 100).toString() + ' ' + extensions[i];

    return exactSize
}

export async function getAWSSignedUrl(key: string, operation: 'GET_OBJECT' | 'PUT_OBJECT'): Promise<SignedUrlData | false> {
    try {
        const response = await API.post('/files/signed-urls', {
            key,
            operation
        })

        if (response.status !== 201) {
            console.log('ERROR', response)
            return false

        }

        const { data } = response

        return data
    }
    catch (e) {
        console.error(e)
        return false
    }
}

import { useCallback, useEffect } from "react";

export function useKeyboardListener(key: string, callback: () => void): void {
    const listener = useCallback((e: KeyboardEvent): void => {
        if (e.key === key) {
            callback();
        }
    }, [callback, key])

    useEffect(() => {
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        }
    }, [listener]);
}

import axios from "axios";
import { type GiphyResponse } from "src/types/giphy";

export async function searchGifs(searchTerm: string, limit: number = 30): Promise<GiphyResponse> {
    const response = await axios.get(`https://api.giphy.com/v1/gifs/search?api_key=${process.env.REACT_APP_GIPHY_API_KEY ?? ''}&q=${searchTerm}&limit=${limit}&offset=0&rating=g&lang=en&bundle=messaging_non_clips`)
    return response.data
}

export async function getTrendingGifs(limit: number = 30): Promise<GiphyResponse> {
    const response = await axios.get(`https://api.giphy.com/v1/gifs/trending?api_key=${process.env.REACT_APP_GIPHY_API_KEY ?? ''}&limit=${limit}&offset=0&rating=g&bundle=messaging_non_clips`)
    return response.data
}

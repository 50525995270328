import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useUser } from "src/hooks/store";
import { hideSettings } from "src/store/slices/app";
import Checkbox from "../../Checkbox";
import clsx from "clsx";
import API from "src/services/Api";
import { toast } from "react-hot-toast";
import LoadingSpinner from "src/components/LoadingSpinner";
import { setUser } from "src/store/slices/user";
import LoadingImage from '../../LoadingImage';
function SettingsModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const fileInput = useRef<HTMLInputElement>(null);
  const [username, $username] = useState('');
  const [file, $file] = useState<File>();
  const [fileBase64, $fileBase64] = useState<string>('');
  const [loading, $loading] = useState(false);
  const [edit, $edit] = useState(false);
  const {
    user
  } = useUser();
  const close = useCallback(() => dispatch(hideSettings()), [dispatch]);
  const pickAvatar = useCallback(() => {
    fileInput.current?.click();
  }, []);
  const saveChanges = useCallback(async () => {
    try {
      const payload = new FormData();
      if (file !== undefined) {
        payload.append('photo', file);
      }
      if (username !== user?.username) {
        payload.append('username', username);
      }
      if (payload.has('photo') || payload.has('username')) {
        $loading(true);
        await API.patch('/users/me', payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        toast.success('Changes saved');
        $edit(false);
        if (user !== null) {
          dispatch(setUser({
            ...user,
            username,
            avatar: fileBase64 !== '' ? fileBase64 : user.avatar
          }));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      $loading(false);
    }
  }, [dispatch, file, fileBase64, user, username]);
  const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file !== undefined) {
      $file(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        $fileBase64((reader.result as string));
      };
      reader.onerror = () => {
        $fileBase64('');
      };
    }
  }, []);
  const toggleEdit = useCallback(() => {
    if (edit) {
      void saveChanges();
    }
    $edit(prev => !prev);
  }, [edit, saveChanges]);
  useEffect(() => {
    if (user?.username !== undefined) {
      $username(user.username);
    }
  }, [user]);
  return <div className="absolute top-0 left-0 w-full h-screen z-80 bg-modal-overlay">
            <div className="absolute z-80 shadow rounded-xl overflow-hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">

                <div className="relative bg-accent px-5 py-4 text-center">
                    <button onClick={close} className="absolute clickable left-4 top-1/2 -translate-y-1/2">
                        <svg className="w-6 h-6" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z" fill="#1C1B1F" />
                        </svg>
                    </button>

                    <span className="text-lg font-semibold">Settings</span>

                    {!loading && <button onClick={toggleEdit} className="absolute clickable right-4 top-1/2 -translate-y-1/2">
                        <i className={clsx('ml-4 bi', edit ? 'bi-check-lg text-xl text-hover-text' : 'bi-pencil')}></i>
                    </button>}
                    {loading && <LoadingSpinner wrapperClass="absolute right-0 top-1/2 -translate-y-1/2" spinnerClass="w-5 h-5" />}
                </div>

                <div className="px-10 py-8 bg-white">

                    <div className="flex items-center">
                        <div className="relative w-24 h-24 rounded-full">
                            <LoadingImage url={fileBase64 !== '' ? fileBase64 : user?.avatar ?? ''} className='w-24 h-24 shrink-0 object-cover rounded-full' spinnerSize='24' alt="avatar" />
                            {edit && <button onClick={pickAvatar} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <div className="bg-black w-10 h-10 opacity-60 rounded-full flex items-center justify-center opacity-80">
                                    <i className="bi bi-upload text-white text-xl"></i>
                                </div>
                            </button>}
                            <input type="file" accept="image/*" className="hidden" ref={fileInput} onChange={onChangeFile} />
                        </div>

                        <div className="flex-1 shrink-0 flex flex-col ml-8">
                            <div className="flex w-64 h-8 overflow-hidden items-center">
                                {edit ? <input type="text" className="text-lg w-full h-full outline-none focus:border-b" autoFocus value={username} onChange={e => {
                $username(e.target.value);
              }} /> : <div className="w-full text-lg">{username}</div>}
                            </div>
                            <span onClick={toggleEdit} className="clickable text-hover-text text-sm">Edit profile</span>
                        </div>
                    </div>

                    <div className="flex flex-col mt-10">

                        <div className="clickable flex items-center my-2">
                            <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_990_43124)">
                                    <path d="M0.00170722 10.9996C0.00170722 8.17905 -0.00352114 5.35935 0.0043214 2.53879C0.00780697 1.2097 0.765919 0.267227 1.99284 0.0421322C2.1767 0.00862576 2.36841 0.00690748 2.55663 0.00604834C5.11242 0.00433006 7.66735 0.0120623 10.2231 3.43623e-05C10.9935 -0.0034022 11.6313 0.250903 12.1838 0.77412C13.4839 2.00183 14.7875 3.22611 16.1068 4.43492C16.722 4.99851 17.0017 5.67122 17 6.49341C16.9921 10.8029 17 15.1114 16.9947 19.4209C16.993 21.0464 16.0083 21.9992 14.3562 22C10.4506 22.0009 6.54412 22.0009 2.63854 22C0.990738 22 0.00693557 21.0412 0.00345 19.4175C-0.00264974 16.6115 0.00170722 13.8056 0.00170722 10.9996ZM10.197 1.29476C9.98177 1.29476 9.82492 1.29476 9.66807 1.29476C7.3458 1.29476 5.02354 1.29476 2.70128 1.29476C1.67914 1.29476 1.31228 1.65388 1.31141 2.66337C1.31054 8.22974 1.31054 13.797 1.31141 19.3633C1.31141 20.3419 1.68872 20.7096 2.6795 20.7096C6.55458 20.7096 10.4297 20.7096 14.3056 20.7096C15.3034 20.7096 15.6842 20.3436 15.685 19.3736C15.6868 15.224 15.6859 11.0735 15.685 6.92384C15.685 6.77349 15.6693 6.624 15.6598 6.44874C14.4102 6.44874 13.2068 6.44874 12.0043 6.44874C10.7669 6.44874 10.1996 5.88858 10.197 4.66345C10.1944 3.56289 10.197 2.46147 10.197 1.2939V1.29476ZM14.9757 5.15745C13.7837 4.0483 12.6892 3.03022 11.5102 1.93396C11.5102 2.99586 11.5015 3.92287 11.5224 4.84902C11.525 4.95384 11.6932 5.14285 11.7873 5.14457C12.8094 5.16604 13.8333 5.15831 14.9757 5.15831V5.15745Z" fill="#5A6A9D" />
                                    <path d="M8.36777 8.59547C9.92146 8.59547 11.4743 8.59375 13.028 8.59633C13.4863 8.59719 13.7713 8.81541 13.8105 9.17539C13.8541 9.57317 13.5473 9.87817 13.0724 9.88246C12.2158 9.8902 11.3593 9.88504 10.5027 9.88504C8.26668 9.88504 6.03156 9.88762 3.79557 9.88075C3.61258 9.88075 3.35726 9.88332 3.26315 9.77593C3.10194 9.59293 2.94335 9.31543 2.96252 9.09291C2.99302 8.74238 3.29887 8.59289 3.66399 8.59375C4.91269 8.59719 6.1614 8.59461 7.40923 8.59461C7.72903 8.59461 8.04796 8.59461 8.36777 8.59461V8.59547Z" fill="#5A6A9D" />
                                    <path d="M8.36777 12.0321C9.92146 12.0321 11.4743 12.0295 13.028 12.0338C13.5046 12.0347 13.7983 12.2752 13.8122 12.6558C13.8262 13.0399 13.5299 13.3165 13.0715 13.32C12.1862 13.3277 11.3 13.3225 10.4147 13.3225C8.2083 13.3225 6.00106 13.3251 3.7947 13.3182C3.6117 13.3182 3.35639 13.3208 3.26228 13.2134C3.10194 13.0304 2.94335 12.7529 2.96252 12.5304C2.99302 12.1799 3.29975 12.0304 3.66399 12.0313C4.91269 12.0347 6.1614 12.0321 7.40923 12.0321C7.72903 12.0321 8.04796 12.0321 8.36777 12.0321Z" fill="#5A6A9D" />
                                    <path d="M5.17354 16.7594C4.66726 16.7594 4.16098 16.7663 3.65471 16.7577C3.21814 16.75 2.93319 16.4974 2.92361 16.1305C2.91315 15.7491 3.2103 15.4759 3.66691 15.4724C4.66552 15.4656 5.66327 15.4656 6.66188 15.4724C7.10716 15.4759 7.37817 15.7216 7.38601 16.0996C7.39472 16.4948 7.11588 16.7508 6.64968 16.7586C6.15822 16.7663 5.66588 16.7603 5.17354 16.7603V16.7594Z" fill="#5A6A9D" />
                                </g>
                            </svg>
                            <a className="w-full rounded border-b ml-5 pb-1 pl-2" target="_blank" rel="noreferrer" href="https://uup-dev.s3.eu-central-1.amazonaws.com/resources/terms-and-conditions.html">Terms and conditions</a>

                        </div>
                        <div className="clickable flex items-center my-2">
                            <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_990_43124)">
                                    <path d="M0.00170722 10.9996C0.00170722 8.17905 -0.00352114 5.35935 0.0043214 2.53879C0.00780697 1.2097 0.765919 0.267227 1.99284 0.0421322C2.1767 0.00862576 2.36841 0.00690748 2.55663 0.00604834C5.11242 0.00433006 7.66735 0.0120623 10.2231 3.43623e-05C10.9935 -0.0034022 11.6313 0.250903 12.1838 0.77412C13.4839 2.00183 14.7875 3.22611 16.1068 4.43492C16.722 4.99851 17.0017 5.67122 17 6.49341C16.9921 10.8029 17 15.1114 16.9947 19.4209C16.993 21.0464 16.0083 21.9992 14.3562 22C10.4506 22.0009 6.54412 22.0009 2.63854 22C0.990738 22 0.00693557 21.0412 0.00345 19.4175C-0.00264974 16.6115 0.00170722 13.8056 0.00170722 10.9996ZM10.197 1.29476C9.98177 1.29476 9.82492 1.29476 9.66807 1.29476C7.3458 1.29476 5.02354 1.29476 2.70128 1.29476C1.67914 1.29476 1.31228 1.65388 1.31141 2.66337C1.31054 8.22974 1.31054 13.797 1.31141 19.3633C1.31141 20.3419 1.68872 20.7096 2.6795 20.7096C6.55458 20.7096 10.4297 20.7096 14.3056 20.7096C15.3034 20.7096 15.6842 20.3436 15.685 19.3736C15.6868 15.224 15.6859 11.0735 15.685 6.92384C15.685 6.77349 15.6693 6.624 15.6598 6.44874C14.4102 6.44874 13.2068 6.44874 12.0043 6.44874C10.7669 6.44874 10.1996 5.88858 10.197 4.66345C10.1944 3.56289 10.197 2.46147 10.197 1.2939V1.29476ZM14.9757 5.15745C13.7837 4.0483 12.6892 3.03022 11.5102 1.93396C11.5102 2.99586 11.5015 3.92287 11.5224 4.84902C11.525 4.95384 11.6932 5.14285 11.7873 5.14457C12.8094 5.16604 13.8333 5.15831 14.9757 5.15831V5.15745Z" fill="#5A6A9D" />
                                    <path d="M8.36777 8.59547C9.92146 8.59547 11.4743 8.59375 13.028 8.59633C13.4863 8.59719 13.7713 8.81541 13.8105 9.17539C13.8541 9.57317 13.5473 9.87817 13.0724 9.88246C12.2158 9.8902 11.3593 9.88504 10.5027 9.88504C8.26668 9.88504 6.03156 9.88762 3.79557 9.88075C3.61258 9.88075 3.35726 9.88332 3.26315 9.77593C3.10194 9.59293 2.94335 9.31543 2.96252 9.09291C2.99302 8.74238 3.29887 8.59289 3.66399 8.59375C4.91269 8.59719 6.1614 8.59461 7.40923 8.59461C7.72903 8.59461 8.04796 8.59461 8.36777 8.59461V8.59547Z" fill="#5A6A9D" />
                                    <path d="M8.36777 12.0321C9.92146 12.0321 11.4743 12.0295 13.028 12.0338C13.5046 12.0347 13.7983 12.2752 13.8122 12.6558C13.8262 13.0399 13.5299 13.3165 13.0715 13.32C12.1862 13.3277 11.3 13.3225 10.4147 13.3225C8.2083 13.3225 6.00106 13.3251 3.7947 13.3182C3.6117 13.3182 3.35639 13.3208 3.26228 13.2134C3.10194 13.0304 2.94335 12.7529 2.96252 12.5304C2.99302 12.1799 3.29975 12.0304 3.66399 12.0313C4.91269 12.0347 6.1614 12.0321 7.40923 12.0321C7.72903 12.0321 8.04796 12.0321 8.36777 12.0321Z" fill="#5A6A9D" />
                                    <path d="M5.17354 16.7594C4.66726 16.7594 4.16098 16.7663 3.65471 16.7577C3.21814 16.75 2.93319 16.4974 2.92361 16.1305C2.91315 15.7491 3.2103 15.4759 3.66691 15.4724C4.66552 15.4656 5.66327 15.4656 6.66188 15.4724C7.10716 15.4759 7.37817 15.7216 7.38601 16.0996C7.39472 16.4948 7.11588 16.7508 6.64968 16.7586C6.15822 16.7663 5.66588 16.7603 5.17354 16.7603V16.7594Z" fill="#5A6A9D" />
                                </g>
                            </svg>
                            <a className="w-full rounded border-b ml-5 pb-1 pl-2" target="_blank" rel="noreferrer" href="https://uup-dev.s3.eu-central-1.amazonaws.com/resources/privacy-policy.html">Privacy and policy</a>
                        </div>

                    </div>

                    <div className="bg-gray-50 p-4 shadow rounded-xl w-full flex items-center justify-between mt-8">
                        <span>Allow Notifications</span>
                        <Checkbox checked />
                    </div>

                </div>

            </div>

        </div>;
}
export default SettingsModal;
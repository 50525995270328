import { createSlice } from '@reduxjs/toolkit'
import reducers from './reducers'

export interface SelectionState {
    selectedMessages: string[]
}

export const initialState: SelectionState = {
    selectedMessages: []
}

export const selectionSlice = createSlice({
    name: 'activeMedia',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    addSelectedMessage,
    removeSelectedMessage,
    resetSelection
} = selectionSlice.actions

export default selectionSlice.reducer

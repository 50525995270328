import type { PayloadAction } from '@reduxjs/toolkit'
import { type CreateChannelState, initialState } from '..'

const reducers = {
    setCreateChannelActive: (state: CreateChannelState, action: PayloadAction<boolean>) => {
        state.active = action.payload
    },
    setCreateChannelName: (state: CreateChannelState, action: PayloadAction<string>) => {
        state.name = action.payload
    },
    setCreateChannelAvatar: (state: CreateChannelState, action: PayloadAction<File | null>) => {
        state.avatar = action.payload
    },
    setCreateChannelAvatarSrc: (state: CreateChannelState, action: PayloadAction<string | null>) => {
        state.avatarSrc = action.payload
    },
    resetCreateChannel: (state: CreateChannelState) => {
        return initialState
    }
}

export default reducers

import { LOCAL_STORAGE_API_TOKEN, LOCAL_STORAGE_KEY, LOCAL_STORAGE_SESSION_ID } from "src/constants/config"
import API from "src/services/Api"
import { type Credentials, type EncryptedEventSourceData, type SavedCredentials } from "src/types/SignIn"
import { type User } from "src/types/User"
import { decryptAES, decryptRSA } from "./message"
import { type AxiosResponse } from "axios"

export function storeCredentials(credentials: Credentials): void {
    const { sessionId, publicKey, privateKey, userData, myApiToken } = credentials

    const data: SavedCredentials = { s: sessionId, pk: publicKey, sk: privateKey, d: userData }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
    localStorage.setItem(LOCAL_STORAGE_API_TOKEN, myApiToken)
    localStorage.setItem(LOCAL_STORAGE_SESSION_ID, sessionId)
}

export function handleCredentials(
    userData: EncryptedEventSourceData,
    sessionId: string,
    publicKey: string,
    privateKey: string,
    success: (user: User) => void,
    error: () => void
): void {
    const decryptIvAes = decryptRSA(userData.aesKey, privateKey)

    if (publicKey === null || privateKey === null || decryptIvAes === false) {
        error()
        return
    }

    const [iv, aes] = decryptIvAes.split(',')

    const myPassword = decryptRSA(userData.password, privateKey)
    const myPrivateKey = decryptAES(userData.privateKey, aes, iv)
    const myApiToken = decryptRSA(userData.accessToken, privateKey)
    const myUid = decryptRSA(userData.userId, privateKey)

    if (sessionId === null || myPassword === false || myPrivateKey === false || userData.publicKey === null || myApiToken === false || myUid === false) {
        error()
        return
    }

    const credentials: Credentials = {
        sessionId,
        publicKey,
        privateKey,
        userData,
        myApiToken
    }

    storeCredentials(credentials)

    const decryptedUserData: User = {
        aes,
        iv,
        password: myPassword,
        privateKey: myPrivateKey,
        publicKey: userData.publicKey,
        apiToken: myApiToken,
        sessionId,
        uid: myUid
    }

    success(decryptedUserData)
}

export const updateUserToken = async (apiToken: string, fcmToken: string): Promise<AxiosResponse> => {
    const response = await API.post('notification/tokens', {
        device: 'WEB',
        type: 'FCM',
        value: fcmToken
    }, {
        headers: {
            'Authorization': 'Bearer ' + apiToken
        }
    })

    return response
}

import { BehaviorSubject, Subject } from 'rxjs';

import { type Contact } from 'src/types/Contact'
import { MessageConvertorService } from 'src/services/Ejabberd/helpers/MessageConvertor';
import { type GroupEvent, type EncryptedGroupMessage, type EncryptedMessage, type CallEvent } from 'src/types/Ejabberd/Message';
import { GROUP_HOST, HOST, WS_SERVICE } from 'src/constants/xmpp';

import { v4 as uuidv4 } from 'uuid';
import StropheRoster from 'src/lib/strophe-roster';
import StropheMAM from 'src/lib/strophe-mam';
import StrophePubSub from 'src/lib/strophe-pubsub';
import { logger } from 'src/helpers/logger';
import { xmlToJson } from 'src/helpers/xmpp';
import { type DeleteMessageEventData, type MessageDeliveryEventData, MessageDeliveryEventType, type DecryptedMessage } from 'src/types/Message';
import StropheRSM from 'src/lib/strophe-rsm';
import { getNodeFromJid } from 'src/helpers/contact';
import { type RosterStateChange, RosterStateType, type TypingEventData } from 'src/types/Roster';
import { type ErrorCallback, type QueuedMessage, type SuccessCallback } from 'src/types/XMPP';
import { SyncQueue } from 'src/helpers/queue';
import { isJSON } from 'src/helpers/common';
import { generateTimestamp } from 'src/helpers/message';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import { clearAppData } from 'src/helpers/app';

const { $pres, $iq, $msg, Strophe } = window

export class XMPPService {
    private readonly BOSH_SERVICE = WS_SERVICE;
    public connection: Strophe.Connection;
    private roomName: any = '';
    public messageHandler: (isArchived: boolean, json: EncryptedMessage, stanza: Element) => void;
    public groupMessageHandler: (json: EncryptedMessage, stanza: Element) => void;
    public groupEventHandler: (isArchived: boolean, json: GroupEvent, stanza: Element) => void;
    public callEventHandler: (json: CallEvent, stanza: Element) => void;
    public typingHandler: (json: TypingEventData) => void;
    public deleteMessageHandler: (json: DeleteMessageEventData) => void;
    public messageDeliveryHandler: (data: MessageDeliveryEventData) => void;
    public logoutHandler: () => void;
    public rosterEvents = new Subject<RosterStateChange>();
    public contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject<Contact[] | null>(null);
    public messageQueue = new SyncQueue()
    public mamQueries: Record<string, QueuedMessage[]> = {}
    public isConnecting = false

    private jid: string = '';
    private host: string = '';
    private password: string = '';

    private domParser: DOMParser;
    private messageConvertorService: MessageConvertorService;

    constructor() {
        this.domParser = new DOMParser();
        this.messageConvertorService = new MessageConvertorService()

        this.connection = new Strophe.Connection(this.BOSH_SERVICE, {
            keepalive: true
        });

        this.addNamespaces();
        this.addPlugins()
    }

    reconnect(): void {
        this.domParser = new DOMParser();
        this.messageConvertorService = new MessageConvertorService()

        this.connection = new Strophe.Connection(this.BOSH_SERVICE, {
            keepalive: true
        });

        this.addNamespaces();
        this.addPlugins()
    }

    // add namespace to strophe
    addNamespaces(): void {
        Strophe.addNamespace('ACTIVITY', 'http://jabber.org/protocol/activity');
        Strophe.addNamespace('CARBONS', 'urn:xmpp:carbons:2');
        Strophe.addNamespace('CHATSTATES', 'http://jabber.org/protocol/chatstates');
        Strophe.addNamespace('CSI', 'urn:xmpp:csi:0');
        Strophe.addNamespace('DELAY', 'urn:xmpp:delay');
        Strophe.addNamespace('EME', 'urn:xmpp:eme:0');
        Strophe.addNamespace('FASTEN', 'urn:xmpp:fasten:0');
        Strophe.addNamespace('FORWARD', 'urn:xmpp:forward:0');
        Strophe.addNamespace('HINTS', 'urn:xmpp:hints');
        Strophe.addNamespace('HTTPUPLOAD', 'urn:xmpp:http:upload:0');
        Strophe.addNamespace('MARKERS', 'urn:xmpp:chat-markers:0');
        Strophe.addNamespace('MENTIONS', 'urn:xmpp:mmn:0');
        Strophe.addNamespace('MESSAGE_CORRECT', 'urn:xmpp:message-correct:0');
        Strophe.addNamespace('MODERATE', 'urn:xmpp:message-moderate:0');
        Strophe.addNamespace('NICK', 'http://jabber.org/protocol/nick');
        Strophe.addNamespace('OCCUPANTID', 'urn:xmpp:occupant-id:0');
        Strophe.addNamespace('OMEMO', 'eu.siacs.conversations.axolotl');
        Strophe.addNamespace('OUTOFBAND', 'jabber:x:oob');
        Strophe.addNamespace('PUBSUB', 'http://jabber.org/protocol/pubsub');
        Strophe.addNamespace('RAI', 'urn:xmpp:rai:0');
        Strophe.addNamespace('RECEIPTS', 'urn:xmpp:receipts');
        Strophe.addNamespace('REFERENCE', 'urn:xmpp:reference:0');
        Strophe.addNamespace('REGISTER', 'jabber:iq:register');
        Strophe.addNamespace('RETRACT', 'urn:xmpp:message-retract:0');
        Strophe.addNamespace('ROSTERX', 'http://jabber.org/protocol/rosterx');
        Strophe.addNamespace('RSM', 'http://jabber.org/protocol/rsm');
        Strophe.addNamespace('SID', 'urn:xmpp:sid:0');
        Strophe.addNamespace('SPOILER', 'urn:xmpp:spoiler:0');
        Strophe.addNamespace('STANZAS', 'urn:ietf:params:xml:ns:xmpp-stanzas');
        Strophe.addNamespace('STYLING', 'urn:xmpp:styling:0');
        Strophe.addNamespace('VCARD', 'vcard-temp');
        Strophe.addNamespace('VCARDUPDATE', 'vcard-temp:x:update');
        Strophe.addNamespace('XFORM', 'jabber:x:data');
        Strophe.addNamespace('XHTML', 'http://www.w3.org/1999/xhtml');
        Strophe.addNamespace('MAM', 'urn:xmpp:mam:2');
        Strophe.addNamespace('ROSTER_VER', 'urn:xmpp:features:rosterver');
    }

    login(jid: string, host: string, pass: string, callback?: () => void): void {
        this.jid = jid
        this.host = host
        this.password = pass
        this.isConnecting = true
        this.connection.connect(jid + '@' + host, pass, (status: Strophe.Status) => {
            if (status === Strophe.Status.DISCONNECTED) {
                this.isConnecting = false
                this.connection.connected = false
                this.login(jid, host, pass);
            }
            if (status === Strophe.Status.CONNECTED) {
                this.isConnecting = false
                this.connection.connected = true
                callback?.()
            }
            else {
                this.connection.connected = false
                this.isConnecting = false
            }
            this.onConnect(status);
        });
    }

    // Set room name.
    setRoomName(roomName: any): void {
        this.roomName = roomName;
    }

    // Get room Name
    getRoomName(): void {
        return this.roomName;
    }

    // Create timestamp for multi-user chat room id.
    timestamp(): number {
        return Math.floor(new Date().getTime() / 1000);
    }

    // Parse nickname of jabber id.
    getNick(): string {
        let nick = this.connection.jid;
        nick = nick.substring(0, nick.indexOf('@'));
        return nick;
    }

    /* Function
        Disconnects the client from the Jabber server.
      Parameters:
      Returns:
    */

    logout(): void {
        if (this.connection === null) return
        this.connection.options.sync = true; // Switch to using synchronous requests since this is typically called onUnload.
        this.connection.flush();
        this.connection.disconnect('logout');
    }

    enableCarbon(successCallback?: any, errorCallback?: any): string {
        const iq: Element = this.buildIQ(`
            <iq xmlns='jabber:client'
                from='${this.connection.jid}'
                id='enable-carbon'
                type='set'>
                <enable xmlns='urn:xmpp:carbons:2'/>
            </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback)
    }

    initXMPP(): void {
        logger('debug', 'Strophe is connected.');

        this.connection.roster.init(this.connection)
        this.connection.mamm.init(this.connection)
        this.connection.PubSub.init(this.connection)
        this.connection.PubSub.connect(this.connection.jid, process.env.REACT_APP_PUBSUB_SERVICE)

        this.connection.roster.get((result: any) => {
            console.log('[ROSTERS]', result)
            this.contacts.next(result);
        })

        this.connection.addHandler((msg: Element) => {
            return this.onMessage(msg);
        }, '', 'message');

        this.connection.addHandler((msg: Element) => {
            const presenceData = xmlToJson(msg)
            // console.log('[PRESENCE]', presenceData, msg)

            const isOnline = (('priority' in presenceData) && ('#text' in presenceData.priority) && presenceData.priority['#text'] === '0') || ('show' in presenceData && presenceData.show['#text'] === 'chat')

            const isOffline = ('type' in presenceData) && presenceData.type === 'unavailable'
            const isSubscribed = ('type' in presenceData) && presenceData.type === 'subscribed'

            const contactId = getNodeFromJid(presenceData.from)

            if (isSubscribed) {
                console.log('Authroize & Subscribe', `${contactId}@${HOST}`)
                this.connection.roster.authorize(`${contactId}@${HOST}`)
                this.connection.roster.subscribe(`${contactId}@${HOST}`)
            }

            if (isOnline) {
                this.rosterEvents.next({
                    type: RosterStateType.ONLINE,
                    from: contactId,
                    value: true
                })
            }
            else if (isOffline) {
                this.rosterEvents.next({
                    type: RosterStateType.ONLINE,
                    from: contactId,
                    value: false
                })
            }

            return true
        }, '', 'presence');

        this.connection.send($pres().tree());

        this.enableCarbon()
    }

    /* Function
        Connect XMPP.
      Parameters:
      Returns:
        status - Status of connection.
    */
    onConnect(status: Strophe.Status): void {
        switch (status) {

            case Strophe.Status.CONNECTED:
                this.initXMPP()

                break;
            case Strophe.Status.ATTACHED:
                logger('debug', 'Strophe is attached.');
                break;

            case Strophe.Status.DISCONNECTED:
                logger('debug', 'Strophe is disconnected.');
                break;

            case Strophe.Status.AUTHFAIL:
                logger('debug', 'Strophe authentication failed.');
                // this.logoutHandler();
                break;

            case Strophe.Status.CONNECTING:
                logger('debug', 'Strophe is connecting.');
                break;

            case Strophe.Status.DISCONNECTING:
                logger('debug', 'Strophe is disconnecting.');
                break;

            case Strophe.Status.AUTHENTICATING:
                logger('debug', 'Strophe is authenticating.');
                break;

            case Strophe.Status.ERROR:
            case Strophe.Status.CONNFAIL:
                logger('debug', 'Strophe connection failed.', status);
                // this.logoutHandler();
                break;

            default:
                logger('debug', 'Strophe unknown status.', status);
                window.location.href = '/sign-in'
                break;
        }
    }

    sendMessage(to: string, message: EncryptedMessage): void {
        try {
            console.log('SENDING MESSAGE', message)
            const finalMessage = JSON.stringify(message);

            const reply = $msg({
                to,
                from: this.connection.jid,
                type: 'chat',
                id: message.mid
            }).c('request', {
                xmlns: 'urn:xmpp:receipts'
            }).up()
                .c('body').t(finalMessage).up()
                .c('x', {
                    xmlns: 'jabber:x:event'
                })

            this.connection.send(reply.tree());

            this.messageDeliveryHandler({
                contactId: getNodeFromJid(to),
                messageId: message.mid,
                type: MessageDeliveryEventType.DELIVERED
            })
        } catch (error) {
            console.log('[ERROR]', error)
        }
    }

    correctMessage(to: string, messageId: string, message: EncryptedMessage): void {
        console.log('EDITING MESSAGE', to, messageId)

        const finalMessage = JSON.stringify(message)
        const id = uuidv4()
        const msg = $msg({
            to,
            id,
        }).c('body').t(finalMessage).up()
            .c('replace', {
                id: messageId,
                xmlns: 'urn:xmpp:message-correct:0'
            })

        console.log('[EDITING MESSAGE]', msg.tree())

        this.connection.send(msg);
    }

    correctGroupMessage(to: string, messageId: string, message: EncryptedGroupMessage): void {
        console.log('EDITING MESSAGE', to, messageId)

        const finalMessage = JSON.stringify(message)
        const id = uuidv4()
        const msg = $msg({
            to,
            id,
            type: 'groupchat'
        }).c('body').t(finalMessage).up()
            .c('replace', {
                id: messageId,
                xmlns: 'urn:xmpp:message-correct:0'
            })

        console.log('[EDITING MESSAGE]', msg.tree())

        this.connection.send(msg);
    }

    retractMessage(to: string, messageId: string): void {
        console.log('RETRACTING MESSAGE', to, messageId)
        const id = uuidv4()

        const msg = $msg({
            to,
            id,
            type: 'chat'
        }).c('apply-to', {
            id: messageId,
            xmlns: 'urn:xmpp:fasten:0'
        }).c('retract', {
            xmlns: 'urn:xmpp:message-retract:0'
        }).up()
            .c('fallback', {
                xmlns: 'urn:xmpp:fallback:0'
            }).up()
            .c('body').t('This person attempted to retract a previous message, but it\'s unsupported by your client.').up()
            .c('store', {
                xmlns: 'urn:xmpp:hints'
            })

        this.connection.send(msg);
    }

    sendGroupMessage(to: string, message: EncryptedGroupMessage): void {
        console.log('SENDING GROUP MESSAGE', to, message)
        const finalMessage = JSON.stringify(message);

        const reply = $msg({
            to,
            from: this.connection.jid,
            type: 'groupchat',
            id: message.mid
        })
            .c('request', {
                xmlns: 'urn:xmpp:receipts'
            }).up()
            .c('body').t(finalMessage).up()
            .c('x', {
                xmlns: 'jabber:x:event'
            })

        this.connection.send(reply.tree());
    }

    join(groupId: string): void {
        // const nickname = Strophe.getNodeFromJid(this.connection.jid)

        // console.log('joined to', `${groupId}@${GROUP_HOST}/${nickname}`,)

        // const presence = $pres({
        //     from: this.connection.jid,
        //     to: `${groupId}@${GROUP_HOST}/${nickname}`,
        //     // to: `${groupId}@${GROUP_HOST}`,
        // }).c('x', {
        //     xmlns: 'http://jabber.org/protocol/muc'
        // })

        // this.connection.send(presence.tree());
    }

    markChatAsRead(to: string): void {
        if (this.connection === undefined || !window.navigator.onLine) return

        const readEvent = $msg({
            to,
            from: this.connection.jid,
            type: 'chat',
        }).c('read').t('read')

        console.log('[CHAT] Mark as read')

        this.connection.send(readEvent.tree());
    }

    inviteToGroup(groupId: string, userId: string, timestamp: number): void {
        const invite = $msg({
            to: `${groupId}@${GROUP_HOST}`,
            from: this.connection.jid,
            id: uuidv4()
        })
            .c('x', {
                xmlns: 'http://jabber.org/protocol/muc#user'
            })
            .c('invite', {
                to: userId
            }).up().up()

        console.log(`invite to group ${groupId} to ${userId}`, invite, invite.tree())

        this.connection.send(invite.tree());
    }


    notifyMyself(data: JSON): void {
        const finalMessage = JSON.stringify(data);
        const id = uuidv4()

        const msg = $msg({
            to: this.connection.jid,
            from: this.connection.jid,
            type: 'chat',
            id
        }).c('request', {
            xmlns: 'urn:xmpp:receipts'
        }).up()
            .c('body').t(finalMessage).up()
            .c('x', {
                xmlns: 'jabber:x:event'
            })

        this.connection.send(msg.tree());
    }

    sendReceivedEvent(to: string, messageId: string): void {
        const msg = $msg({
            to,
            from: this.connection.jid,
            type: 'chat',
        }).c('received', {
            xmlns: 'urn:xmpp:receipts',
            id: messageId
        })
        this.connection.send(msg.tree());
    }

    sendComposingEvent(to: string): void {
        const msg = $msg({
            to,
            from: this.connection.jid,
            type: 'chat',
        }).c('composing', {
            xmlns: 'http://jabber.org/protocol/chatstates'
        })

        console.log('send composing event', msg, msg.tree())

        this.connection.send(msg.tree());
    }


    onMessage(msg: Element): boolean {
        const innerMessages = msg.getElementsByTagName('message')
        const from = msg.getAttribute('from')
        const body = msg.getElementsByTagName('body')[0]?.textContent;
        const parsedBody = (body !== null && isJSON(body)) ? JSON.parse(body) : null;
        const lastInnerMessage = innerMessages.length > 0 ? innerMessages[innerMessages.length-1] : msg
        const notSelfMessage = getNodeFromJid(msg.getAttribute('from') ?? '') !== getNodeFromJid(msg.getAttribute('to') ?? '');
        const delayExtension = msg.getElementsByTagName('delay')
        console.warn('NEW MESSAGE', msg, '\n', parsedBody)

        const json = xmlToJson(lastInnerMessage);
        const hasQueryId = msg.getElementsByTagName('result').length > 0 && msg.getElementsByTagName('result')[0].getAttribute('queryid') !== null;
        const isChatMessage = msg.getElementsByTagName('body').length > 0;
        const isTypingEvent = msg.getElementsByTagName('composing').length > 0;
        const isReceivedEvent = notSelfMessage && from !== null && !from.includes(GROUP_HOST) && msg.getElementsByTagName('received').length > 0
        const isGroupReceivedEvent = typeof from === 'string' && from.includes(GROUP_HOST) && msg.getElementsByTagName('received').length > 0;
        const isReadEvent = msg.getElementsByTagName('read').length > 0 && msg.getElementsByTagName('error').length === 0;
        const isDeleteMessageEvent = msg.getElementsByTagName('deleted_message_id').length > 0;
        const isGroupEvent = parsedBody !== null && parsedBody.t === 'GROUP_EVENT'
        const isGroupMessage = msg.getAttribute('from')?.includes(`@${GROUP_HOST}`)
        const isArchivedGroupMessage = (innerMessages.length > 0 && (innerMessages[0].getAttribute('from')?.includes(`@${GROUP_HOST}`) ?? innerMessages[0].getAttribute('to')?.includes(`@${GROUP_HOST}`)) === true) && delayExtension.length > 0
        const isCallEvent = parsedBody !== null && parsedBody.t === 'CALL'

        if (hasQueryId) {
            const queryId = msg.getElementsByTagName('result')[0].getAttribute('queryid');
            const body = msg.getElementsByTagName('body')[0];
            if (body === undefined || body.textContent === null) return true;

            if (!isJSON(body.textContent)) return true

            const jsonBody: EncryptedMessage = JSON.parse(body.textContent)
            if (queryId === null) return true;

            if (this.mamQueries[queryId] !== undefined) this.mamQueries[queryId].push({ jsonBody, msg })
            else this.mamQueries[queryId] = [{ jsonBody, msg }]
        }

        else if (isTypingEvent) this.typingHandler(json);

        else if (isReceivedEvent) {
            try {
                const contactId = getNodeFromJid(json.from)
                const messageId = json.received.id
                this.messageDeliveryHandler({
                    contactId,
                    messageId,
                    type: MessageDeliveryEventType.RECEIVED
                })
            } catch (error) {
                console.error(error, msg, json)
            }
        }

        else if (isReadEvent) {

            const contactId = notSelfMessage ? getNodeFromJid(json.from) : getNodeFromJid(json.to)
            this.messageDeliveryHandler({
                contactId,
                type: MessageDeliveryEventType.READ,
                isReceivedMessage: notSelfMessage
            })
        }


        else if (isGroupReceivedEvent) {
            console.log('group received', msg)
            const contactId = getNodeFromJid(from)
            const messageId = msg.getElementsByTagName('received')[0].getAttribute('id')

            if (messageId === null) return true;

            this.messageDeliveryHandler({
                contactId,
                messageId,
                type: MessageDeliveryEventType.RECEIVED
            })
        }

        else if (isGroupEvent) {
            try {
                const body = msg.getElementsByTagName('body')[0]

                if (body === undefined || body.textContent === null) return true;

                const jsonBody = JSON.parse(body.textContent) as GroupEvent
                this.setDelayedMessageTimestamp(delayExtension, jsonBody)

                this.groupEventHandler(isArchivedGroupMessage, jsonBody, msg)
            } catch (error) {
                console.error('[GROUP] Cannot parse message as JSON:', json, error)
            }
        }

        else if (isCallEvent) {
            try {
                this.callEventHandler(parsedBody, msg)
            } catch (error) {
                console.error('Cannot parse message as JSON:', json, error)
            }
        }

        else if (isArchivedGroupMessage || isGroupMessage === true) {
            try {

                const body = msg.getElementsByTagName('body')[0]
                if (body === undefined || body.textContent === null || !isJSON(body.textContent)) return true;

                const jsonBody: EncryptedMessage = JSON.parse(body.textContent);
                this.setDelayedMessageTimestamp(delayExtension, jsonBody)

                this.groupMessageHandler(jsonBody, msg)
            } catch (error) {
                console.error('[GROUP] Cannot parse message as JSON:', json, error)
            }
        }

        else if (isDeleteMessageEvent) {
            let fromJid = msg.getAttribute('from');
            const archivedMessage = msg.getElementsByTagName('message')
            if (archivedMessage.length !== 0)
                fromJid = archivedMessage[0].getAttribute('from');
            if (fromJid === null) return true;

            const jid = Strophe.getBareJidFromJid(fromJid);
            const elems = msg.getElementsByTagName('deleted_message_id');
            const messageId = elems[0].textContent;

            if (messageId === null) return true;
            const data: DeleteMessageEventData = { jid, messageId }

            this.deleteMessageHandler(data)
        }

        else if (isChatMessage) {
            try {
                const body = msg.getElementsByTagName('body')[0];
                if (body.textContent === null) return true;
                if (!isJSON(body.textContent)) return true;
                if (body.textContent.includes('groupXmppId')) return true;

                const jsonBody = JSON.parse(body.textContent)
                this.setDelayedMessageTimestamp(delayExtension, jsonBody)
                this.messageQueue.enqueue(() => {
                    this.messageHandler(delayExtension.length > 0 , jsonBody, msg);
                }).catch((error) => {
                    console.error('message not added', error)
                })
            } catch (error) {
                console.error('[P2P] Message', error, msg.getElementsByTagName('body')[0])
            }
        }

        return true;
    }

    setDelayedMessageTimestamp(delayExtension: HTMLCollectionOf<Element>, jsonBody: any): void {
        if (delayExtension.length > 0) {
            const messageTimestamp = delayExtension[0].getAttribute("stamp")
            if (messageTimestamp !== undefined && messageTimestamp !== null) {
                jsonBody.ts = new Date(messageTimestamp).getTime().toString();
            }
        }
    }

    deleteMessage(jid: string, messageId: string): void {
        console.log('sending delete message event to', jid, messageId)
        const id = uuidv4()

        const msg = $msg({
            to: jid,
            from: this.connection.jid,
            type: 'chat',
            id
        }).c('deleted_message_id', {
            xmlns: 'urn:xmpp:message-correct:0'
        })
            .t(messageId).up()
            .c('body').t("delete").up()
        this.connection.send(msg.tree());
    }

    deleteGroupMessage(jid: string, messageId: string): void {
        console.log('sending delete message event to', jid, messageId)
        const id = uuidv4()

        const msg = $msg({
            to: jid,
            from: this.connection.jid,
            type: 'groupchat',
            id
        }).c('deleted_message_id', {
            xmlns: 'urn:xmpp:message-correct:0'
        }).t(messageId).up()

        this.connection.send(msg.tree());
    }

    onPresence(msg: any): void {
        console.warn('%cPRESENCE RECEIVED', 'background: #222; color: #bada55', "\n", msg);
    }

    block(jid: string, successCallback: any, errorCallback: any): string {
        console.log(`BLOCKING ${jid} from ${this.connection.jid}`)
        const iq: Element = this.buildIQ(`
        <iq type='set' id='block-${jid}' from='${this.connection.jid}'>
            <block xmlns='urn:xmpp:blocking'>
                <item jid='${jid}'></item>
            </block>
        </iq>`)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    fetchMyVCARD(successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const iq = $iq({
            from: this.connection.jid,
            type: 'get'
        }).c('vCard', {
            xmlns: Strophe.NS.VCARD
        });

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    fetchContactVirtualCard(jid: any, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const bareJid = Strophe.getBareJidFromJid(jid)
        const iq = $iq({
            to: bareJid,
            id: Math.floor(Math.random() * 1000000).toString(),
            type: 'get'
        }).c('vCard', {
            xmlns: Strophe.NS.VCARD
        });

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    requestContactLastSeen(jid: string, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const iq = this.buildIQ(`
        <iq from='${this.connection.jid}'
            to='${jid}'
            type='get'>
            <query xmlns='jabber:iq:last'/>
        </iq>`)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    test_appendNick(room: string, nick: string): string {
        const node = Strophe.escapeNode(getNodeFromJid(room));
        const domain = Strophe.getDomainFromJid(room);

        return node + '@' + domain + (nick != null ? '/' + nick : '');
    }

    leave(room: any): void {
        console.log('Leaving group', room)

        const presenceid = this.connection.getUniqueId();
        const presence = $pres({
            type: 'unavailable',
            id: presenceid,
            from: this.connection.jid,
            to: room
        });

        this.connection.send(presence);
    }

    endGroupCall(group: Contact): void {
        const myId = getNodeFromJid(this.connection.jid)
        const groupId = getNodeFromJid(group.jid)
        const timestamp = generateTimestamp()
        const toId = `${groupId}@${GROUP_HOST}`;

        const groupEvent: EncryptedGroupMessage = {
            c: `${myId}:GROUP_CALL_ENDED:${groupId}`,
            fid: myId,
            mid: uuidv4(),
            t: MessageType.GROUP_EVENT,
            tid: groupId,
            ts: timestamp.toString()
        }

        this.sendGroupMessage(toId, groupEvent)
    }

    buildIQ(xml: string, type: DOMParserSupportedType = 'text/xml'): Element {
        const iq = this.domParser.parseFromString(xml, type)
        return iq.getElementsByTagName('iq')[0]
    }

    buildPresence(xml: string, type: DOMParserSupportedType = 'text/xml'): Element {
        const pres = this.domParser.parseFromString(xml, type)
        return pres.getElementsByTagName('presence')[0]
    }

    getLastMessagesByJid(jid: string, timestamp?: number | undefined, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const formattedTimestamp = timestamp !== undefined ? new Date(timestamp + 855).toISOString() : ''
        const timestampStr = timestamp !== undefined ? `<field var='start'><value>${formattedTimestamp}</value></field>` : ''

        const iq: Element = this.buildIQ(`
        <iq type='set'>
            <query xmlns='urn:xmpp:mam:2'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE' type='hidden'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                    <field var='with'>
                        <value>${jid}</value>
                    </field>
                    ${timestampStr}
                </x>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <max>50</max>
                    <before/>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    getMyMessages(successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const myjid = getNodeFromJid(this.connection.jid) + '@' + HOST

        console.log('mm', myjid)
        const iq: Element = this.buildIQ(`
        <iq type='set' to='${myjid}'>
            <query xmlns='urn:xmpp:mam:2'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE' type='hidden'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                </x>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    getChannelMessages(channelId: string, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        console.log('channelId', channelId)
        const iq: Element = this.buildIQ(`
        <iq type='set' to='${channelId}'>
            <query xmlns='urn:xmpp:mam:2'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE' type='hidden'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                </x>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <max>100</max>
                    <before/>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    getLastMessagesByGroup(jid: string, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const queryId = uuidv4()

        let myJid = this.connection.jid
        myJid = myJid.split('/')[0]

        console.log('group', myJid)

        const iq: Element = this.buildIQ(`
        <iq to='${myJid}' id='GROUP-${jid}' type='set'>
            <query xmlns='urn:xmpp:mam:2'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                    <field var='start'>
                        <value>2023-04-20T08:39:32.704+00:00</value>
                    </field>
                </x>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <before />
                    <max>500</max>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, (data: Element) => {
            const retrievedMessages = this.mamQueries[queryId]
            console.log('retrievedMessages', retrievedMessages)
        }, (err: Element) => {
            console.error(err)
        });
    }

    getMyLastMessagesByGroup(jid: string, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const queryId = uuidv4()

        let myJid = this.connection.jid
        myJid = myJid.split('/')[0]

        console.log('myJid', jid, myJid)

        const iq: Element = this.buildIQ(`
        <iq to='${jid}' id='GROUP-${jid}' type='set'>
            <query xmlns='urn:xmpp:mam:2'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                    <field var='with'>
                        <value>${myJid}</value>
                    </field>
                    <field var='start'>
                        <value>2023-04-20T08:39:32.704+00:00</value>
                    </field>
                </x>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <before />
                    <max>500</max>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, (data: Element) => {
            const retrievedMessages = this.mamQueries[queryId]
            console.log('retrievedMessages', retrievedMessages)
        }, (err: Element) => {
            console.error(err)
        });
    }

    getAllLastMessages(successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const iq: Element = this.buildIQ(`
        <iq type='set'>
            <query xmlns='urn:xmpp:mam:2'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE' type='hidden'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                </x>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <before/>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    getLastMessagesByMid(mid: string, successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string {
        const iq: Element = this.buildIQ(`
        <iq type='set' id='q29302'>
            <query xmlns='urn:xmpp:mam:2'>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <after>${mid}</after>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    getMessagesWithPagination(jid: string, message: DecryptedMessage | undefined, timestamp?: number | undefined, successCallback?: (data: QueuedMessage[] | undefined) => void, errorCallback?: ErrorCallback): string {
        const queryId = uuidv4()
        const formattedTimestamp = timestamp !== undefined ? new Date(timestamp + 855).toISOString() : ''
        const timestampStr = timestamp !== undefined ? `<field var='start'><value>${formattedTimestamp}</value></field>` : ''
        const iq: Element = this.buildIQ(`
        <iq type='set'>
            <query xmlns='urn:xmpp:mam:2' queryid='${queryId}'>
                <x xmlns='jabber:x:data' type='submit'>
                    <field var='FORM_TYPE'>
                        <value>urn:xmpp:mam:2</value>
                    </field>
                    <field var='with'>
                        <value>${jid}</value>
                    </field>
                    ${timestampStr}
                </x>
                <set xmlns='http://jabber.org/protocol/rsm'>
                    <max>50</max>
                    <before>${message?.mamId ?? ''}</before>
                </set>
            </query>
        </iq>
        `)

        return this.connection.sendIQ(iq, (data: Element) => {
            const retrievedMessages = this.mamQueries[queryId]
            if (successCallback !== undefined) successCallback(retrievedMessages)
        }, (error: Element) => {
            if (errorCallback !== undefined) errorCallback(error)
        })
    }

    getOfflineMessages (successCallback?: SuccessCallback, errorCallback?: ErrorCallback): string{
        const iq: Element = this.buildIQ(`
            <iq type='get' id='fetch1'>
                <offline xmlns='http://jabber.org/protocol/offline'>
                    <fetch/>
                </offline>
            </iq>
        `)

        return this.connection.sendIQ(iq, successCallback, errorCallback);
    }

    addPlugins(): void {
        this.connection.roster = new StropheRoster()
        this.connection.mamm = new StropheMAM()
        this.connection.RSM = new StropheRSM({})
        this.connection.PubSub = new StrophePubSub()
    }

    // Strophe get archived messages from a room
    // Parameters:
    // (String) room - The multi-user chat room name.
    // (String) with - The XMPP JID of the user to fetch history with.
    // (Function) handleCb - The callback function to handle the result.
    // (Function) errorCb - The callback function to handle an error.
    // Returns:
    // msgiq - the unique id used to send the message
    async loadMessagesFrom(from: any): Promise<EncryptedMessage[]> {
        const convertor = this.messageConvertorService;
        const messagesArray: EncryptedMessage[] = [];
        const connection = this.connection;

        this.connection.mamm.query(Strophe.getBareJidFromJid(this.connection.jid), {
            with: from,
            start: 0,
            end: 0,
            withtext: '',
            onMessage(message: Element) {
                messagesArray.push(convertor.convertXMPPMessageToMessage(message, connection.jid,
                    false
                ));
                return true;
            },
            onComplete(response: any) {
                console.log(messagesArray);
                return response;
            }
        })

        return messagesArray;
    }
}

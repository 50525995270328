import { useCallback, useEffect, useMemo, useRef, useState } from "react";
interface Props {
  shown?: boolean;
  className?: string;
  trigger: JSX.Element;
  children: React.ReactNode;
}
function Dropdown({
  shown,
  trigger,
  className,
  children
}: Props): JSX.Element {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [active, $active] = useState(shown ?? false);
  const handleClickOutside = useCallback((e: Event) => {
    if (dropdownRef.current === null) return;
    if (dropdownRef.current.contains((e.target as Node))) return;
    $active(false);
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
  const renderDropdown = useMemo(() => <div ref={dropdownRef} className={`absolute bg-white w-[max-content] drop-shadow overflow-hidden rounded-xl top-8 z-50 ${className ?? ''}`}>
            {children}
        </div>, [children, className]);
  return <div className="relative">
            <div onClick={() => {
      $active(!active);
    }}>
                {trigger}
            </div>
            {active && renderDropdown}
        </div>;
}
export default Dropdown;
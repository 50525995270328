const { $build, Strophe } = window

type StropheRSMAttributes = 'max' | 'first' | 'last' | 'after' | 'before' | 'index' | 'count'

class StropheRSM {
    attribs: StropheRSMAttributes[];
    index: any;
    max: any;
    first: any;
    last: any;
    after: any;
    before: any;
    count: any;


    constructor(options: any) {
        this.attribs = ['max', 'first', 'last', 'after', 'before', 'index', 'count'];

        if (typeof options.xml !== 'undefined') {
            this.fromXMLElement(options.xml);
        } else {
            for (let ii = 0; ii < this.attribs.length; ii++) {
                const attrib = this.attribs[ii];
                this[attrib] = options[attrib];
            }
        }
    }

    toXML(): Element {
        let xml = $build('set', { xmlns: Strophe.NS.RSM });
        for (let ii = 0; ii < this.attribs.length; ii++) {
            const attrib = this.attribs[ii];
            if (typeof this[attrib] !== 'undefined') {
                xml = xml.c(attrib).t(this[attrib].toString()).up();
            }
        }
        return xml.tree();
    }

    next(max: any): StropheRSM {
        const newSet = new StropheRSM({ max, after: this.last });
        return newSet;
    }

    previous(max: any): StropheRSM {
        const newSet = new StropheRSM({ max, before: this.first });
        return newSet;
    }

    fromXMLElement(xmlElement: any): void {
        for (let ii = 0; ii < this.attribs.length; ii++) {
            const attrib = this.attribs[ii];
            const elem = xmlElement.getElementsByTagName(attrib)[0];
            if (typeof elem !== 'undefined' && elem !== null) {
                this[attrib] = Strophe.getText(elem);
                if (attrib === 'first') {
                    this.index = elem.getAttribute('index');
                }
            }
        }
    }
}

export default StropheRSM

import { createSlice } from '@reduxjs/toolkit'

export interface AppState {
    settings: boolean;
    logout: boolean;
}

export const initialState: AppState = {
    settings: false,
    logout: false,
}

export const appSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        showSettings: (state: AppState) => {
            state.settings = true
        },
        hideSettings: (state: AppState) => {
            state.settings = false
        },
        showLogout: (state: AppState) => {
            state.logout = true
        },
        hideLogout: (state: AppState) => {
            state.logout = false
        },
        resetSettings: (state: AppState) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    showSettings,
    hideSettings,
    showLogout,
    hideLogout,
} = appSlice.actions

export default appSlice.reducer

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function strLimit(str: string, limit: number): string {
    return str.length > limit ? str.substring(0, limit) + '...' : str
}

export function clamp(num: number, max: number): number {
    return Math.min(Math.max(num, num), max);
}

export const isFilled = (variable: any): boolean => (variable !== null && variable !== undefined && variable !== "");

export function isJSON(str: string | null | undefined): boolean {
    if (str === null || str === undefined) return false;

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function formatDuration(value: number): string {
    value = Math.floor(value)
    let minutes: string | number = Math.floor(value / 60);
    let seconds: string | number = value - minutes * 60;

    if (minutes < 10) minutes = `0${minutes}`
    if (seconds < 10) seconds = `0${seconds}`

    return `${minutes}:${seconds}`
}



export async function hash(str: string, algorithm: AlgorithmIdentifier = 'SHA-256'): Promise<string> {
    const utf8 = new TextEncoder().encode(str);

    return await crypto.subtle.digest(algorithm, utf8).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, '0'))
            .join('');
        return hashHex;
    });
}

export function generateGradientStyleFromId(id: string): object {
    const bytes = new TextEncoder().encode(id);
    const hashedValue = bytes.reduce((acc, byte) => (acc << 5) + acc + byte, 5381);

    const baseRed = (((hashedValue & 0xFF00F0) >> 16) % 206 + 50) / 320.0 * 255
    const baseGreen = (((hashedValue & 0xF0FF00) >> 8) % 206 + 50) / 320.0 * 255
    const baseBlue = ((hashedValue & 0x0F00FF) % 206 + 50) / 320.0 * 255


    const lighterRed = Math.min(baseRed + 0.1 * 255, 255)
    const lighterGreen = Math.min(baseGreen + 0.1 * 255, 255)
    const lighterBlue = Math.min(baseBlue + 0.1 * 255, 255)

    const darkerRed = Math.max(baseRed - 0.1 * 255, 0.0)
    const darkerGreen = Math.max(baseGreen - 0.1 * 255, 0.0)
    const darkerBlue = Math.max(baseBlue - 0.1 * 255, 0.0)

    const lighterColor = `rgb(${lighterRed}, ${lighterGreen}, ${lighterBlue})`
    const darkerColor = `rgb(${darkerRed}, ${darkerGreen}, ${darkerBlue})`

    return {
        backgroundImage: `linear-gradient(to bottom, ${lighterColor}, ${darkerColor})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }
}


export function updateObjects(originalObjects: any[], updatedObjects: any[], key: string): any[] {
    const newObjects = [...originalObjects]
    updatedObjects.forEach(updatedObject => {
        const index = newObjects.findIndex(originalObject => originalObject[key] === updatedObject[key])
        if (index === -1) return
        newObjects[index] = { ...newObjects[index], ...updatedObject }
    })
    return newObjects
}

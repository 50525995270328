import { MessageType } from "src/types/Ejabberd/MessageType";
import { getNodeFromJid } from "./contact";
import { encryptAES, generateAes, generateEncryptedMessageContent, generateIv } from "./message";

import { type Contact } from "src/types/Contact";
import { type RecordingData } from "src/components/AudioRecorder";
import { type User } from "src/types/User";
import { type EncryptedMessage, type EncryptedGroupMessage } from "src/types/Ejabberd/Message";
import { getAWSSignedUrl } from "./file";
import axios from "axios";
import { generateEncryptedMessageContentForGroup } from "./group"


export function computeElapsedTime(startTime: any): string {
    const endTime: any = new Date();

    let timeDiff: any = endTime - startTime;
    timeDiff = timeDiff / 1000;

    let seconds: string | number = Math.floor(timeDiff % 60); // ignoring uncomplete seconds (floor)
    seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();

    timeDiff = Math.floor(timeDiff / 60);

    let minutes: string | number = timeDiff % 60; // no need to floor possible incomplete minutes, becase they've been handled as seconds
    minutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();

    timeDiff = Math.floor(timeDiff / 60);

    const hours = timeDiff % 24; // no need to floor possible incomplete hours, becase they've been handled as seconds

    timeDiff = Math.floor(timeDiff / 24);

    const days = timeDiff; // add days to hours

    let totalHours: string | number = hours + (days * 24);
    totalHours = totalHours < 10 ? "0" + totalHours.toString() : totalHours.toString();

    if (totalHours === "00") {
        return minutes + ":" + seconds;
    } else {
        return totalHours + ":" + minutes + ":" + seconds;
    }
}

export function formatTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);

    return `${minutes < 10 ? '0' + minutes.toString() : minutes.toString()}:${seconds < 10 ? '0' + seconds.toString() : seconds.toString()}`;
}

export const uploadAudio = async (filename: string, recordingData: RecordingData): Promise<string | false> => {
    const path = `media/voices/${filename}`
    const signedData = await getAWSSignedUrl(path, 'PUT_OBJECT')

    if (recordingData.blob === null) return false
    if (signedData === false) return false

    const upload = await axios.put(signedData.url, recordingData.blob, {
        onUploadProgress: (evt) => {
            // console.log('[UPLOAD AUDIO PROGRESS]', Math.round((evt.loaded / evt.total) * 100))
        }
    })

    if (upload.status !== 200) return false

    return filename
}

export const prepareAudioMessageForContact = async (
    filename: string,
    contact: Contact,
    recordingData: RecordingData,
    userData: User,
    additional = {}
): Promise<EncryptedMessage | false> => {
    const aes = generateAes()
    const iv = generateIv()
    const encryptedAudioPath = encryptAES(filename, aes, iv)
    const myEncryptedAudioPath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedAudioPath,
        mdt: myEncryptedAudioPath,
        md: JSON.stringify({
            d: recordingData.duration?.toFixed(1).toString(),
            s: recordingData.size
        })
    }

    return generateEncryptedMessageContent(
        MessageType.VOICE,
        JSON.stringify(attachment),
        getNodeFromJid(contact.jid),
        contact.key, aes, iv, userData, additional
    )
}

export const prepareAudioMessageForGroup = async (
    filename: string,
    group: Contact,
    recordingData: RecordingData,
    user: User,
    additional = {}
): Promise<EncryptedGroupMessage | false> => {
    const [iv, aes] = group.key.split(',')
    const encryptedAudioPath = encryptAES(filename, aes, iv)
    const encryptedMyAudioPath = encryptAES(filename, user.aes, user.iv)

    const attachment = {
        dt: encryptedAudioPath,
        mdt: encryptedMyAudioPath,
        md: JSON.stringify({
            d: recordingData.duration?.toFixed(1).toString(),
            s: recordingData.size
        })
    }


    return generateEncryptedMessageContentForGroup(
        MessageType.VOICE,
        JSON.stringify(attachment),
        group,
        user,
        additional
    )
}


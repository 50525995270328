import { useCallback, useEffect } from "react";
import ImageMessage from "src/components/Messages/ImageMessage";
import VideoMessage from "src/components/Messages/VideoMessage";
import { useAppDispatch, useContacts } from "src/hooks/store";
import { hideGallery, showGallery } from "src/store/slices/contacts";
import { MessageType } from "src/types/Ejabberd/MessageType";
function Gallery(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const {
    galleryItems,
    mainGalleryItem,
    activeContact,
    activeGroup
  } = useContacts();
  const closeGallery = useCallback(() => {
    dispatch(hideGallery());
  }, [dispatch]);
  const index = galleryItems.findIndex(item => item.id === mainGalleryItem?.id);
  const handleGalleryNavigation = useCallback((message: string) => {
    if (mainGalleryItem === null) return;
    const nextMessage = message === 'next' ? galleryItems[index + 1] : galleryItems[index - 1];
    if (nextMessage === undefined) return;
    dispatch(showGallery({
      galleryItems,
      mainGalleryItem: nextMessage
    }));
  }, [dispatch, galleryItems, index, mainGalleryItem]);
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        closeGallery();
        break;
      case 'ArrowLeft':
        handleGalleryNavigation('previous');
        break;
      case 'ArrowRight':
        handleGalleryNavigation('next');
        break;
      default:
        break;
    }
  }, [closeGallery, handleGalleryNavigation]);
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  if (galleryItems.length === 0 || mainGalleryItem === null) return null;
  return <div className='absolute top-0 left-0 w-full h-full bg-white z-100'>

            <button onClick={closeGallery} className="absolute top-8 right-8 clickable">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9959 1.00488L1.00586 12.9924" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13 13L1 1" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            <div className="w-full h-20 flex items-center justify-center">
                <span className="text-xl font-semibold">{activeContact?.name ?? activeGroup?.name}</span>
            </div>

            <div className="mb-20 h-[calc(100%_-_240px)]">
                {mainGalleryItem !== null && (mainGalleryItem.type === MessageType.IMAGE ? <ImageMessage key={mainGalleryItem.id} message={mainGalleryItem} isMainGalleryItem /> : <VideoMessage key={mainGalleryItem.id} message={mainGalleryItem} isMainGalleryItem />)}
                {index > 0 && <button onClick={() => {
        handleGalleryNavigation('previous');
      }} className="absolute left-10 top-[50%] translate-y-[-50%] clickable">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                    </button>}
                {index < galleryItems.length - 1 && <button onClick={() => {
        handleGalleryNavigation('next');
      }} className="absolute right-10 top-[50%] translate-y-[-50%] clickable">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>}
            </div>

            <div className="absolute bottom-0 h-40 flex w-full items-center overflow-x-scroll">
                {galleryItems.map(item => item.type === MessageType.IMAGE ? <ImageMessage key={item.id} message={item} isGalleryItem /> : <VideoMessage key={item.id} message={item} isGalleryItem />)}
            </div>

        </div>;
}
export default Gallery;
import React, { useCallback, useMemo, useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import LoadingSpinner from '../LoadingSpinner';
interface Props {
  src: string;
  onClose: () => void;
  onSend: (file: File) => void;
}
const availableTabs = [TABS.ADJUST, TABS.ANNOTATE
// TABS.WATERMARK
];

const defaultTab = TABS.ANNOTATE;
const defaultTool = TOOLS.PEN;
function ImageCropper({
  src,
  onClose,
  onSend
}: Props): JSX.Element {
  const ref = React.useRef<any>(null);
  const [loading, $loading] = useState(false);
  const send = useCallback(() => {
    if (ref.current === null) return;
    $loading(true);
    setTimeout(() => {
      const {
        imageData
      } = ref.current();
      imageData.imageCanvas.toBlob(onSend);
      $loading(false);
    }, 300);
  }, [onSend]);
  const renderNavigation = useMemo(() => <div className='bg-hover-bg w-full p-4 flex items-center justify-between'>
            <div className="flex items-center">
                <button onClick={onClose} className='w-12 h-12 flex items-center justify-center rounded-lg mx-1 transition hover:bg-light-blue'>
                        <i className='bi bi-x-lg' />
                </button>
            </div>
            <span className='text-black text-primary font-medium'>Image preview</span>

            <div className="flex items-center">
                    <button onClick={send} className='w-12 h-12 flex items-center justify-center rounded-lg mx-1 transition hover:bg-light-blue'>
                    <i className="bi bi-send text-primary text-lg" color='white'></i>
                    </button>
            </div>
        </div>, [onClose, send]);
  const renderEditor = useMemo(() => <div className="relative flex flex-col w-full h-[calc(100%_-_110px)]">

            {loading && <div className="absolute z-50 bg-gray-50 flex items-center justify-center w-full h-full">
                <LoadingSpinner wrapperClass="w-12 h-12" />
                <span className='ml-4 font-light'>Processing..</span>
            </div>}

            <FilerobotImageEditor source={src} annotationsCommon={{
      fill: '#ff0000'
    }} Text={{
      text: 'Text here...'
    }} getCurrentImgDataFnRef={ref} Rotate={{
      angle: 90,
      componentType: 'slider'
    }} tabsIds={availableTabs} defaultTabId={defaultTab} defaultToolId={defaultTool}
    // onBeforeSave={(info: any) => console.log(info)}
    // onSave={(editedImageObject: any, designState: any) => {
    //     console.log(editedImageObject, designState)
    // }}
    />
        </div>, [loading, src]);
  return <>
            {renderNavigation}
            {renderEditor}
        </>;
}
export default ImageCropper;
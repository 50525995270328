import { encryptAES, generateAes, generateEncryptedMessageContent, generateIv } from "./message"
import { getNodeFromJid } from "./contact"
import { type Contact } from "src/types/Contact"
import { MessageType } from "src/types/Ejabberd/MessageType"
import { type User } from "src/types/User";
import { type EncryptedMessage, type EncryptedGroupMessage } from "src/types/Ejabberd/Message"
import { convertBytesToReadable, getAWSSignedUrl } from "./file"
import axios from "axios"
import { type GiphyData } from 'src/types/giphy'
import { generateEncryptedMessageContentForGroup } from './group'

export const uploadImage = async (blob: Blob, filename: string, controller: AbortController): Promise<string | false> => {
    const path = `media/images/${filename}`
    const signedData = await getAWSSignedUrl(path, 'PUT_OBJECT')

    if (signedData === false) return false

    const upload = await axios.put(signedData.url, blob, {
        signal: controller.signal,
        onUploadProgress: (evt) => {
            // console.log('[UPLOAD IMAGE PROGRESS]', Math.round((evt.loaded / evt.total) * 100))
        }
    })

    if (upload.status !== 200) return false

    return filename
}

export async function prepareImageMessageForContact(
    contact: Contact,
    filename: string,
    thumbnail: string,
    filesize: string,
    width: number,
    height: number,
    userData: User,
    additional: object = {}
): Promise<EncryptedMessage | false> {
    const aes = generateAes()
    const iv = generateIv()
    const encryptedImagePath = encryptAES(filename, aes, iv)
    const myEncryptedImagePath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedImagePath,
        mdt: myEncryptedImagePath,
        md: JSON.stringify({
            s: filesize,
            w: width,
            h: height
        }),
        tb: thumbnail,

    }

    return generateEncryptedMessageContent(
        MessageType.IMAGE,
        JSON.stringify(attachment),
        getNodeFromJid(contact.jid),
        contact.key, aes, iv, userData, additional
    )
}

export async function prepareGifMessageForContact(
    contact: Contact,
    gifData: GiphyData,
    userData: User,
    additional: object = {}
): Promise<EncryptedMessage | false> {
    const aes = generateAes()
    const iv = generateIv()

    const data = gifData.images.fixed_width
    const filename = data.url

    const size = convertBytesToReadable(parseInt(data.size))

    const encryptedGifPath = encryptAES(filename, aes, iv)
    const myEncryptedGifPath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedGifPath,
        mdt: myEncryptedGifPath,
        md: JSON.stringify({
            s: size,
            w: parseInt(data.width),
            h: parseInt(data.height)
        })
    }

    return generateEncryptedMessageContent(
        MessageType.GIF,
        JSON.stringify(attachment),
        getNodeFromJid(contact.jid),
        contact.key, aes, iv, userData, additional
    )
}

export async function prepareGifMessageForGroup(
    group: Contact,
    gifData: GiphyData,
    userData: User,
    additional: object = {}
): Promise<EncryptedGroupMessage | false> {
    const [iv, aes] = group.key.split(',')

    const data = gifData.images.fixed_width
    const filename = data.url

    const encryptedGifPath = encryptAES(filename, aes, iv)
    const encryptedMyGifPath = encryptAES(filename, userData.aes, userData.iv)

    const size = convertBytesToReadable(parseInt(data.size))

    const attachment = {
        dt: encryptedGifPath,
        mdt: encryptedMyGifPath,

        md: JSON.stringify({
            s: size,
            w: parseInt(data.width),
            h: parseInt(data.height)
        })
    }

    return generateEncryptedMessageContentForGroup(
        MessageType.GIF,
        JSON.stringify(attachment),
        group,
        userData, additional
    )
}

// export async function prepareImageMessageForGroup(
//     group: Group,
//     filename: string,
//     thumbnail: string,
//     filesize: string,
//     user: User,
//     additional: object = {}
// ): Promise<EncryptedGroupMessage> {
//     const [iv, aes] = group.secret.split(',')
//     const encryptedImagePath = encryptAES(filename, aes, iv)

//     const attachment = {
//         tb: thumbnail,
//         dt: encryptedImagePath,
//         s: filesize
//     }

//     const groupMsg: EncryptedGroupMessage = {
//         t: MessageType.IMAGE,
//         mid: uuidv4(),
//         fid: user.uid,
//         tid: group.id,
//         at: JSON.stringify(attachment),
//         ts: generateTimestamp().toString(),
//         ...additional
//     }

//     return groupMsg
// }

export async function prepareImageMessageForGroup(
    group: Contact,
    filename: string,
    thumbnail: string,
    filesize: string,
    width: number,
    height: number,
    userData: User,
    additional: object = {}
): Promise<EncryptedGroupMessage | false> {
    const [iv, aes] = group.key.split(',')
    const encryptedImagePath = encryptAES(filename, aes, iv)
    const encryptedMyImagePath = encryptAES(filename, userData.aes, userData.iv)

    const attachment = {
        dt: encryptedImagePath,
        mdt: encryptedMyImagePath,
        md: JSON.stringify({
            s: filesize,
            w: width,
            h: height
        }),
        tb: thumbnail
    }

    return generateEncryptedMessageContentForGroup(
        MessageType.IMAGE,
        JSON.stringify(attachment),
        group,
        userData, additional
    )
}


export async function generateThumbnail(file: File | Blob, boundBox: [number, number], quality: number = 0.05): Promise<[string, number, number]> {
    if (boundBox.length !== 2) {
        throw new Error('Invalid boundBox')
    }

    const reader = new FileReader();
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext('2d');

    if (ctx === null) return await Promise.reject(new Error('Canvas context is null'))

    return await new Promise((resolve, reject) => {
        reader.onload = function (event) {
            const img = new Image();

            img.onload = function () {
                const originalBoundBox = [img.width, img.height]

                const scaleRatio = Math.min(...boundBox) / Math.max(...originalBoundBox)
                const scaledWidth = img.width * scaleRatio
                const scaledHeight = img.height * scaleRatio

                canvas.width = scaledWidth;
                canvas.height = scaledHeight;
                ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
                resolve([canvas.toDataURL('image/jpeg', quality), img.width, img.height]);
            }

            if (event.target === null) { reject(new Error('event.target is null')); return; }

            img.src = event.target.result as string;
        }

        reader.readAsDataURL(file);
    })
}


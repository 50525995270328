import { type PayloadAction } from "@reduxjs/toolkit"
import { type ContactsState } from ".."
import { type DecryptedMessage } from "src/types/Message"

const reducers = {
    showGallery: (state: ContactsState, action: PayloadAction<{
        galleryItems: DecryptedMessage[],
        mainGalleryItem: DecryptedMessage | null
    }>) => {
        const { galleryItems, mainGalleryItem } = action.payload
        state.galleryItems = galleryItems
        state.mainGalleryItem = mainGalleryItem
    },
    hideGallery: (state: ContactsState) => {
        state.galleryItems = []
        state.mainGalleryItem = null
    }
}

export default reducers

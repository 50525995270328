import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ContextMenu, { type ContextMenuItem } from "src/components/ContextMenu";
import AddParticipant from "src/components/UI/Buttons/Group/AddParticipant";
import { HOST, xmpp } from "src/constants/xmpp";
import { fetchVirtualCardForContact, getNodeFromJid } from "src/helpers/contact";
import { useAppDispatch, useContacts } from "src/hooks/store";
import API from "src/services/Api";
import { removeGroupMember, setActiveContact, setActiveGroup, setGroupAddMember, setGroupMenu, updateGroup, updateGroupMember, updateContact } from "src/store/slices/contacts";
import { type GroupUser as IGroupUser, type GroupUserActions, GroupAffiliations } from 'src/types/Group';
import GroupUser from "../GroupUser";
import LeaveGroup from "src/components/UI/Modals/LeaveGroup";
import Modal from "src/components/Modal";
import { useKeyboardListener } from "src/hooks/useKeyboardListener";
import clsx from "clsx";
import LoadingSpinner from "src/components/LoadingSpinner";
import { toast } from "react-hot-toast";
import LoadingImage from "src/components/LoadingImage";
import { ContactTypes, type Contact } from "src/types/Contact";
import PaginatedList from "src/components/PaginatedList";
import { generateGradientStyleFromId } from "src/helpers/common";
function GroupMenu(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const [edit, $edit] = useState(false);
  const [loading, $loading] = useState(false);
  const {
    activeGroup,
    contacts,
    groupMenu,
    groupAddMember
  } = useContacts();
  const [search, $search] = useState<string | null>(null);
  const [editingName, $editingName] = useState('');
  const [editingFile, $editingFile] = useState<File>();
  const [editingFileBase64, $editingFileBase64] = useState<string>('');
  const [subscribers, $subscribers] = useState<IGroupUser[]>([]);
  const [subscribersLoaded, $subscribersLoaded] = useState(false);
  const allMembers = useMemo(() => [...(activeGroup?.members ?? []), ...subscribers], [activeGroup?.members, subscribers]);
  const fileInput = useRef<HTMLInputElement>(null);
  const pickAvatar = useCallback(() => {
    fileInput.current?.click();
  }, []);
  const me = activeGroup?.members?.find(member => member.id === getNodeFromJid(xmpp.connection.jid));
  const closeGroupMenu = useCallback(() => {
    $edit(false);
    $editingName('');
    $editingFileBase64('');
    $editingFile(undefined);
    dispatch(setGroupMenu(false));
  }, [dispatch]);
  const showAddMembers = useCallback(() => dispatch(setGroupAddMember(true)), [dispatch]);
  const [showLeaveGroup, $showLeaveGroup] = useState(false);
  const removeMember = useCallback(async (member: IGroupUser) => {
    if (activeGroup === null) return;
    const response = await API.delete(`/muc/rooms/${getNodeFromJid(activeGroup.jid)}/users/${member.id}`);
    if (response.status !== 200) return;
    dispatch(removeGroupMember(member));
  }, [activeGroup, dispatch]);
  const makeAdmin = useCallback(async (member: IGroupUser) => {
    if (activeGroup === null) return;
    const response = await API.patch(`/muc/rooms/${getNodeFromJid(activeGroup.jid)}/users/${member.id}`, {
      affiliation: 'admin'
    });
    if (response.status !== 200) return;
    dispatch(updateGroupMember({
      groupId: getNodeFromJid(activeGroup.jid),
      memberId: member.id,
      affiliation: GroupAffiliations.ADMIN
    }));
  }, [activeGroup, dispatch]);
  const removeAdmin = useCallback(async (member: IGroupUser) => {
    if (activeGroup === null) return;
    const id = getNodeFromJid(activeGroup.jid);
    const response = await API.patch(`/muc/rooms/${id}/users/${member.id}`, {
      affiliation: 'member'
    });
    if (response.status !== 200) return;
    dispatch(updateGroupMember({
      groupId: getNodeFromJid(activeGroup.jid),
      memberId: member.id,
      affiliation: 'member'
    }));
  }, [activeGroup, dispatch]);
  const openChat = useCallback(async (member: IGroupUser) => {
    let contact = contacts.find(contact => Strophe.getNodeFromJid(contact.jid) === member.id);
    const card = await fetchVirtualCardForContact(member.id + '@' + HOST);
    if (contact === undefined) {
      contact = {
        jid: member.id,
        name: member.name,
        avatar: card.data.IMG,
        key: card.data.PK
      };
    }
    dispatch(setActiveGroup(null));
    dispatch(setGroupMenu(false));
    dispatch(setGroupAddMember(false));
    dispatch(setActiveContact(contact));
  }, [contacts, dispatch]);
  const actions = useCallback((member: IGroupUser): GroupUserActions => ({
    owner: [{
      label: 'Make admin',
      shown: member.affiliation === 'member' || member.affiliation === 'none',
      onClick: (member: IGroupUser) => {
        void makeAdmin(member);
      }
    }, {
      label: 'Remove admin',
      shown: member.affiliation === 'admin' && member.id !== me?.id,
      danger: true,
      onClick: (member: IGroupUser) => {
        void removeAdmin(member);
      }
    }, {
      label: 'Send message',
      shown: member.id !== me?.id,
      onClick: (member: IGroupUser) => {
        openChat(member).catch(console.error);
      }
    }, {
      label: 'Remove member',
      shown: member.id !== me?.id,
      danger: true,
      onClick: (member: IGroupUser) => {
        void removeMember(member);
      }
    }],
    admin: [{
      label: 'Make admin',
      shown: member.affiliation !== 'admin' && member.id !== me?.id,
      onClick: (member: IGroupUser) => {
        void makeAdmin(member);
      }
    }, {
      label: 'Remove admin',
      shown: member.affiliation === 'admin' && member.id !== me?.id,
      danger: true,
      onClick: (member: IGroupUser) => {
        void removeAdmin(member);
      }
    }, {
      label: 'Send message',
      shown: member.id !== me?.id,
      onClick: (member: IGroupUser) => {
        openChat(member).catch(console.error);
      }
    }, {
      label: 'Remove member',
      shown: member.id !== me?.id,
      danger: true,
      onClick: (member: IGroupUser) => {
        void removeMember(member);
      }
    }],
    member: [{
      label: 'Send message',
      shown: member.id !== me?.id,
      // TODO: Implement
      onClick: (member: IGroupUser) => {
        openChat(member).catch(console.error);
      }
    }],
    none: []
  }), [makeAdmin, me?.id, openChat, removeAdmin, removeMember]);
  const getContextItemsByMember = useCallback((member: IGroupUser): ContextMenuItem[] => {
    if (me === undefined) return [];
    const availableActions = actions(member);
    const actionsByAffiliation = availableActions[me.affiliation];
    const enabledActions = actionsByAffiliation.filter(action => action.shown);
    return enabledActions.map(action => ({
      label: action.label,
      danger: action.danger,
      onClick: () => {
        action.onClick(member);
      }
    }));
  }, [actions, me]);
  const renderAddParticipant = useMemo(() => me !== undefined && [GroupAffiliations.ADMIN, GroupAffiliations.OWNER].includes((me.affiliation as GroupAffiliations)) && <AddParticipant onClick={showAddMembers} />, [me, showAddMembers]);
  useEffect(() => {
    $subscribers([]);
    $subscribersLoaded(false);
  }, [activeGroup]);
  const renderGroupMembers = useMemo(() => activeGroup?.members?.filter(member => search === null || member.name?.toLowerCase().includes(search.toLowerCase())).map(member => {
    const contextItems = getContextItemsByMember(member);
    return <ContextMenu key={member.id} width={48} className="w-full flex flex-col" items={contextItems}>
                <GroupUser key={member.id} user={member} />
            </ContextMenu>;
  }), [activeGroup?.members, search, getContextItemsByMember]);
  const fetchChannelSubscribers = useCallback(async () => {
    if (activeGroup === null || subscribersLoaded) return;
    if (me?.affiliation !== GroupAffiliations.OWNER && me?.affiliation !== GroupAffiliations.ADMIN) return;
    const page = Math.ceil(subscribers.length / 10) + 1;
    const response = await API.get(`/muc/rooms/${getNodeFromJid(activeGroup.jid)}/users?page=${page}`);
    const {
      data: members,
      meta
    } = response.data;
    if (meta.hasNextPage === false) $subscribersLoaded(true);
    $subscribers(members);
  }, [activeGroup, me?.affiliation, subscribers.length, subscribersLoaded]);
  const renderChannelMembers = useMemo(() => <PaginatedList<IGroupUser> items={subscribers} onReachEnd={fetchChannelSubscribers} render={item => {
    const contextItems = getContextItemsByMember(item);
    return <ContextMenu key={item.id} width={48} className="w-full flex flex-col" items={contextItems}>
                        <GroupUser key={item.id} user={item} />
                    </ContextMenu>;
  }} />, [fetchChannelSubscribers, getContextItemsByMember, subscribers]);
  const renderSearch = useMemo(() => <div className="flex flex-col w-full h-full">
        <div className="flex items-center relative">
            <input value={search ?? ''} onChange={e => {
        $search(e.target.value);
      }} type="text" className="shadow px-4 py-2 pr-8 rounded-xl text-sm" placeholder="Search user" />
            <button className="center-flex absolute right-2 ml-2" onClick={() => {
        $search(null);
      }}>
                <i className="bi bi-x text-xl text-gray-400"></i>
            </button>
        </div>
    </div>, [search]);
  useKeyboardListener('Escape', () => {
    $showLeaveGroup(false);
  });
  useEffect(() => {
    closeGroupMenu();
  }, [activeGroup?.jid, closeGroupMenu]);
  const saveChanges = useCallback(async () => {
    try {
      if (activeGroup === null) return;
      const id = getNodeFromJid(activeGroup.jid);
      const payload = new FormData();
      if (editingFile !== undefined) payload.append('photo', editingFile);
      if (editingName !== activeGroup.name) payload.append('name', editingName);
      if (!payload.has('photo') && !payload.has('name')) {
        toast.success('No changes to save');
        $edit(false);
        return;
      }
      const response = await API.patch<Contact>(`/muc/rooms/${id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const newGroup = {
        ...activeGroup,
        name: response.data.name,
        photo: response.data.avatar,
        members: activeGroup.members
      };
      xmpp.connection.roster.update(activeGroup.jid, newGroup.name);
      toast.success('Changes saved');
      $edit(false);
    } catch (error) {
      console.error(error);
    } finally {
      $loading(false);
    }
  }, [activeGroup, editingFile, editingName]);
  const onChangeFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file !== undefined) {
      $editingFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        $editingFileBase64((reader.result as string));
      };
      reader.onerror = () => {
        $editingFileBase64('');
      };
    }
  }, []);
  const onClickEdit = useCallback(async () => {
    if (activeGroup === null) return;
    if (edit) {
      $loading(true);
      await saveChanges();
    } else {
      $editingName(activeGroup.name);
      $edit(true);
    }
  }, [activeGroup, edit, saveChanges]);
  if (activeGroup === null || !groupMenu || groupAddMember) return null;
  return <div className="flex flex-col w-1/3 h-screen bg-accent z-50 border-l border-gray-100">

            <div className='mx-4 h-16 shrink-0 flex items-center'>
                <span className='ml-5 font-bold text-xl'>Group info</span>

                <div className="flex items-center ml-auto">
                    {loading ? <LoadingSpinner spinnerClass="w-5 h-5" /> : <>
                            {(me?.affiliation === 'admin' || me?.affiliation === 'owner') && <button onClick={onClickEdit} className="clickable">
                                <i className={clsx('ml-4 bi', edit ? 'bi-check-lg text-xl text-hover-text' : 'bi-pencil')}></i>
                            </button>}
                            <button onClick={closeGroupMenu} className='clickable ml-4'>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.9959 1.00488L1.00586 12.9924" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M13 13L1 1" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </>}
                </div>
            </div>

            <div className="flex flex-col items-center mt-6">

                <div className="relative w-24 h-24 rounded-full">

                    {editingFileBase64 !== '' ? <img src={editingFileBase64} alt="avatar" className={clsx('w-24 h-24 shrink-0 object-cover rounded-xl', edit && 'opacity-30')} /> : <LoadingImage url={activeGroup.avatar} fallbackElement={<div className='shadow flex items-center justify-center bg-white p-2 w-24 h-24 rounded-xl shrink-0 object-cover' style={generateGradientStyleFromId(getNodeFromJid(activeGroup.jid))}>
                                <span className='text-2xl text-white uppercase'>{activeGroup.name?.charAt(0)}</span>
                            </div>} className='w-24 h-24 shrink-0 object-cover rounded-xl' spinnerSize='16' alt="avatar" />}

                    {edit && <button onClick={pickAvatar} className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <div className="bg-black w-10 h-10 rounded-full flex items-center justify-center opacity-80">
                            <i className="bi bi-upload text-white text-xl"></i>
                        </div>
                    </button>}
                    <input type="file" accept="image/*" className="hidden" ref={fileInput} onChange={onChangeFile} />
                </div>

                <div className="flex h-10 overflow-hidden items-center">
                    {edit ? <input type="text" className="text-lg w-full h-full bg-transparent text-center outline-none focus:border-b" autoFocus value={editingName} onChange={e => {
          $editingName(e.target.value);
        }} /> : <div className="w-full text-lg font-semibold">{activeGroup.name}</div>}
                </div>

                <span className='text-sm text-gray-500'>Group - {allMembers.length} participants</span>
            </div>

            <div className="w-full px-4 flex justify-between items-center my-6">
                <span className="text-hover-text">{allMembers.length} participants</span>

                <div className="flex items-center h-8">
                    {search === null ? <button onClick={() => {
          $search('');
        }} className="clickable">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.167 13.1665L16.1037 16.0956" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <circle cx="7.97376" cy="7.97327" r="6.65833" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button> : renderSearch}
                </div>
            </div>

            <div className="flex flex-col h-full overflow-y-scroll">
                {renderAddParticipant}
                {activeGroup.type === ContactTypes.CHANNEL ? <>
                        {renderGroupMembers}
                        {renderChannelMembers}
                    </> : renderGroupMembers}
            </div>

            {showLeaveGroup && <Modal>
                    <LeaveGroup onCancel={() => {
        $showLeaveGroup(false);
      }} onLeave={() => {
        $showLeaveGroup(false);
      }} />
                </Modal>}

            <button onClick={() => {
      $showLeaveGroup(true);
    }} className="w-full py-3 bg-primary text-accent mt-auto transition hover:opacity-80">
                {activeGroup.type === ContactTypes.CHANNEL ? 'Leave channel' : 'Exit group'}
            </button>
        </div>;
}
export default GroupMenu;
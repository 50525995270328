import type { PayloadAction } from '@reduxjs/toolkit'
import { UupEvents } from 'src/constants/events';
import { EventBus } from 'src/services/EventBus';
import { CallContactTypes, type CallData } from 'src/types/call';
import { type CallState, initialState } from '..';

const reducers = {
    initializeContactCall(state: CallState, action: PayloadAction<CallData>): CallState {
        const { format, channelId, caller, type, callId } = action.payload

        return {
            initialized: true,
            format,
            type,
            caller,
            channelId,
            callId,
            contactType: CallContactTypes.CONTACT,
            groupId: null,
        }
    },
    initializeGroupCall(state: CallState, action: PayloadAction<CallData>): CallState {
        const { format, channelId, caller, type, groupId, callId } = action.payload

        return {
            initialized: true,
            format,
            type,
            caller,
            channelId,
            callId,
            contactType: CallContactTypes.GROUP,
            groupId: groupId ?? null
        }
    },
    handleOngoingCallEvent(state: CallState, action: PayloadAction<CallData>): CallState {
        const { callId } = action.payload

        if (state.callId !== callId) {
            EventBus.next({ type: UupEvents.CALL_ENDED })
            return initialState
        }
        return state
    },
    resetCallState: (state: CallState) => {
        return initialState
    }
}

export default reducers

import StropheRSM from "./strophe-rsm";

const { Strophe, $iq } = window

class StropheMAM {
    connection: null | Strophe.Connection;

    _p: ['with', 'start', 'end']

    init(conn: any): void {
        this.connection = conn;
    }

    query(jid: any, options: any): any {
        const _p = this._p;
        const attr = {
            type: 'set',
            to: jid
        };
        options = options ?? {};
        const mamAttr = { xmlns: Strophe.NS.MAM, queryid: options.queryid };
        if (!('queryId' in options)) {
            mamAttr.queryid = options.queryid;
            delete options.queryid;
        }
        const iq = $iq(attr).c('query', mamAttr).c('x', { xmlns: 'jabber:x:data', type: 'submit' });

        iq.c('field', { var: 'FORM_TYPE', type: 'hidden' }).c('value').t(Strophe.NS.MAM).up().up();
        let i;
        for (i = 0; i < this._p.length; i++) {
            const pn = _p[i];
            const p = options[pn];

            delete options[pn];

            if (p !== undefined) {
                iq.c('field', { var: pn }).c('value').t(p).up().up();
            }
        }
        iq.up();

        const onMessage = options.onMessage;
        delete options.onMessage;
        const onComplete = options.onComplete;
        delete options.onComplete;
        iq.cnode(new StropheRSM(options).toXML());

        const { connection } = this;

        if (this.connection !== null) {
            const handler = this.connection.addHandler(onMessage, Strophe.NS.MAM, 'message');
            return this.connection.sendIQ(iq, function (this: any) {
                if (connection === null) return
                connection.deleteHandler(handler);
                onComplete.apply(this, arguments);
            });
        }

    }
}

export default StropheMAM

import { MessageType } from "src/types/Ejabberd/MessageType";

export const TYPING_RESET_TIMEOUT = 2000;
export const LOCAL_STORAGE_KEY = 'uup_data'
export const LOCAL_STORAGE_API_TOKEN = 'api_token'
export const LOCAL_STORAGE_SESSION_ID = 'session_id'
export const LOCAL_STORAGE_FCM_TOKEN = 'firebase_token'

export const attachmentTypes = [
    MessageType.VOICE,
    MessageType.AUDIO,
    MessageType.IMAGE,
    MessageType.VIDEO,
    MessageType.FILE
]

export enum RosterStateType {
    ONLINE = 'online',
    TYPING = 'typing',
    LAST_SEEN = 'last_seen',
}

export interface RosterState {
    online: boolean;
    typing: boolean;
    lastSeen: string | null;
}

export interface RosterStateChange {
    type: RosterStateType;
    value: string | boolean;
    from: string;
}

export interface TypingEventData {
    from: string;
    composing: {
        xmlns: string;
    };
    to: string;
    type: string;
}

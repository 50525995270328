import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { LOCAL_STORAGE_KEY } from 'src/constants/config';
import { handleCredentials } from 'src/helpers/auth';
import { useAppDispatch } from 'src/hooks/store';
import { setUser } from 'src/store/slices/user';
import { type SavedCredentials } from 'src/types/SignIn';
import { type User } from 'src/types/User';
function HomePage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const checkSavedCredentials = useCallback(() => {
    const savedCredentials = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedCredentials === null) {
      navigate('/sign-in');
      return;
    }
    const {
      s: sessionId,
      pk: publicKey,
      sk: privateKey,
      d: userData
    }: SavedCredentials = JSON.parse(savedCredentials);
    handleCredentials(userData, sessionId, publicKey, privateKey, (user: User) => {
      dispatch(setUser(user));
      navigate('/home');
    }, () => {
      navigate('/sign-in');
    });
  }, [dispatch, navigate]);
  useEffect(() => {
    checkSavedCredentials();
  }, [checkSavedCredentials]);
  return <div className='bg-accent w-full h-screen flex items-center justify-center overflow-hidden'>
            <LoadingSpinner />
        </div>;
}
export default HomePage;
import { useEffect } from 'react';
import { Subject, type Subscription } from 'rxjs';
import { type UupEvents } from 'src/constants/events';

export interface EventBusEvent {
    type: UupEvents;
    payload?: any;
}

export const EventBus = new Subject<EventBusEvent>()

export function useEventBus(event: UupEvents, callback: (payload: any) => void): void {
    useEffect(() => {
        const subscribe = EventBus.asObservable().subscribe(e => {
            if (e.type === event) {
                callback(e.payload)
            }
        })

        return () => {
            subscribe.unsubscribe()
        }
    }, [])
}


export const onEvent = (event: UupEvents, callback: (payload: any) => void): Subscription =>
    EventBus.asObservable().subscribe(e => {
        if (e.type === event) {
            callback(e.payload)
        }
    })

export enum MessageType {
    DEFAULT = "DEFAULT",
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    GIF = "GIF",
    VIDEO = "VIDEO",
    AUDIO = "AUDIO",
    VOICE = "VOICE",
    FILE = "FILE",
    CONTACT = "CONTACT",
    LOCATION = "LOCATION",
    LIVE_LOCATION = "LIVE_LOCATION",
    DELETED = "DELETED",
    GROUP_EVENT = "GROUP_EVENT",
    CALL_EVENT = "CALL",
    DATE_HEADER = "DATE_HEADER",
}

import { useMemo, useCallback, useEffect, useRef, useState, useLayoutEffect, type LegacyRef } from 'react';
import { getNodeFromJid } from 'src/helpers/contact';
import ReceivedMessage from 'src/components/Messages/ReceivedMessage';
import SentMessage from 'src/components/Messages/SentMessage';
import { useAppDispatch, useContacts, useUser } from 'src/hooks/store';
import { type DecryptedMessage } from 'src/types/Message';
import { xmpp } from 'src/constants/xmpp';
import ForwardContactMessage from './Forward';
import { type QueuedMessage } from 'src/types/XMPP';
import EndToEndEncryptionWarning from 'src/components/UI/EndToEndEncryptionWarning';
import PaginationLoader from 'src/components/UI/PaginationLoader';
import { addPaginationLimitedContact } from 'src/store/slices/contacts';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { UupEvents } from 'src/constants/events';
import { useEventBus } from 'src/services/EventBus';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import { formatDateHeader } from 'src/helpers/message';
import CallEventMessage from 'src/components/Messages/CallEventMessage';
interface Props {
  messages: DecryptedMessage[];
}
function ConversationMessages({
  messages
}: Props): JSX.Element {
  const {
    user
  } = useUser();
  const {
    activeContact,
    paginationLimitedContacts,
    deletedMessages,
    rosterState
  } = useContacts();
  const dispatch = useAppDispatch();
  const uid = activeContact !== null ? getNodeFromJid(activeContact.jid) : null;
  const containerRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const isPaginationLimited = uid !== null ? paginationLimitedContacts.includes(uid) : true;
  const [loading, $loading] = useState<boolean>(false);
  const contactDeletedMessages = useMemo(() => {
    return uid !== null ? deletedMessages[uid] ?? [] : [];
  }, [uid, deletedMessages]);
  const forceScrollBottom = useCallback(() => {
    const element = containerRef.current;
    if (element !== null) element.scrollTop = element.scrollHeight;
  }, []);

  // const scrollToBottom = useCallback(() => {
  //     const element = containerRef.current

  //     if (element !== null) {
  //         const bottomDistance = (element.scrollHeight - element.scrollTop) - element.clientHeight

  //         if (bottomDistance < 10) {
  //             console.log('scrolling', bottomDistance)
  //             element.scrollTop = element.scrollHeight
  //         }
  //     }
  // }, [])

  const onReplyMessageClicked = useCallback((messageId: string) => {
    const element = containerRef.current;
    const msgElement = document.getElementById(`msg-${messageId}`);
    if (element !== null && msgElement !== null) {
      element.scroll({
        top: msgElement.offsetTop,
        left: 0,
        behavior: "smooth"
      });
      msgElement.classList.add("bg-slate-300");
      setTimeout(() => {
        msgElement.classList.remove("bg-slate-300");
      }, 1000);
    }
  }, []);
  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      forceScrollBottom();
    });
  }, [activeContact, forceScrollBottom]);
  useEventBus(UupEvents.NEW_MESSAGE, () => requestAnimationFrame(() => {
    forceScrollBottom();
  }));
  const renderMessages = useMemo(() => {
    const filtered = messages.filter(message => !contactDeletedMessages.includes(message.id));
    return filtered.map((message: DecryptedMessage) => {
      if (user === null) return null;
      const isCallEvent = message.type === MessageType.CALL_EVENT;
      if (message.type === MessageType.DATE_HEADER) return <div onClick={() => {
        console.log(message);
      }} key={message.id} className="flex justify-center items-center my-2" id={`msg-${message.id}`}>
                    <div className='text-gray-500 text-xs font-semibold bg-white px-4 py-2 rounded-full'>
                        {formatDateHeader(message.timestamp)}
                    </div>
                </div>;else if (isCallEvent) return <CallEventMessage key={message.id} message={message} />;else if (message.received) return <ReceivedMessage key={message.id} message={message} onReplyMessageClicked={onReplyMessageClicked} />;else return <SentMessage key={message.id} message={message} onReplyMessageClicked={onReplyMessageClicked} />;
    });
  }, [contactDeletedMessages, messages, onReplyMessageClicked, user]);
  const handlePaginationMessages = useCallback(async (data: QueuedMessage[] | undefined) => {
    if (uid === null) return;
    if (data !== undefined) {
      data.forEach(message => {
        xmpp.messageHandler(true, message.jsonBody, message.msg);
      });
      const isLessThanLimit = data.length < 10;
      if (isLessThanLimit) dispatch(addPaginationLimitedContact(uid));
    } else dispatch(addPaginationLimitedContact(uid));
    requestAnimationFrame(() => {
      $loading(false);
    });
  }, [dispatch, uid]);
  const loadBefore = useCallback(() => {
    if (activeContact === null || isPaginationLimited) return;
    const firstMessage = messages[0];
    const firstMsgElement = document.getElementById(`msg-${firstMessage.id}`);
    if (firstMsgElement === null || containerRef.current === null) return;
    $loading(true);
    const curOffset = firstMsgElement.offsetTop - containerRef.current.scrollTop;
    const firstMamMessage = messages.find(message => message.mamId !== undefined);
    xmpp.getMessagesWithPagination(activeContact.jid, firstMamMessage, activeContact.isClearedAt, (data: QueuedMessage[] | undefined) => {
      handlePaginationMessages(data).then(() => {
        requestAnimationFrame(() => {
          if (containerRef.current === null) return;
          containerRef.current.scrollTop = firstMsgElement.offsetTop - curOffset;
        });
      }).catch(console.error);
    }, (error: any) => {
      console.log('ERR', error);
    });
  }, [activeContact, handlePaginationMessages, isPaginationLimited, messages]);
  const debouncedLoadBefore = useMemo(() => {
    return debounce(loadBefore, 150);
  }, [loadBefore]);

  // TODO: if read and there is no new message, don't mark as read
  const debouncedRead = useMemo(() => {
    return debounce(() => {
      if (activeContact === null || uid === null) return;
      xmpp.markChatAsRead(activeContact.jid);
    }, 150);
  }, [activeContact, rosterState, uid]);
  const onScrollMessages = useCallback((e: WheelEvent) => {
    if (activeContact === null) return;
    const element = e.target;
    if (element === null || !(element instanceof HTMLElement)) return;
    const isScrolledToTop = element.scrollTop === 0;
    const isScrolledToBottom = element.scrollTop + element.clientHeight + 10 >= element.scrollHeight;
    const isScrollable = element.scrollHeight > element.clientHeight;
    if (isScrolledToBottom) debouncedRead();
    if (!loading && isScrollable && isScrolledToTop) {
      debouncedLoadBefore();
    }
  }, [activeContact, debouncedLoadBefore, debouncedRead, loading]);
  useEffect(() => {
    const element = containerRef.current;
    if (element !== null) {
      element.addEventListener('wheel', onScrollMessages, {
        passive: true
      });
    }
    return () => {
      if (element !== null) {
        element.removeEventListener('wheel', onScrollMessages);
      }
    };
  }, [onScrollMessages]);
  return <div className='relative w-full h-full'>
            <ForwardContactMessage />

            <div ref={containerRef} className={clsx('bg-hover-bg w-full h-full px-4 overflow-y-scroll', loading && 'pointer-events-none')}>
                <EndToEndEncryptionWarning />
                <PaginationLoader loading={loading} />
                {renderMessages}
                <div className='h-44'></div>
            </div>
        </div>;
}
export default ConversationMessages;
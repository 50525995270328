function EndToEndEncryptionWarning(): JSX.Element {
  return <div className='mx-auto w-fit h-24 flex items-center overflow-hidden bg-hover-bg my-3'>
            <div className='bg-light-blue flex items-center rounded-xl px-6 py-6'>
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
                    <path d="M13.4235 7.44756V5.30056C13.4235 2.78756 11.3855 0.749556 8.87249 0.749556C6.35949 0.738556 4.31349 2.76656 4.30249 5.28056V5.30056V7.44756" stroke="#312E40" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6832 19.2495H5.04224C2.94824 19.2495 1.25024 17.5525 1.25024 15.4575V11.1685C1.25024 9.07346 2.94824 7.37646 5.04224 7.37646H12.6832C14.7772 7.37646 16.4752 9.07346 16.4752 11.1685V15.4575C16.4752 17.5525 14.7772 19.2495 12.6832 19.2495Z" stroke="#312E40" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.86304 12.2026V14.4236" stroke="#312E40" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <span className='text-xs ml-3'>Messages are end-to-end encrypted. No one outside of this chat, not even Uup Anonymous can read or listen to them. Click to learn more.</span>
            </div>
        </div>;
}
export default EndToEndEncryptionWarning;
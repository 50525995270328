import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import LoadingImage from "src/components/LoadingImage";
import { getNodeFromJid } from "src/helpers/contact";
import { useAppDispatch, useContacts } from "src/hooks/store";
import { setShowContactInfo } from "src/store/slices/contacts";
import { type RosterState } from "src/types/Roster";
import { defaultAvatar } from 'src/constants/contact';
import { type ContactStatusType } from "src/types/Contact";
import { initializeContactCall } from "src/store/slices/call";
import { type CallData, CallFormats, CallTypes } from "src/types/call";
function ChatNavigation(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const {
    activeContact,
    rosterState
  } = useContacts();
  const currentRosterState = useMemo<ContactStatusType>(() => {
    if (activeContact === null) return {
      type: 'OFFLINE',
      text: 'Offline'
    };
    const id = getNodeFromJid(activeContact.jid);
    const contactState: RosterState = rosterState[id];
    if (contactState === undefined) return {
      type: 'OFFLINE',
      text: 'Offline'
    };else if (contactState.typing) return {
      type: "TYPING",
      text: 'Typing...'
    };else if (contactState.online) return {
      type: 'ONLINE',
      text: 'Online'
    };else if (contactState.lastSeen !== null && contactState.lastSeen !== '0') {
      const lastSeenDiff = parseInt(contactState.lastSeen);
      const timestamp = moment().unix() - lastSeenDiff;
      let formattedLastSeen = moment.unix(timestamp).format('HH:mm');
      if (lastSeenDiff > 86400) formattedLastSeen = moment.unix(timestamp).format('DD/MM/YYYY HH:mm');
      return {
        type: 'LAST_SEEN',
        text: `Last seen at ${formattedLastSeen}`
      };
    } else return {
      type: 'OFFLINE',
      text: 'Offline'
    };
  }, [activeContact, rosterState]);
  const isOnline = currentRosterState.type === 'ONLINE' || currentRosterState.type === 'TYPING';
  const onClickContact = useCallback(() => {
    dispatch(setShowContactInfo(true));
  }, [dispatch]);
  useEffect(() => {
    console.log('ACTIVE CONTACT:', activeContact?.jid);
  }, [activeContact]);
  const callViaVoice = useCallback(async () => {
    if (activeContact === null) return;
    console.log('voice call');
    const callData: CallData = {
      format: CallFormats.VOICE,
      type: CallTypes.OUTGOING,
      caller: {
        id: getNodeFromJid(activeContact.jid),
        username: activeContact.name
      },
      channelId: null,
      callId: null
    };
    dispatch(initializeContactCall(callData));
  }, [activeContact, dispatch]);
  const callViaVideo = useCallback(async () => {
    if (activeContact === null) return;
    console.log('video call');
    const callData: CallData = {
      format: CallFormats.VIDEO,
      type: CallTypes.OUTGOING,
      caller: {
        id: getNodeFromJid(activeContact.jid),
        username: activeContact.name
      },
      channelId: null,
      callId: null
    };
    dispatch(initializeContactCall(callData));
  }, [activeContact, dispatch]);
  if (activeContact === null) return null;
  return <div className='px-6 py-4 h-20 bg-contact-navigation-bg flex justify-between items-center'>

            {activeContact !== null && <button onClick={onClickContact} className="flex items-center transition cursor-pointer hover:opacity-80">
                    <LoadingImage url={activeContact.avatar} fallback={defaultAvatar} alt="avatar" className='rounded-xl w-12 h-12 object-cover' />
                    <div className="flex flex-col items-start ml-3">
                        <span className="text-base text-dark">{activeContact.name}</span>
                        <div className='flex items-center'>
                            <span className={`w-2 h-2 rounded-full ${isOnline ? 'bg-green-600' : 'bg-status-offline'} mr-2`}></span>
                            <span className='text-contact-status text-2xs'>{currentRosterState.text}</span>
                        </div>
                    </div>
                </button>}

            <div className="flex items-center">
                <button onClick={callViaVoice} className='w-12 h-12 flex items-center justify-center rounded-lg mx-1 transition hover:bg-light-blue'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M13.353 1.5C17.054 1.911 19.978 4.831 20.393 8.532" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.353 5.04297C15.124 5.38697 16.508 6.77197 16.853 8.54297" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0315 11.4724C14.0205 15.4604 14.9254 10.8467 17.4653 13.3848C19.9138 15.8328 21.3222 16.3232 18.2188 19.4247C17.8302 19.737 15.3613 23.4943 6.68447 14.8197C-1.99341 6.144 1.76157 3.67244 2.07394 3.28395C5.18377 0.173846 5.66682 1.58938 8.11539 4.03733C10.6541 6.5765 6.04254 7.48441 10.0315 11.4724Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>

                <button onClick={callViaVideo} className='w-12 h-12 flex items-center justify-center rounded-lg mx-1 transition hover:bg-light-blue'>
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M15.3 5.97984L18.593 3.28484C19.409 2.61684 20.633 3.19884 20.632 4.25184L20.62 11.6008C20.619 12.6538 19.394 13.2308 18.58 12.5628L15.3 9.86784" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2969 11.5383C15.3778 13.3704 13.8992 14.9196 11.9946 14.9975C11.8543 15.0034 5.01531 14.9896 5.01531 14.9896C3.11996 15.1335 1.46115 13.7715 1.31165 11.9463C1.30039 11.8103 1.30346 4.47219 1.30346 4.47219C1.21949 2.63815 2.69604 1.08499 4.60163 1.00418C4.74396 0.997278 11.5738 1.01009 11.5738 1.01009C13.4783 0.868176 15.1423 2.24001 15.2897 4.07405C15.3 4.2061 15.2969 11.5383 15.2969 11.5383Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
        </div>;
}
export default ChatNavigation;
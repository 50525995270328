import { type EncryptedMessage } from "src/types/Ejabberd/Message";
import { type DecryptedMessage } from "src/types/Message";
import { type GroupAffiliation, type GroupUser } from "./Group";

export type ChatSettingType = "BLOCK" | "NOTIFICATION" | "TRANSLATION" | "READ_RECEIPT"


export enum ContactTypes {
    CONTACT = "CONTACT",
    CHANNEL = "CHANNEL",
    PRIVATE = "PRIVATE"
}

export type ContactType = `${ContactTypes}`

export interface Contact {
    name: string;
    jid: string;
    avatar: string | null;
    key: string;
    status?: string;
    lastMessage?: string;
    lastMessageTime?: string;
    unreadMessages?: number;
    isOnline?: boolean;
    isTyping?: boolean;
    isSelected?: boolean;
    isGroup?: boolean;
    isMuted?: boolean;
    isFavorite?: boolean;
    isArchived?: boolean;
    isBlocked?: boolean;
    isHidden?: boolean;
    isLeft?: boolean;
    isClearedAt?: number;
    isReadAt?: number;
    isReceivedAt?: number;

    members?: GroupUser[],
    myAffiliation?: GroupAffiliation;
    type?: ContactType;
}

export interface VirtualCard {
    userId: string;
    data: {
        NICK: string;
        IMG: string;
        PK: string;
    }
}

export interface ContactMessageData {
    contactId: string;
    message: EncryptedMessage;
    received: boolean;
}

export interface DecryptedContactMessageData {
    contactId: string;
    message: DecryptedMessage;
    received: boolean;
}

export interface ChatSetting {
    type: ChatSettingType,
    value: boolean
}

export interface ChatSettings {
    from: ChatSetting[],
    to: ChatSetting[]
}

export type ChatSettingNames = "BLOCK" | "NOTIFICATION" | "TRANSLATION" | "READ_RECEIPT"

export interface ParsedChatSettings {
    from: {
        BLOCK: boolean;
        TRANSLATION: boolean;
        NOTIFICATION: boolean;
        READ_RECEIPT: boolean;
    },
    to: {
        BLOCK: boolean;
        TRANSLATION: boolean;
        NOTIFICATION: boolean;
        READ_RECEIPT: boolean;
    }
}

export interface ContactStatusType {
    type: "ONLINE" | "OFFLINE" | "LAST_SEEN" | "TYPING";
    text: string;
}

import { useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect, type LegacyRef } from 'react';
import { addHiddenContact, getNodeFromJid } from 'src/helpers/contact';
import ReceivedMessage from 'src/components/Messages/ReceivedMessage';
import SentMessage from 'src/components/Messages/SentMessage';
import { useAppDispatch, useContacts, useUser } from 'src/hooks/store';
import { type DecryptedMessage } from 'src/types/Message';
import { HOST, xmpp } from 'src/constants/xmpp';
import { addPaginationLimitedContact, setGroupThread } from 'src/store/slices/contacts';
import EndToEndEncryptionWarning from '../../UI/EndToEndEncryptionWarning';
import PaginationLoader from '../../UI/PaginationLoader';
import { type QueuedMessage } from 'src/types/XMPP';
import { debounce } from 'lodash';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import GroupEventMessage from 'src/components/Messages/GroupEvent';
import { formatDateHeader } from 'src/helpers/message';
import clsx from 'clsx';
import { useEventBus } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
import CallEventMessage from 'src/components/Messages/CallEventMessage';
import ForwardGroupMessage from './Forward';
import { type GroupUser } from 'src/types/Group';
import GroupChatActions from './Actions';
function GroupMessages(): JSX.Element | null {
  const containerRef: LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const dispatch = useAppDispatch();
  const {
    user
  } = useUser();
  const {
    activeGroup,
    threadGroupId,
    threadMessageId,
    paginationLimitedContacts,
    deletedMessages,
    messages,
    contacts
  } = useContacts();
  const [loading, $loading] = useState<boolean>(false);
  const groupMessages = useMemo(() => activeGroup !== null ? messages[getNodeFromJid(activeGroup.jid)] ?? [] : [], [activeGroup, messages]);
  const uid = activeGroup !== null ? getNodeFromJid(activeGroup.jid) : null;
  const isPaginationLimited = uid !== null ? paginationLimitedContacts.includes(uid) : true;
  const contactDeletedMessages = useMemo(() => {
    return uid !== null ? deletedMessages[uid] ?? [] : [];
  }, [deletedMessages, uid]);
  const forceScrollBottom = useCallback(() => {
    const element = containerRef.current;
    if (element !== null) element.scrollTop = element.scrollHeight;
  }, []);
  const scrollToBottom = useCallback(() => {
    const element = containerRef.current;
    if (element !== null) {
      const bottomDistance = element.scrollHeight - element.scrollTop - element.clientHeight;

      // If the user is not at the bottom of the chat, don't scroll
      if (bottomDistance < 10) {
        console.log('scrolling', bottomDistance);
        element.scrollTop = element.scrollHeight;
      }
    }
  }, []);
  const onReplyMessageClicked = useCallback((messageId: string) => {
    const element = containerRef.current;
    const msgElement = document.getElementById(`msg-${messageId}`);
    if (element !== null && msgElement !== null) {
      element.scroll({
        top: msgElement.offsetTop,
        left: 0,
        behavior: "smooth"
      });
      msgElement.classList.add("bg-slate-300");
      setTimeout(() => {
        msgElement.classList.remove("bg-slate-300");
      }, 1000); // 1000
    }
  }, []);
  useEffect(() => {
    const timeout = setTimeout(scrollToBottom, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [messages.length, scrollToBottom]);
  useLayoutEffect(() => {
    forceScrollBottom();
  }, [activeGroup, forceScrollBottom]);
  useEventBus(UupEvents.NEW_GROUP_MESSAGE, () => requestAnimationFrame(() => {
    forceScrollBottom();
  }));
  const renderMessages = useCallback((customMessages?: DecryptedMessage[]) => {
    const filtered = groupMessages.filter(message => {
      return !contactDeletedMessages.includes(message.id) && (message.thid === null || message.thid === undefined);
    });
    const pendingContacts: string[] = [];
    return (customMessages ?? filtered).map((message: DecryptedMessage) => {
      if (activeGroup === null) return null;
      const fromId = getNodeFromJid(message.fromId);
      const isReceived = fromId !== user?.uid;
      const isCallEvent = message.type === MessageType.CALL_EVENT;
      const isGroupEvent = message.type === MessageType.GROUP_EVENT;
      let member: GroupUser | undefined;
      if (isReceived) {
        const contact = contacts.find(contact => getNodeFromJid(contact.jid) === fromId);
        member = activeGroup.members?.find(member => member.id === fromId);
        if (member === undefined && contact !== undefined) {
          member = {
            id: contact.jid,
            name: contact.name,
            photo: contact.avatar ?? "",
            affiliation: 'none'
          };
        }
        if (contact === undefined && !pendingContacts.includes(fromId)) {
          addHiddenContact(fromId + "@" + HOST, dispatch).catch(console.error);
          pendingContacts.push(fromId);
        }
      }
      if (message.type === MessageType.DATE_HEADER) return <div onClick={() => {
        console.log(message);
      }} key={message.id} className="flex justify-center items-center my-2" id={`msg-${message.id}`}>
                    <div className='text-gray-500 text-xs font-semibold bg-white px-4 py-2 rounded-full'>
                        {formatDateHeader(message.timestamp)}
                    </div>
                </div>;else if (isCallEvent) return <CallEventMessage key={message.id} message={message} />;else if (isGroupEvent) return <GroupEventMessage key={message.id} message={message} />;else if (isReceived) return <ReceivedMessage key={message.id} groupMember={member} message={message} onReplyMessageClicked={onReplyMessageClicked} />;else return <SentMessage key={message.id} message={message} onReplyMessageClicked={onReplyMessageClicked} />;
    });
  }, [groupMessages, contactDeletedMessages, activeGroup, user?.uid, onReplyMessageClicked, contacts, dispatch]);
  const handlePaginationMessages = useCallback(async (data: QueuedMessage[] | undefined) => {
    if (activeGroup === null) return;
    const uid = getNodeFromJid(activeGroup.jid);
    if (data !== undefined) {
      data.forEach(el => {
        xmpp.messageHandler(true, el.jsonBody, el.msg);
      });
      const isLessThanLimit = data.length < 10;
      console.log('limit', isLessThanLimit);
      if (isLessThanLimit) dispatch(addPaginationLimitedContact(uid));
    } else {
      dispatch(addPaginationLimitedContact(uid));
      console.log('limit');
    }
    requestAnimationFrame(() => {
      $loading(false);
    });
  }, [activeGroup, dispatch]);
  const loadBefore = useCallback(() => {
    if (activeGroup === null || isPaginationLimited) return;
    const firstMessage = groupMessages[0];
    const firstMsgElement = document.getElementById(`msg-${firstMessage.id}`);
    if (firstMsgElement === null || containerRef.current === null) return;
    $loading(true);
    const curOffset = firstMsgElement.offsetTop - containerRef.current.scrollTop;
    const firstMamMessage = groupMessages.find(message => message.mamId !== undefined);
    xmpp.getMessagesWithPagination(activeGroup.jid, firstMamMessage, activeGroup.isClearedAt, (data: QueuedMessage[] | undefined) => {
      handlePaginationMessages(data).then(() => {
        requestAnimationFrame(() => {
          if (containerRef.current === null) return;
          containerRef.current.scrollTop = firstMsgElement.offsetTop - curOffset;
        });
      }).catch(console.error);
    }, console.error);
  }, [activeGroup, isPaginationLimited, groupMessages, handlePaginationMessages]);

  // const onScrollMessages = useCallback((e: any) => {
  //     if (activeGroup === null) return

  //     const element: HTMLElement = e.target
  //     const isScrolledToTop = element.scrollTop === 0
  //     const isScrolledToBottom = (element.scrollTop + element.clientHeight + 10) >= element.scrollHeight

  //     if (isScrolledToBottom)
  //         xmpp.markChatAsRead(activeGroup.id)

  //     if (!loading && isScrolledToTop) {
  //         loadBefore()
  //     }
  // }, [activeGroup, loadBefore, loading])

  const debouncedLoadBefore = useMemo(() => {
    return debounce(loadBefore, 150);
  }, [loadBefore]);

  // // TODO: if read and there is no new message, don't mark as read
  // const debouncedRead = useMemo(() => {
  //     return debounce(() => {
  //         if (activeGroup === null) return

  //         xmpp.markChatAsRead(activeGroup.id)
  //     }, 150)
  // }, [activeGroup])

  const onScrollMessages = useCallback((e: WheelEvent) => {
    if (activeGroup === null) return;
    const element = e.target;
    if (element === null || !(element instanceof HTMLElement)) return;
    const isScrolledToTop = element.scrollTop === 0;
    const isScrolledToBottom = element.scrollTop + element.clientHeight + 10 >= element.scrollHeight;
    const isScrollable = element.scrollHeight > element.clientHeight;

    // if (isScrolledToBottom) debouncedRead()

    if (!loading && isScrollable && isScrolledToTop) {
      debouncedLoadBefore();
    }
  }, [activeGroup, debouncedLoadBefore, loading]);
  useEffect(() => {
    const element = containerRef.current;
    if (element !== null) {
      element.addEventListener('wheel', onScrollMessages, {
        passive: true
      });
    }
    return () => {
      if (element !== null) {
        element.removeEventListener('wheel', onScrollMessages);
      }
    };
  }, [onScrollMessages]);
  const closeThread = useCallback(() => {
    if (activeGroup === null) return;
    dispatch(setGroupThread({
      groupId: null,
      messageId: null
    }));
  }, [activeGroup, dispatch]);
  const renderThread = useMemo(() => {
    if (threadGroupId === null || threadMessageId === null || activeGroup === null) return null;
    if (activeGroup.jid !== threadGroupId) return null;
    if (activeGroup === null) return [];
    const threadMessages = groupMessages.filter(msg => msg.thid === threadMessageId);
    return <>
                <div className="absolute bg-thread-overlay backdrop-blur-[2px] z-60 top-0 left-0 w-full h-screen"></div>
                <div className={clsx('absolute bg-hover-bg z-70 top-20 left-1/2 -translate-x-1/2 w-2/3 h-2/3 rounded-xl overflow-hidden shadow', loading && 'pointer-events-none')}>
                    <div className='flex items-center justify-between bg-white p-4'>
                        <span>Thread</span>
                        <button onClick={closeThread}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.9959 1.005L1.00586 12.9925" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13 13.0002L1 1.00018" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className="w-full h-full px-4 overflow-y-scroll">
                        {renderMessages(threadMessages)}
                        <div className='h-44'></div>
                    </div>
                    <GroupChatActions />
                </div>
            </>;
  }, [activeGroup, closeThread, groupMessages, loading, renderMessages, threadGroupId, threadMessageId]);
  return <div className='relative w-full h-full'>
            <ForwardGroupMessage />

            <div ref={containerRef} className={clsx('bg-hover-bg w-full h-full px-4 overflow-y-scroll', loading && 'pointer-events-none')}>
                <EndToEndEncryptionWarning />
                <PaginationLoader loading={loading} />
                {renderThread}
                {renderMessages()}
                <div className='h-44'></div>
            </div>
        </div>;
}
export default GroupMessages;
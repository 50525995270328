import { type PayloadAction } from "@reduxjs/toolkit"
import { type DecryptedMessage, type ForwardingMessage } from "src/types/Message"
import { type ConversationState, initialState, type ReplyingMessage, type EditingMessage, cancelGroupEditing, cancelGroupReplying } from ".."
import { type Contact } from "src/types/Contact"

const reducers = {
    setReplying: (state: ConversationState, action: PayloadAction<ReplyingMessage<Contact>>) => {
        state.replying = action.payload
    },
    setEditing: (state: ConversationState, action: PayloadAction<EditingMessage<Contact>>) => {
        state.editing = action.payload
    },
    updateEditingMessage: (state: ConversationState, action: PayloadAction<DecryptedMessage>) => {
        if (state.editing !== null) {
            state.editing.message = action.payload
        }
    },
    setForwarding: (state: ConversationState, action: PayloadAction<ForwardingMessage<Contact, undefined> | null>) => {
        state.forwarding = action.payload
    },
    cancelReplying: (state: ConversationState) => {
        state.replying = null
    },
    cancelEditing: (state: ConversationState) => {
        state.editing = null
    },

    setGroupReplying: (state: ConversationState, action: PayloadAction<ReplyingMessage<Contact>>) => {
        state.groupReplying = action.payload
        cancelGroupEditing()

    },
    setGroupForwarding: (state: ConversationState, action: PayloadAction<ForwardingMessage<Contact, Contact> | null>) => {
        state.groupForwarding = action.payload
        cancelGroupEditing()
        cancelGroupReplying()
    },
    setGroupEditing: (state: ConversationState, action: PayloadAction<EditingMessage<Contact>>) => {
        state.groupEditing = action.payload
        cancelGroupReplying()
    },
    updateGroupEditingMessage: (state: ConversationState, action: PayloadAction<DecryptedMessage>) => {
        if (state.groupEditing !== null) {
            state.groupEditing.message = action.payload
        }
    },
    cancelGroupEditing: (state: ConversationState) => {
        state.groupEditing = null
    },
    cancelGroupReplying: (state: ConversationState) => {
        state.groupReplying = null
    },
    resetConversationState: (state: ConversationState) => {
        return initialState
    }
}

export default reducers

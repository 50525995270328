import * as messaging from "@firebase/messaging";
import { app } from 'src/services/Firebase';
import { LOCAL_STORAGE_API_TOKEN, LOCAL_STORAGE_FCM_TOKEN, LOCAL_STORAGE_SESSION_ID } from "src/constants/config";
import { vapidKey } from "src/constants/firebase";
import { type User } from "src/types/User";
import { updateUserToken } from "./auth";
import { type AppDispatch } from "src/store";
import { handleNotification } from "./notifications";
import { xmpp } from "src/constants/xmpp";
import { storePersistor } from "src/store";
import { API_URL } from "src/constants/api";
import axios from "axios";

const handleTokenRefresh = async (user: User, dispatch: AppDispatch): Promise<void> => {
    const firebaseMessaging = messaging.getMessaging(app)
    messaging.onMessage(firebaseMessaging, (payload: any) => { handleNotification(payload, dispatch); });
    const fcmToken = await messaging.getToken(firebaseMessaging, {
        vapidKey,
    })
    localStorage.setItem(LOCAL_STORAGE_FCM_TOKEN, fcmToken)
    await updateUserToken(user.apiToken, fcmToken)
}

export async function initializeMessaging(user: User | null, dispatch: AppDispatch, askForPermission?: boolean): Promise<void> {
    if(Notification.permission === 'granted' && user !== null) {
        await handleTokenRefresh(user, dispatch)
        return
    }

    if (user === null || askForPermission !== true) return

    Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
            await handleTokenRefresh(user, dispatch)
        }
        else {
            console.log('Notification permission denied.')
        }
    }).catch((err) => {
        console.error('Unable to get permission to notify.', err)
    })
}

export function clearAppData(): void {
    localStorage.clear()
    storePersistor.purge().catch(console.error)
    indexedDB.deleteDatabase('uup-db');
    indexedDB.deleteDatabase('uup-storage');
    indexedDB.deleteDatabase('uup-main');
    indexedDB.deleteDatabase('firebase-heartbeat-database');
    indexedDB.deleteDatabase('firebase-installations-database');
    indexedDB.deleteDatabase('firebase-messaging-database');
}

export async function logoutApp(
    callback?: () => void,
): Promise<void> {
    const token = localStorage.getItem(LOCAL_STORAGE_API_TOKEN) as string
    const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID) as string
    clearAppData()
    xmpp.logout()
    axios.post(API_URL + '/auth/sign-out', {}, {
        headers: {
            Authorization: `Bearer ${token}`,
            'SESSION-ID': sessionId
        }
    }).catch(console.error)
        .finally(() => {
            callback?.()            
            window.location.href = '/sign-in'
        })
}

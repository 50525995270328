import { useCallback, useState } from "react";
import ContactList from "src/components/Contact/List";
import { xmpp } from "src/constants/xmpp";
import { getNodeFromJid } from "src/helpers/contact";
import { insertGroupMessage, isGroup } from "src/helpers/group";
import { insertMessage, prepareEncryptedForwardingMessageContent, prepareEncryptedGroupForwardingMessageContent } from "src/helpers/message";
import { useAppDispatch, useContacts, useConversation, useUser } from "src/hooks/store";
import { setActiveContact, setActiveGroup } from "src/store/slices/contacts";
import { setGroupForwarding } from "src/store/slices/conversation";
import { type Contact } from "src/types/Contact";
function ForwardGroupMessage(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const {
    user
  } = useUser();
  const {
    activeGroup,
    contacts
  } = useContacts();
  const {
    groupForwarding
  } = useConversation();
  const [search, $search] = useState<string>('');
  const onPressForwardingContact = useCallback((contact: Contact) => {
    if (groupForwarding !== null) {
      const isExists = groupForwarding.contacts.find((item: Contact) => item.jid === contact.jid);
      let contacts = [...groupForwarding.contacts];
      if (isExists != null) contacts = contacts.filter((item: Contact) => item.jid !== contact.jid);else contacts.push(contact);
      dispatch(setGroupForwarding({
        ...groupForwarding,
        contacts
      }));
    }
  }, [dispatch, groupForwarding]);
  const forwardMessage = useCallback(() => {
    if (groupForwarding === null) return;
    groupForwarding.contacts.forEach((contact: Contact) => {
      if (isGroup(contact.jid)) {
        const group = contacts.find(item => item.jid === contact.jid);
        if (user === null || groupForwarding.message === undefined || group === undefined) return;
        const encryptedMessage = prepareEncryptedGroupForwardingMessageContent(groupForwarding.message, getNodeFromJid(contact.jid), user, group, {});
        if (encryptedMessage === false) return;
        xmpp.sendGroupMessage(contact.jid, encryptedMessage);
        insertGroupMessage(encryptedMessage, user, dispatch);
      } else {
        if (user === null || groupForwarding.message === undefined || activeGroup === null) return;
        const encryptedMessage = prepareEncryptedForwardingMessageContent(groupForwarding.message, getNodeFromJid(contact.jid), contact.key, null, null, user);
        if (encryptedMessage === false) return;
        xmpp.sendMessage(contact.jid, encryptedMessage);
        insertMessage(encryptedMessage, user, dispatch);
      }
    });
    if (groupForwarding.contacts.length === 1) {
      const contact = groupForwarding.contacts[0];
      if (isGroup(contact.jid)) {
        const group = contacts.find(item => item.jid === contact.jid);
        if (group === undefined) return;
        dispatch(setActiveGroup(group));
      } else dispatch(setActiveContact(contact));
    }
    dispatch(setGroupForwarding(null));
  }, [activeGroup, contacts, dispatch, groupForwarding, user]);
  const close = useCallback(() => {
    dispatch(setGroupForwarding(null));
  }, [dispatch]);
  if (groupForwarding === null) return null;
  return <div className="absolute w-1/2 top-64 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow bg-accent z-60">
            <div className="bg-primary text-accent flex items-center justify-between">
                <div className='p-4'>Forwarding</div>
                <button onClick={close} className="p-4 clickable">
                    <svg className="w-3 h-3" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9959 1.005L1.00586 12.9925" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13 13.0002L1 1.00018" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            <input value={search} onChange={({
      target
    }) => {
      $search(target.value);
    }} type="text" placeholder='Search' className='p-2 text-xs w-full border outline-none transition hover:opacity-80' />


            <div className='h-96 overflow-y-scroll'>
                <ContactList searchQuery={search} selectedContacts={groupForwarding.contacts} onPressContact={onPressForwardingContact} />
            </div>

            {groupForwarding.contacts.length > 0 && <div className='px-4 py-2 border-t flex justify-between items-center'>
                    {groupForwarding.contacts !== null && <span>{groupForwarding.contacts.length} selected</span>}
                    <button onClick={forwardMessage} className='w-10 h-10 flex justify-center items-center shadow border bg-primary z-50 rounded-full transition hover:bg-opacity-80'>
                        <i className="bi bi-send-fill text-accent"></i>
                    </button>
                </div>}
        </div>;
}
export default ForwardGroupMessage;
import { current, type PayloadAction } from "@reduxjs/toolkit"
import { getNodeFromJid, setContactsRecords, sortContactsByLastMessage, sortMessages } from "src/helpers/contact"
import { decryptGroupMessage, parseGroupEvent } from "src/helpers/group"
import { type GroupMessage, type GroupAffiliation, type GroupUser } from "src/types/Group"
import { type AddGroupMessageParams } from "."
import { type ContactsState } from ".."
import { type CallEvent, type GroupEvent } from "src/types/Ejabberd/Message"
import { MessageType } from "src/types/Ejabberd/MessageType"
import { fixTimestamp, parseCallEvent } from "src/helpers/message"
import moment from "moment"
import { type User } from "src/types/User"
import { EventBus } from "src/services/EventBus"
import { UupEvents } from "src/constants/events"
import { ContactTypes, type Contact } from "src/types/Contact"
import { type DecryptedMessage } from "src/types/Message"
import { xmpp } from "src/constants/xmpp"

const reducers = {
    setActiveGroup: (state: ContactsState, action: PayloadAction<Contact | null>) => {
        state.activeGroup = action.payload
        state.activeContact = null
        state.groupMenu = false
        state.galleryItems = []
        state.mainGalleryItem = null
    },
    setGroupMenu: (state: ContactsState, action: PayloadAction<boolean>) => {
        state.groupMenu = action.payload
    },
    setGroupAddMember: (state: ContactsState, action: PayloadAction<boolean>) => {
        state.groupAddMember = action.payload
    },
    updateGroupMember: (state: ContactsState, action: PayloadAction<{ groupId: string, memberId: string, affiliation: GroupAffiliation }>) => {
        const { groupId, memberId, affiliation } = action.payload;

        if (state.activeGroup?.members !== undefined && getNodeFromJid(state.activeGroup.jid) === groupId) {
            const memberIdx = state.activeGroup.members.findIndex(member => member.id === memberId)
            state.activeGroup.members[memberIdx].affiliation = affiliation
        }

        const contactIdx = state.contacts.findIndex(group => getNodeFromJid(group.jid) === groupId)
        if (contactIdx === -1) return

        const contact = state.contacts[contactIdx]

        const member = (contact.members as GroupUser[]).find(member => member.id === memberId)
        if (member === undefined) return
        member.affiliation = affiliation
    },
    addGroup: (state: ContactsState, action: PayloadAction<{
        group: Contact;
        user: User;
        addAsContact?: boolean
    }>) => {
        const { group, user, addAsContact } = action.payload;
        const id = getNodeFromJid(group.jid);

        const exists = state.contacts.find(item => item.jid === group.jid)
        if (exists !== undefined) return

        if (addAsContact === true) state.contacts.push(group)
        if (!Object.hasOwn(state.messages, id)) state.messages[id] = []

        const handlePendingMessage = (message: GroupMessage): void => {
            const { data, received } = message
            if (user === null) return

            const messages = state.messages[id]
            const isAlreadyReceived = messages.find(msg => msg.id === data.mid) !== undefined

            if (isAlreadyReceived) {
                console.log(`[${id}] Message is already received`)
                return
            }

            const msg = decryptGroupMessage(data, group.key, received)
            if (msg === false) return

            state.messages[id] = sortMessages(state, id, msg)
            EventBus.next({ type: UupEvents.NEW_GROUP_MESSAGE })
            state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
        }

        const setMessageStatus = (message: DecryptedMessage, index: number, messages: DecryptedMessage[]): DecryptedMessage => {
            if (!message.received) {
                const nextReceivedMessage = messages.find((message, _index) => {
                    if (_index > index) return message.received

                    return false
                })

                if (nextReceivedMessage !== undefined) {
                    message.isDelivered = true
                    message.isReceived = true
                    message.isRead = true;
                }
                else {
                    message.isDelivered = true
                }

                return message
            }

            return message
        }

        if (id in state.pendingGroupMessages) {
            state.pendingGroupMessages[id].forEach(handlePendingMessage)
            delete state.pendingGroupMessages[id]
        }

        state.messages[id] = state.messages[id].map(setMessageStatus)
    },
    setGroupThread: (state: ContactsState, action: PayloadAction<{
        groupId: string | null,
        messageId: string | null
    }>) => {
        const { groupId, messageId } = action.payload;

        state.threadGroupId = groupId
        state.threadMessageId = messageId
    },
    removeGroup: (state: ContactsState, action: PayloadAction<string>) => {
        const groupId = action.payload
        state.contacts = state.contacts.filter(group => getNodeFromJid(group.jid) !== groupId)
    },
    leaveGroup: (state: ContactsState, action: PayloadAction<string>) => {
        const groupId = action.payload
        const group = state.contacts.find(g => getNodeFromJid(g.jid) === getNodeFromJid(groupId))
        if (group === undefined) return
        if (group.type === ContactTypes.CHANNEL) {
            state.contacts = state.contacts.filter(group => getNodeFromJid(group.jid) !== getNodeFromJid(groupId))
        }
        group.isLeft = true
        if (state.activeGroup?.jid === groupId) {
            state.activeGroup.isLeft = true
            state.groupMenu = false
        }
    },
    addExistingGroup: (state: ContactsState, action: PayloadAction<string>) => {
        const groupId = action.payload

        const group = state.contacts.find(contact => contact.jid === groupId)
        if (group === undefined || group.isLeft !== true) return

        group.isLeft = false

        state.contacts = state.contacts.map(item => item.jid === groupId ? group : item)

        if (state.activeGroup?.jid === groupId)
            state.activeGroup.isLeft = false
    },
    addGroupMessage: (state: ContactsState, action: PayloadAction<AddGroupMessageParams>) => {
        const { message } = action.payload;
        const { groupId, data, received } = message
        const group = state.contacts.find(contact => getNodeFromJid(contact.jid) === groupId)

        if (group === undefined || group.key === undefined) {
            if (!(groupId in state.pendingGroupMessages)) {
                state.pendingGroupMessages[groupId] = []
            }

            state.pendingGroupMessages[groupId].push(message)
            return
        }

        const messages = state.messages[groupId] ?? []
        const isAlreadyReceived = messages.find(msg => msg.id === data.mid) !== undefined
        const contactNotExists = !(groupId in state.messages)

        if (isAlreadyReceived) {
            console.log(`[${groupId}] Message is already received`)
            return
        }

        if (contactNotExists) {
            state.messages[groupId] = []
            state.pendingRosters.push(groupId)
        }

        const msg = decryptGroupMessage(data, group.key, received)
        if (msg === false) return
        else if (state.activeGroup !== null && received) {

            const check = getNodeFromJid(state.activeGroup.jid) === groupId
            if (check) {
                xmpp.markChatAsRead(state.activeGroup.jid)
                setContactsRecords(groupId, msg.timestamp, 'readAt')
                msg.isRead = true
            }
        }

        state.messages[groupId] = sortMessages(state, groupId, msg)
        EventBus.next({ type: UupEvents.NEW_GROUP_MESSAGE })
        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
    },
    addGroupEvent: (state: ContactsState, action: PayloadAction<AddGroupMessageParams>) => {
        const { message, user } = action.payload;
        const { groupId, data, received } = message
        const group = state.contacts.find(contact => getNodeFromJid(contact.jid) === groupId)

        if (user === null || group === undefined) return

        const messages = state.messages[groupId] ?? []
        const isAlreadyReceived = messages.find(msg => msg.id === data.mid) !== undefined
        const contactNotExists = !(groupId in state.messages)

        if (isAlreadyReceived) {
            console.log(`[${groupId}] Message is already received`)
            return
        }

        if (contactNotExists) {
            state.messages[groupId] = []
            state.pendingRosters.push(groupId)
        }
        const msg = parseGroupEvent(data as GroupEvent, group, state.contacts, received, user)
        if (msg === false) return

        state.messages[groupId] = sortMessages(state, groupId, msg)
    },
    addGroupMember: (state: ContactsState, action: PayloadAction<GroupUser>) => {
        const member = action.payload;

        if (state.activeGroup === null) return

        state.activeGroup.members?.push(member)

        const findIndex = state.contacts.findIndex(contact => contact.jid === state.activeGroup?.jid)

        state.contacts[findIndex] = state.activeGroup
    },
    removeGroupMember(state: ContactsState, action: PayloadAction<GroupUser>) {
        const member = action.payload;
        if (state.activeGroup === null) return
        const { activeGroup } = state

        activeGroup.members = activeGroup.members?.filter(groupMember => groupMember.id !== member.id)

        const contact = state.contacts.find(contact => contact.jid === activeGroup.jid)
        if (contact === undefined) return

        contact.members = activeGroup.members
    },
    updateGroup(state: ContactsState, action: PayloadAction<Contact>) {
        const group = action.payload;
        const index = state.contacts.findIndex(contact => contact.jid === group.jid)
        const newMembers = (group.members !== undefined && group.members.length > 0) ? group.members : state.contacts[index]?.members
        const newGroup = { ...group, members: newMembers }

        state.contacts[index] = newGroup

        if (state.activeGroup !== null && state.activeGroup.jid === group.jid) {
            state.activeGroup = newGroup
        }
    },
    updateGroupMembers(state: ContactsState, action: PayloadAction<{
        groupId: string;
        members: GroupUser[];
    }>) {
        const { groupId, members } = action.payload;
        const { activeGroup } = state

        const group = state.contacts.find(contact => getNodeFromJid(contact.jid) === groupId)
        if (group === undefined) return
        group.members = members

        if (activeGroup !== null && activeGroup.jid === group.jid) activeGroup.members = members
    },
    updateMessageDeliveryStatusesForGroup(state: ContactsState, action: PayloadAction<string>) {
        const groupId = getNodeFromJid(action.payload)

        const groupMessages = state.messages[groupId]
        if (groupMessages === undefined) return
        let lastSentMessage : DecryptedMessage;
        for(let i = groupMessages.length-1; i >= 0; i--){
            if(!groupMessages[i].received){
                lastSentMessage  = groupMessages[i];
                break;
            }
        }

        state.messages[groupId] = groupMessages.map((message, index, messages) => {
            if (!message.received) {

                if (message.isUploaded !== undefined && !message.isUploaded) return message

                const nextReceivedMessage = messages.find((message, _index) => {
                    if (_index > index) return message.received

                    return false
                })

                if (nextReceivedMessage !== undefined) {
                    message.isDelivered = true
                    message.isReceived = true
                    message.isRead = true;
                }
                else {
                    message.isDelivered = true
                }

                return message
            } else {
                if(lastSentMessage === undefined) return message
                if(message.timestamp < lastSentMessage?.timestamp){
                    message.isRead = true
                }
            }

            return message
        })

    },
    fixGroupDateHeaders: (state: ContactsState, action: PayloadAction<{
        groupId: string;
    }>) => {
        const { groupId } = action.payload;
        const groupMessages = state.messages[groupId]

        if (groupMessages === undefined) return

        const newGroupMessages = groupMessages.map((message) => {
            if (message.type === MessageType.DATE_HEADER) return message
            const ts = fixTimestamp(message.timestamp) / 1000
            const id = moment.unix(ts).format('DD-MM-YYYY') + '-date-header'

            return [
                {
                    type: MessageType.DATE_HEADER,
                    timestamp: ts,
                    id,
                    fromId: message.fromId,
                    toId: message.toId,
                    received: false
                },
                message
            ]
        }).flat()
            .filter((message, index, messages) => {
                const previousMessage = messages[index - 1]
                if (previousMessage === undefined) return true
                if (message.type === MessageType.DATE_HEADER && previousMessage.type === MessageType.DATE_HEADER) return false

                return true
            })

        const uniqueMessages = newGroupMessages.filter((item, index) => newGroupMessages.findIndex(i => i.id === item.id) === index)

        state.messages[groupId] = uniqueMessages
    },
    addGroupCallEvent: (state: ContactsState, action: PayloadAction<{
        event: CallEvent
    }>) => {
        const { event } = action.payload;

        const groupId = event.tid

        const messages = state.messages[groupId] ?? []
        const isAlreadyReceived = messages.find(msg => msg.id === event.mid) !== undefined
        const contactNotExists = !(groupId in state.messages)

        if (isAlreadyReceived) {
            console.log(`[${groupId}] Message is already received`, event.t, event.mid)
            return
        }

        if (contactNotExists) {
            state.messages[groupId] = []
            if (groupId !== process.env.REACT_APP_SERVER_UID as string) state.pendingRosters.push(groupId)
        }

        const msg = parseCallEvent(event, true)
        if (msg === false) return

        EventBus.next({ type: UupEvents.NEW_GROUP_MESSAGE })
        state.messages[groupId] = sortMessages(state, groupId, msg)
        state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
    },
}

export default reducers

import { useCallback, useEffect } from 'react';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { xmpp } from 'src/constants/xmpp';
import { fetchLastSeen, getNodeFromJid, requestLastSeenOfContact } from 'src/helpers/contact';
import { useAppDispatch, useAppSelector, useContacts } from 'src/hooks/store';
import { setRosterState } from 'src/store/slices/contacts';
import { RosterStateType } from 'src/types/Roster';
import ConversationMessages from './Messages';
import ContactChatActions from './Actions';
import BlockedContactChatActions from './BlockedContactActions';
import ChatNavigation from './Navigation';
import Gallery from './Gallery';
import { useEventBus } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
function Chat(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    activeContact,
    activeChatSettings
  } = useContacts();
  const {
    messages
  } = useAppSelector(state => state.contacts);
  const contactId = activeContact !== null ? getNodeFromJid(activeContact.jid) : null;
  const contactMessages = contactId !== null ? messages[contactId] ?? [] : [];
  const isBlocked = activeChatSettings?.from.BLOCK === true;
  useEventBus(UupEvents.FETCH_LAST_SEEN, useCallback((jid: string) => {
    fetchLastSeen(jid, dispatch);
  }, [dispatch]));
  useEffect(() => {
    if (xmpp.connection !== undefined && activeContact !== null) fetchLastSeen(activeContact.jid, dispatch);
  }, [activeContact, dispatch]);
  if (activeContact === null) return <LoadingSpinner />;
  return <div className='relative w-full h-screen'>
            <ChatNavigation />
            <Gallery />

            <div className="flex flex-col w-full h-full">
                <ConversationMessages messages={contactMessages} />
                {isBlocked ? <BlockedContactChatActions /> : <ContactChatActions />}
            </div>
        </div>;
}
export default Chat;
import { createSlice } from '@reduxjs/toolkit'
import { type CallContactType, type CallFormat, type CallType } from 'src/types/call';

import reducers from './reducers';

export interface CallState {
    initialized: boolean;
    format: CallFormat | null;
    type: CallType | null;
    caller: {
        id: string;
        username: string;
    } | null,
    groupId: string | null;
    channelId: string | null;
    contactType: CallContactType | null;
    callId: string | null;
}

export const initialState: CallState = {
    initialized: false,
    format: null,
    type: null,
    caller: null,
    groupId: null,
    channelId: null,
    contactType: null,
    callId: null,
}

export const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    initializeContactCall,
    initializeGroupCall,
    resetCallState,
    handleOngoingCallEvent
} = callSlice.actions

export default callSlice.reducer

import { useEffect, useMemo } from 'react';
import { type Contact } from 'src/types/Contact';
import ContactItem from 'src/components/Contact';
import { useContacts } from 'src/hooks/store';
import Group from '../Group';
import { isGroup } from 'src/helpers/group';
import { getNodeFromJid } from 'src/helpers/contact';
interface Props {
  searchQuery?: string;
  selectedContacts: Contact[];
  onPressContact: (contact: Contact) => void;
  excludeGroups?: boolean;
  excludeIds?: string[];
}
function ContactList({
  searchQuery,
  selectedContacts,
  onPressContact,
  excludeGroups = false,
  excludeIds = []
}: Props): JSX.Element {
  const {
    contacts
  } = useContacts();
  useEffect(() => {
    console.log('re-rendering contact list', contacts.length);
  }, [contacts]);
  const renderContacts = useMemo(() => contacts.filter((contact: Contact) => {
    if (excludeGroups && isGroup(contact.jid)) return false;else if (contact.isHidden === true) return false;else if (excludeIds.includes(getNodeFromJid(contact.jid))) return false;else if (searchQuery === undefined) return true;else if (searchQuery.length > 0) return contact.name?.toLowerCase().includes(searchQuery.toLowerCase());else return true;
  }).map((contact: Contact) => {
    const userId = getNodeFromJid(contact.jid);
    const isSelected = selectedContacts.findIndex(selectedContact => getNodeFromJid(selectedContact.jid) === userId) !== -1;
    if (isGroup(contact.jid)) return <Group key={userId} isSelected={isSelected} onPress={() => {
      onPressContact(contact);
    }} contact={contact} />;
    return <ContactItem key={userId} isSelected={isSelected} onPress={() => {
      onPressContact(contact);
    }} contact={contact} />;
  }), [contacts, excludeGroups, excludeIds, onPressContact, searchQuery, selectedContacts]);
  return <div className="w-full flex flex-col h-auto">
            {renderContacts}
        </div>;
}
export default ContactList;
import { UupEvents } from "src/constants/events"
import { EventBus } from "src/services/EventBus"
import { type AppDispatch } from "src/store"
import { initializeContactCall, initializeGroupCall, resetCallState } from "src/store/slices/call"
import { type CallFormat, CallTypes } from "src/types/call"
import { CallEventTypes } from "src/types/Ejabberd/Message"

export function handleNotification(payload: any, dispatch: AppDispatch): void {
    console.log('Notification', payload)

    const { data } = payload

    if (data.nt === 'CALL') {
        const callerData = JSON.parse(data.caller)
        const type = data.callType as CallFormat

        if (data.group === undefined || data.group === null) {
            dispatch(initializeContactCall({
                format: type,
                type: CallTypes.INCOMING,
                caller: callerData,
                channelId: data.channel,
                callId: data.callId
            }))
        }
        else {
            const groupData = JSON.parse(data.group)

            dispatch(initializeGroupCall({
                format: type,
                type: CallTypes.INCOMING,
                caller: callerData,
                channelId: data.channel,
                callId: data.callId,
                groupId: groupData.id
            }))
        }
    }
    // if we receive ongoing event, we need to disable the calling modal.
    if (data.t === 'CALL') {
        if ([CallEventTypes.ENDED, CallEventTypes.REJECTED].includes(data.c)) {
            dispatch(resetCallState())

            EventBus.next({ type: UupEvents.CALL_ENDED })
        }
    }

}

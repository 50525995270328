import type { PayloadAction } from '@reduxjs/toolkit'
import { type Contact } from 'src/types/Contact'
import { type CreateGroupState, type CreateGroupStep, initialState } from '..'

const reducers = {
    setCreateGroupActive: (state: CreateGroupState, action: PayloadAction<boolean>) => {
        state.active = action.payload
    },
    setCreateGroupStep: (state: CreateGroupState, action: PayloadAction<CreateGroupStep>) => {
        state.step = action.payload
    },
    setCreateGroupName: (state: CreateGroupState, action: PayloadAction<string>) => {
        state.name = action.payload
    },
    setCreateGroupContacts: (state: CreateGroupState, action: PayloadAction<Contact[]>) => {
        state.contacts = action.payload
    },
    setCreateGroupAvatar: (state: CreateGroupState, action: PayloadAction<File | null>) => {
        state.avatar = action.payload
    },
    setCreateGroupAvatarSrc: (state: CreateGroupState, action: PayloadAction<string | null>) => {
        state.avatarSrc = action.payload
    },
    resetCreateGroup: (state: CreateGroupState) => {
        return initialState
    }
}

export default reducers

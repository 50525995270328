import { TYPING_RESET_TIMEOUT } from "src/constants/config";
import { xmpp } from "src/constants/xmpp";
import { type AppDispatch } from "src/store";
import { addDeletedMessage, setRosterState, updateMessageDeliveryData } from "src/store/slices/contacts";
import { type Contact } from "src/types/Contact";
import { type GroupEvent, type EncryptedGroupMessage, type EncryptedMessage, type CallEvent } from "src/types/Ejabberd/Message";
import { type DeleteMessageEventData, type MessageDeliveryEventData } from "src/types/Message";
import { RosterStateType, type TypingEventData } from "src/types/Roster";
import { type User } from "src/types/User";
import { hash } from "./common";
import { getBareJid, getNodeFromJid, initializeContacts } from "./contact";
import { xmppGroupEventHandler, xmppGroupMessageHandler } from "./group";
import { xmppCallEventHandler, xmppMessageHandler } from "./message";

export const xmlToJson = (xml: Element): any => {
    let obj: any = {};
    if (xml.nodeType === 1) {
        if (xml.attributes.length > 0) {
            for (let j = 0; j < xml.attributes.length; j++) {
                const attribute = xml.attributes.item(j);
                if (attribute !== null)
                    obj[attribute.nodeName] = attribute.nodeValue;
            }
        }
    } else if (xml.nodeType === 3) {
        obj = xml.nodeValue;
    }
    if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
            const item = xml.childNodes.item(i);
            const nodeName = item.nodeName;
            if (typeof (obj[nodeName]) === 'undefined') {
                obj[nodeName] = xmlToJson(item as Element);
            } else {
                if (typeof (obj[nodeName].push) === 'undefined') {
                    const old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }
                obj[nodeName].push(xmlToJson(item as Element));
            }
        }
    }
    return obj;
}

export function xmppTypingHandler(json: TypingEventData, dispatch: AppDispatch): void {
    const { from, composing } = json
    const contactJid = getBareJid(from)

    if (composing !== undefined) {
        dispatch(setRosterState({
            from: contactJid,
            type: RosterStateType.TYPING,
            value: composing !== undefined
        }))

        setTimeout(() => {
            dispatch(setRosterState({
                from: contactJid,
                type: RosterStateType.TYPING,
                value: false
            }))
        }, TYPING_RESET_TIMEOUT);
    }
}

export function xmppInitialize(user: User | null, contacts: Contact[], dispatch: AppDispatch): void {
    if (!xmpp.connection.connected || user === null) return

    xmpp.contacts.subscribe((newContacts: Contact[] | null) => {
        if (newContacts === null) return
        if (xmpp.connection.connected) initializeContacts(newContacts, contacts, user, dispatch).catch(console.error)
    })

    xmpp.rosterEvents.subscribe(event => dispatch(setRosterState({
        from: event.from,
        type: RosterStateType.ONLINE,
        value: event.value
    })))

    xmpp.messageHandler = (isArchived: boolean, json: EncryptedMessage, xml: Element) => { xmppMessageHandler(isArchived, json, xml, user, dispatch); }
    xmpp.groupMessageHandler = (json: EncryptedGroupMessage, xml: Element) => { xmppGroupMessageHandler(json, xml, user, dispatch); }
    xmpp.groupEventHandler = (isArchived: boolean, json: GroupEvent, xml: Element) => { xmppGroupEventHandler(isArchived, json, xml, user, dispatch); }
    xmpp.callEventHandler = (json: CallEvent, xml: Element) => { xmppCallEventHandler(json, xml, user, dispatch); }
    xmpp.deleteMessageHandler = (json: DeleteMessageEventData) => dispatch(addDeletedMessage({ ...json, hide: true }))
    xmpp.typingHandler = (json: TypingEventData) => { xmppTypingHandler(json, dispatch); }
    xmpp.messageDeliveryHandler = (data: MessageDeliveryEventData) => dispatch(updateMessageDeliveryData(data))
}

export async function generatePubSubNodeNameForContact(from: string, to: string, isGroup: boolean = false, prefix: string = '', suffix: string = ''): Promise<string> {
    from = getNodeFromJid(from)
    to = getNodeFromJid(to)
    const channelId = from < to ? from + "_" + to : to + "_" + from

    if (isGroup) return to
    else {
        const hashed = await hash(channelId, "SHA-256")
        return prefix + hashed + suffix
    }
}

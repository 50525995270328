import clsx from 'clsx';
import { useState, useRef } from 'react';
import { computeElapsedTime } from 'src/helpers/audio';
import { calculateFileSize } from 'src/helpers/file';
import { RTCAudioRecorderService } from 'src/services/RTCAudioRecorder';
import { Player } from '@lottiefiles/react-lottie-player';
interface Props {
  onRecordStatusChange?: (status: boolean) => void;
  onPressSend: (blob: RecordingData) => void;
}
export interface RecordingData {
  blob: Blob | null;
  src: string | null;
  type: string | null;
  duration: number | null;
  size: string | null;
}
function AudioRecorder({
  onRecordStatusChange,
  onPressSend
}: Props): JSX.Element {
  const audioRecorderService = useRef(new RTCAudioRecorderService());
  const elapsedTimeIntervalRef = useRef<any>(null);
  const playerRef = useRef<HTMLAudioElement>(null);
  const [recording, $recording] = useState<boolean>(false);
  const [startTime, $startTime] = useState<Date | null>(null);
  const [elapsedTime, $elapsedTime] = useState<string | null>(null);
  const startAudioRecording = (): void => {
    console.log("Recording Audio...");
    onRecordStatusChange?.(true);
    const recorderAudioIsPlaying = playerRef.current != null && !playerRef.current.paused;
    if (recorderAudioIsPlaying) {
      playerRef.current.pause();
    }
    audioRecorderService.current.start().then(() => {
      // on success

      $recording(true);

      // store the recording start time to display the elapsed time according to it
      const _startTime = new Date();
      $startTime(_startTime);
      handleElapsedRecordingTime(_startTime);

      // display control buttons to offer the functionality of stop and cancel
    }).catch(error => {
      // on error
      onRecordStatusChange?.(false);
      $recording(false);
      alert(error.message);
    });
  };
  const finishRecording = (blob: Blob): void => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target === null) return;
      const base64URL = (e.target.result as string);
      const BlobType = blob.type.includes(";") ? blob.type.substr(0, blob.type.indexOf(';')) : blob.type;
      if (startTime === null) return;
      const finishDate = new Date();
      const duration: any = (finishDate.getTime() - startTime.getTime()) / 1000;
      const data: RecordingData = {
        blob,
        src: base64URL,
        duration,
        size: calculateFileSize(blob),
        type: BlobType
      };
      onPressSend(data);
    };
    reader.readAsDataURL(blob);
  };
  const sendRecording = (): void => {
    audioRecorderService.current.stop().then(blob => {
      $recording(false);
      finishRecording(blob);
      clearInterval(elapsedTimeIntervalRef.current);
    }).catch(error => {
      $recording(false);
      console.error(error.message);
    });
  };
  const cancelAudioRecording = async (): Promise<void> => {
    if (audioRecorderService.current === null) return;
    onRecordStatusChange?.(false);
    console.log("Canceling audio...");
    await audioRecorderService.current.cancel();
    $recording(false);
    clearInterval(elapsedTimeIntervalRef.current);
  };
  function handleElapsedRecordingTime(_startTime: Date): void {
    $elapsedTime("00:00");
    elapsedTimeIntervalRef.current = setInterval(() => {
      const elapsedTime = computeElapsedTime(_startTime); // pass the actual record start time
      $elapsedTime(elapsedTime);
    }, 1000);
  }
  return <div className={clsx('flex items-center ml-4', recording && 'justify-center w-full')}>

            {recording && <div className='flex w-full justify-between items-center'>
                    <div className="flex items-center">
                        <Player src={require('../../animations/recording.json')} background="transparent" speed={0.3} className='w-14 h-14' loop controls autoplay />
                        <span className='text-primary w-20 text-center text-lg ml-4'>{elapsedTime}</span>
                    </div>
                    <Player src={require('../../animations/audio-waves.json')} background="transparent" speed={0.6} className='w-full h-14 grayscale' loop controls autoplay />
                    <div className="flex items-center ml-auto">
                        <button onClick={cancelAudioRecording} className='center-flex bg-status-offline w-8 h-8 rounded clickable'>
                            <i className="bi bi-x-lg text-white"></i>
                        </button>
                        <button onClick={sendRecording} className="clickable ml-4">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="16" fill="#121212" />
                                <path d="M23.2335 15.3488L9.45293 8.08602C8.96488 7.82774 8.37646 8.18226 8.37646 8.73415C8.37646 10.3481 9.49244 14.1339 9.49244 14.1339C9.49244 14.1339 9.81158 14.9176 10.2345 14.9665L16.2707 15.663C16.6676 15.7087 16.6676 16.2852 16.2707 16.331L10.2345 17.0275C9.81158 17.0763 9.49244 17.8601 9.49244 17.8601C9.49244 17.8601 8.37646 21.7272 8.37646 23.3818C8.37646 23.7695 8.69023 24.0395 9.07537 23.9953C9.46139 23.9509 9.0801 24.1381 23.2335 16.6451C23.7529 16.37 23.7532 15.6239 23.2335 15.3488Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>}

            {!recording && <button onClick={startAudioRecording}>
                    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.99945 20.9998V17.8389" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.99958 13.8481V13.8481C6.75637 13.8481 4.9375 12.0218 4.9375 9.76819V5.08095C4.9375 2.82732 6.75637 1 8.99958 1C11.2438 1 13.0617 2.82732 13.0617 5.08095V9.76819C13.0617 12.0218 11.2438 13.8481 8.99958 13.8481Z" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17 9.80078C17 14.2396 13.4188 17.8385 8.99948 17.8385C4.58117 17.8385 1 14.2396 1 9.80078" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>}
        </div>;
}
export default AudioRecorder;
interface Props {
  callback: () => void;
  onClose: () => void;
}
function ClearChatModal({
  callback,
  onClose
}: Props): JSX.Element {
  return <div className="absolute top-0 left-0 w-full h-screen z-80 bg-modal-overlay">

            <div className="absolute z-80 shadow rounded-xl overflow-hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96">

                <div className="relative bg-accent px-5 py-4">
                    <span className="text-lg font-semibold">Are you sure?</span>
                </div>

                <div className="px-10 py-8 bg-white flex items-center justify-end">
                    <span onClick={onClose} className="font-semibold clickable">Cancel</span>
                    <button onClick={callback} className="clickable bg-red-500 text-white px-4 py-1.5 rounded ml-4">Clear chat</button>
                </div>

            </div>

        </div>;
}
export default ClearChatModal;
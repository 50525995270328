import { type GroupEvent, type EncryptedGroupMessage } from "src/types/Ejabberd/Message";
import { type ContactType } from "./Contact";

export enum GroupAffiliations {
    OWNER = "owner",
    ADMIN = "admin",
    MEMBER = "member",
    NONE = "none"
}

export type GroupAffiliation = `${GroupAffiliations}`

export interface GroupMessage {
    groupId: string;
    data: EncryptedGroupMessage | GroupEvent;
    received: boolean;
}

export interface GroupDetailsResponse {
    id: string;
    type: ContactType;
    name: string;
    photo: string;
    secret: string;
}

export interface GroupMetaResponse {
    subscriberCount: number;
    affiliation: ContactType;
}

export interface CreateGroupResponse {
    id: string;
    name: string;
    type: ContactType;
    photo: string;
    secret: string;
    users: GroupUser[]
}

export interface GroupUser {
    id: string;
    name: string;
    photo: string;
    affiliation: GroupAffiliation;
}

export interface GroupInvitationJson {
    groupXmppId: string;
    host: string;
}

export interface GroupUserAction {
    label: string,
    shown: boolean,
    danger?: boolean;
    onClick: (member: GroupUser) => void
}

export type GroupUserActions = {
    [key in GroupAffiliation]: GroupUserAction[];
}

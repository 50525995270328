// import clsx from "clsx";
// import moment from "moment";
// import MessageDeliveryTicks from "src/components/UI/MessageDeliveryTicks";
// import { parseTimestamp } from "src/helpers/message";
import { type DecryptedMessage } from "src/types/Message";
interface Props {
  message: DecryptedMessage;
}
function GroupEventMessage({
  message
}: Props): JSX.Element {
  const {
    content
  } = message;
  // const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm')

  return <div className='mx-auto bg-[#7B7BAC] flex flex-col my-2 w-fit rounded-xl text-xs px-4 py-2' onClick={() => {
    console.log(message);
  }}>
            <span className='text-white'>{content}</span>
            {/* <div className="flex items-center mt-1 self-end text-2xs">
                <span className='text-gray-400'>{formattedTimestamp}</span>
                <MessageDeliveryTicks message={message} />
             </div> */}
        </div>;
}
export default GroupEventMessage;
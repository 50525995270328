import { useCallback, useState } from "react";
import { getNodeFromJid } from "src/helpers/contact";
import { useContacts } from "src/hooks/store";
import { type Contact } from "src/types/Contact";
import SortedContactList from "../SortedContactList";
import AddUserModal from "./AddUserModal";
interface Props {
  results: Contact[];
  onAdd: (user: Contact, username: string) => void;
  isLoading: boolean;
  addContactInProgress: boolean;
}
function SearchResults({
  results,
  onAdd,
  isLoading,
  addContactInProgress
}: Props): JSX.Element | null {
  const [user, $user] = useState<Contact | null>(null);
  const {
    contacts
  } = useContacts();
  const filteredContacts = results.filter(item => {
    const isIncluded = contacts.find(contact => getNodeFromJid(contact.jid) === getNodeFromJid(item.jid));
    return isIncluded === undefined || isIncluded.isHidden === true;
  });
  const onPressListUser = useCallback(async (user: Contact) => {
    $user(user);
  }, []);
  if (isLoading) return null;
  return <div className="flex flex-col h-auto mt-4">
            {user !== null && <AddUserModal user={user} onAdd={onAdd} onClose={() => {
      $user(null);
    }} addContactInProgress={addContactInProgress} />}

            <SortedContactList sortingDisabled data={filteredContacts} onClick={onPressListUser} />
        </div>;
}
export default SearchResults;
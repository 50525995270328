import { store, storePersistor } from 'src/store';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFoundRoutePage from 'src/pages/404';
import MainPage from 'src/pages/Main';
import SignIn from './pages/SignIn';
import HomePage from './pages/Home';
import { PersistGate } from 'redux-persist/integration/react';
import { EmojiContext, emojiData } from './contexts/Emoji';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
const router = createBrowserRouter([{
  index: true,
  element: <HomePage />,
  errorElement: <NotFoundRoutePage />
}, {
  path: 'sign-in',
  element: <SignIn />
}, {
  path: "/home",
  element: <MainPage />
}]);
function App(): JSX.Element {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/firebase-messaging-sw.js?v=1", {
        scope: "/"
      }).then(registration => {
        console.log("Service worker updated");
        registration.update().catch(console.error);
      }).catch(error => {
        console.error('Service worker update:', error);
      });
    }
  }, []);
  return <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={storePersistor}>
                <EmojiContext.Provider value={emojiData}>
                    <Toaster position="top-right" reverseOrder={false} />
                    <RouterProvider router={router} />
                </EmojiContext.Provider>
            </PersistGate>
        </ReduxProvider>;
}
export default App;
import moment from "moment";
import { parseTimestamp } from "src/helpers/message";
import { type DecryptedMessage, type LocationShareAttachment } from "src/types/Message";
import MessageDeliveryTicks from "src/components/UI/MessageDeliveryTicks";
interface Props {
  message: DecryptedMessage;
}
function LocationShare({
  message
}: Props): JSX.Element | null {
  const {
    timestamp
  } = message;
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const {
    thumbnail
  } = (message.attachment as LocationShareAttachment);
  return <div className='w-56 min-w-[300px]'>
            <div className='relative ml-auto text-xs w-full h-full rounded-sm'>
            <img src={`data:image/jpeg;base64,${thumbnail}`} alt="" className="w-full h-full" />
                <div className="w-full flex justify-between bg-black px-2 py-2">
                    <span className="text-white text-xs">Live location not available for web</span>
                    <div className="flex items-center mt-1 self-end text-2xs">
                        <span className='text-white text-2xs'>{formattedTimestamp}</span>
                        <MessageDeliveryTicks message={message} />
                    </div>    
                </div>
            </div>
        </div>;
}
export default LocationShare;
export enum CallTypes {
    OUTGOING = 'outgoing',
    INCOMING = 'incoming',
    ON_CALL = 'on_call'
}

export enum CallFormats {
    VOICE = 'VOICE',
    VIDEO = 'VIDEO',
    CONFERENCE_VOICE = 'CONFERENCE_VOICE',
    CONFERENCE_VIDEO = 'CONFERENCE_VIDEO'
}

export enum CallContactTypes {
    CONTACT = 'contact',
    GROUP = 'group'
}

export type CallFormat = `${CallFormats}`
export type CallType = `${CallTypes}`
export type CallContactType = `${CallContactTypes}`

export interface CallData {
    format: CallFormat,
    caller: {
        id: string;
        username: string;
    };
    channelId: string | null;
    type: CallType,
    groupId?: string;
    callId: string | null;
}

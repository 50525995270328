import { type ChatSettingNames, type ParsedChatSettings } from "src/types/Contact"

export const defaultAvatar = 'https://via.placeholder.com/60'

export const chatSettingTypes: ChatSettingNames[] = [
    'BLOCK',
    'NOTIFICATION',
    'TRANSLATION',
    'READ_RECEIPT'
]

export const emptyChatSettings: ParsedChatSettings = {
    from: {
        BLOCK: false,
        NOTIFICATION: false,
        READ_RECEIPT: true,
        TRANSLATION: false
    },
    to: {
        BLOCK: false,
        NOTIFICATION: false,
        READ_RECEIPT: true,
        TRANSLATION: false
    }
}

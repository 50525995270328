import { useCallback } from "react";
import { useAppDispatch } from "src/hooks/store";
import { hideLogout } from "src/store/slices/app";
import { xmpp } from "src/constants/xmpp";
function LogoutModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const close = useCallback(() => dispatch(hideLogout()), [dispatch]);
  const logout = useCallback(async () => {
    xmpp.logoutHandler();
  }, []);
  return <div className="absolute top-0 left-0 w-full h-screen z-80 bg-modal-overlay">

            <div className="absolute z-80 shadow rounded-xl overflow-hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96">

                <div className="relative bg-accent px-5 py-4">
                    <span className="text-lg font-semibold">Are you sure?</span>
                </div>

                <div className="px-10 py-8 bg-white flex items-center justify-end">
                    <span onClick={close} className="font-semibold clickable">Cancel</span>
                    <button onClick={logout} className="clickable bg-red-500 text-white px-4 py-1.5 rounded ml-4">Log out</button>
                </div>

            </div>

        </div>;
}
export default LogoutModal;
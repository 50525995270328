import axios, { type AxiosRequestConfig } from 'axios'
import { toast } from 'react-hot-toast';
import { API_URL } from 'src/constants/api'
import { LOCAL_STORAGE_API_TOKEN, LOCAL_STORAGE_SESSION_ID } from 'src/constants/config';
import HttpStatus from 'src/constants/http';
import { logoutApp } from 'src/helpers/app';

const API = axios.create({
    baseURL: API_URL,
    timeout: 15000,
})

API.interceptors.request.use(function (config: AxiosRequestConfig) {
    const token = localStorage.getItem(LOCAL_STORAGE_API_TOKEN)
    const sessionId = localStorage.getItem(LOCAL_STORAGE_SESSION_ID)

    if (config.headers !== undefined) {
        if (token !== null)
            config.headers.Authorization = `Bearer ${token}`

        if (sessionId !== null) {
            config.headers['session-id'] = sessionId
        }
    }

    return config;
})

API.interceptors.response.use(function (response) {
    return response;
}, async function (error) {
    const message = error.response?.data?.message ?? error.message ?? 'Something went wrong'    
    if((error.response.status < 400 || error.response.status >= 500) || process.env.NODE_ENV === 'development')
        toast.error(message)

    if(error.response.status === HttpStatus.UNAUTHORIZED) {
        logoutApp().catch(console.error)
    }
    
    return await Promise.reject(error);
});

export default API

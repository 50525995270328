import { useCallback, useEffect, useMemo, useState } from 'react';
import EmptyConversation from 'src/components/EmptyConversation';
import Chat from 'src/components/Contact/Chat';
import { useApp, useAppDispatch, useCall, useContacts, useUser } from 'src/hooks/store';
import { HOST, xmpp } from 'src/constants/xmpp';
import { addPendingContact, fetchMyVirtualCard } from 'src/helpers/contact';
import { useNavigate } from 'react-router-dom';
import GroupChat from 'src/components/Group/Chat';
import GroupMenu from 'src/components/Group/Chat/Menu';
import GroupAddMember from 'src/components/Group/Chat/AddMember';
import { xmppInitialize } from 'src/helpers/xmpp';
import { init as loadEmojiPicker } from 'emoji-mart';
import GroupCreate from 'src/components/Group/Create';
import { useEmoji } from 'src/contexts/Emoji';
import ContactInfo from 'src/components/Contact/Menu';
import Sidebar from 'src/components/Sidebar';
import SettingsModal from 'src/components/UI/Modals/Settings';
import RequestPermissionModal from 'src/components/Notification/RequestPermissionModal';
import LogoutModal from 'src/components/UI/Modals/Logout';
import Modal from 'src/components/Modal';
import { ActiveMediaContext } from 'src/contexts/ActiveMedia';
import { initializeMessaging, logoutApp } from 'src/helpers/app';
import ContactAgoraCall from 'src/components/Contact/Call';
import GroupAgoraCall from 'src/components/Group/Call';
import { useKeyboardListener } from 'src/hooks/useKeyboardListener';
import { hideLogout, hideSettings } from 'src/store/slices/app';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { useEventBus } from 'src/services/EventBus';
import { UupEvents } from 'src/constants/events';
import { finishLoading, sendReceivedEvent, sortContacts, syncContacts } from 'src/store/slices/contacts';
import ChannelCreate from 'src/components/Channel/Create';
import { type Contact } from 'src/types/Contact';
function MainPage(): JSX.Element {
  // const { PubSub } = xmpp.connection

  const {
    initialized: callInitialized,
    groupId: callGroupId
  } = useCall();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    logout,
    settings
  } = useApp();
  const [askNotificationPermission, $askNotificationPermission] = useState(false);
  const [connected, $connected] = useState(false);
  const emojiData = useEmoji();
  const {
    user
  } = useUser();
  const {
    loading,
    activeContact,
    activeGroup,
    pendingRosters,
    contacts
  } = useContacts();
  const [activeMedia, setActiveMedia] = useState<WaveSurfer | null>(null);
  const Conversation = useMemo(() => {
    if (activeContact != null) return <Chat />;else if (activeGroup != null) return <GroupChat />;else return <EmptyConversation />;
  }, [activeContact, activeGroup]);
  const checkNotificationPermission = useCallback((): void => {
    const askNotificationPermission = localStorage.getItem('ask-for-notification-permission');
    if (Notification.permission === 'default' && askNotificationPermission !== 'denied') {
      $askNotificationPermission(true);
    }
  }, [$askNotificationPermission]);
  useEventBus(UupEvents.CONTACTS_LOADED, (payload: Contact[]) => requestAnimationFrame(() => {
    console.log('[CONTACTS_LOADED]', payload);
    setTimeout(function () {
      dispatch(sendReceivedEvent(payload));
    }, 1000);
    dispatch(syncContacts());
    dispatch(sortContacts());
    dispatch(finishLoading());
  }));
  const xmppLogin = useCallback(async (): Promise<void> => {
    if (user === null) return;
    xmpp.login(user.uid, HOST, user.password, () => {
      checkNotificationPermission();
      xmppInitialize(user, contacts, dispatch);
      initializeMessaging(user, dispatch).catch(console.error);
      $connected(true);
    });
  }, [checkNotificationPermission, contacts, dispatch, user]);
  const initXmpp = useCallback(async (): Promise<void> => {
    if (user === null) {
      navigate('/');
      return;
    }
    if (xmpp.isConnecting || xmpp.connection.connected) return;
    xmpp.logoutHandler = () => {
      void logoutApp(() => {
        dispatch({
          type: 'PURGE'
        });
        // const firebaseMessaging = messaging.getMessaging(app)
        // messaging.deleteToken(firebaseMessaging).catch(console.error)
        navigate('/');
      });
    };
    xmppLogin().catch(console.error);
  }, [dispatch, navigate, user, xmppLogin]);
  useEffect(() => {
    // setTimeout(() => {
    void initXmpp();
    // }, 3000);
  }, [initXmpp]);
  useEffect(() => {
    const onlineListener = (): void => {
      console.log('Became online');
      xmpp.connection.reset();
      xmpp.reconnect();
      void initXmpp();
    };
    const offlineListener = (): void => {
      console.log('Became offline');
      xmpp.connection.disconnect('offline');
      xmpp.connection.reset();
      $connected(false);
    };
    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);
    return () => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);
    };
  }, [initXmpp]);
  useEffect(() => {
    loadEmojiPicker({
      data: emojiData
    });
  }, [emojiData]);
  useEffect(() => {
    if (pendingRosters.length === 0) return;
    console.log('[PENDING ROSTERS]', pendingRosters);
    pendingRosters.forEach((jid: string) => {
      addPendingContact(xmpp, dispatch, jid).catch(console.error);
    });
  }, [connected, dispatch, pendingRosters]);
  useEffect(() => {
    if (user !== null && user.username === undefined) {
      fetchMyVirtualCard(user, dispatch).catch(console.error);
    }
  }, [dispatch, user]);
  useEffect(() => {
    fetchMyVirtualCard(user, dispatch).catch(console.error);
  }, []);

  // const enablePubSub = (): void => {
  //     xmpp.connection.PubSub.connect(xmpp.connection.jid, process.env.REACT_APP_PUBSUB_SERVICE)
  // }

  // const createNode = (): void => {
  //     xmpp.connection.PubSub.createNode('ffx', {
  //         publishModel: 'subscribers',
  //         persistItems: true,
  //         maxItems: 3
  //     })
  // }

  // const subscribe = (): void => {
  //     xmpp.connection.PubSub.subscribe('de27bec21712c6b087cd810151e6deff46f1c5c415e099a13afd2d9e94fd5616_LOCATION')
  // }

  // const publish = (): void => {
  //     xmpp.connection.PubSub.publish('de27bec21712c6b087cd810151e6deff46f1c5c415e099a13afd2d9e94fd5616_LOCATION', [
  //         {
  //             attrs: {
  //                 id: v4(),
  //                 xmlns: 'http://jabber.org/protocol/pubsub#event'
  //             },
  //             data: JSON.stringify({
  //                 a: 5,
  //                 b: 7
  //             })
  //         }
  //     ], (res: any) => {
  //         console.log('[PUBSUB] PUB', res)
  //     })
  // }

  // const getItems = (): void => {
  //     xmpp.connection.PubSub.items('de27bec21712c6b087cd810151e6deff46f1c5c415e099a13afd2d9e94fd5616_LOCATION', (res: any) => {
  //         console.log('[PUBSUB] ITEMS', res)
  //     }, (err: any) => {
  //         console.error(err)
  //     }, 10)
  // }

  useKeyboardListener('Escape', () => {
    dispatch(hideSettings());
    dispatch(hideLogout());
  });
  const renderContactCall = useMemo(() => <Modal>
                <ContactAgoraCall />
            </Modal>, []);
  const renderGroupCall = useMemo(() => <Modal>
                <GroupAgoraCall />
            </Modal>, []);

  // useEventBus(UupEvents.ADD_MISSING_VIRTUAL_CARD, (payload: string[]) => {
  //     console.log('[ADD_MISSING_VIRTUAL_CARD]', payload);
  //     // dispatch(sortContacts())

  //     // payload.forEach(contactJid => {
  //     //     console.log('[ADD_MISSING_VIRTUAL_CARD] ADDING', contactJid);
  //     //     dispatch(removeContact(contactJid))

  //     // });
  // })

  const renderMain = useMemo(() => !loading && <>
                {callInitialized && callGroupId === null && renderContactCall}
                {callInitialized && callGroupId !== null && renderGroupCall}
                <GroupCreate />
                <ChannelCreate />
                <Sidebar />
                <div className='w-3/4'>{Conversation}</div>
                <GroupAddMember />
                <GroupMenu />
                <ContactInfo />
            </>, [Conversation, callGroupId, callInitialized, loading, renderContactCall, renderGroupCall]);
  return <ActiveMediaContext.Provider value={{
    activeMedia,
    setActiveMedia
  }}>
            {loading && <div className='absolute top-0 left-0 z-100 w-full h-screen overflow-hidden bg-gray-100 flex justify-center items-center'>
                <div className="flex items-center">
                    <LoadingSpinner size='10' />
                    <div>
                        <span className='ml-4 font-light text-2xl'>Loading...</span>
                        <span className='ml-4 text-gray-500 block mt-2'>Please wait while we retrieve your messages</span>
                    </div>
                </div>
            </div>}

            <div className='flex items-start bg-white overflow-hidden w-full h-screen'>
                {settings && <Modal>
                        <SettingsModal />
                    </Modal>}
                {logout && <Modal>
                        <LogoutModal />
                    </Modal>}
                {askNotificationPermission && <Modal>
                        <RequestPermissionModal onClose={() => {
          $askNotificationPermission(false);
        }} />
                    </Modal>}
                {renderMain}
            </div>
        </ActiveMediaContext.Provider>;
}
export default MainPage;
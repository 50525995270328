import moment from "moment";
import { parseTimestamp } from "src/helpers/message";
import { type ContactAttachment, type DecryptedMessage } from "src/types/Message";
import { defaultAvatar } from "src/constants/contact";
import MessageDeliveryTicks from "src/components/UI/MessageDeliveryTicks";
import clsx from "clsx";
import { useMemo } from "react";
interface Props {
  message: DecryptedMessage;
}
function ContactMessage({
  message
}: Props): JSX.Element {
  const {
    timestamp,
    received
  } = message;
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const {
    name,
    numbers
  } = (message.attachment as ContactAttachment);
  const getFirstLetters = useMemo(() => {
    const [firstname, lastname] = name.trim().split(' ');
    return (firstname?.split('')[0] ?? '') + (lastname?.split('')[0] ?? '');
  }, [name]);
  return <div className='flex items-center justify-between ml-auto w-full h-full rounded-sm p-4'>
            <div className="flex items-center justify-center font-semibold bg-[#7B7BAC] text-xl text-white w-14 h-14 rounded-full shadow">
                {getFirstLetters}
            </div>

            <div className="flex flex-col ml-4">
                <span className={clsx('font-semibold text-sm', !received && 'text-white')}>{name}</span>
                <span className="text-xs text-hover-text mt-1">{numbers}</span>
            </div>
            <div className="relative flex -bottom-1 ml-2 self-end text-2xs">
                <span className='relative text-gray-400'>{formattedTimestamp}</span>
                <MessageDeliveryTicks message={message} />
            </div>
        </div>;
}
export default ContactMessage;
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from 'src/store'
import { type AppState } from 'src/store/slices/app'
import { type CallState } from 'src/store/slices/call'
import { type ContactsState } from 'src/store/slices/contacts'
import { type ConversationState } from 'src/store/slices/conversation'
import { type CreateChannelState } from 'src/store/slices/createChannel'
import { type CreateGroupState } from 'src/store/slices/createGroup'
import { type SelectionState } from 'src/store/slices/selection'
import { type UserState } from 'src/store/slices/user'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useUser = (): UserState => useAppSelector((state) => state.user)
export const useCreateGroup = (): CreateGroupState => useAppSelector((state) => state.createGroup)
export const useCreateChannel = (): CreateChannelState => useAppSelector((state) => state.createChannel)
export const useContacts = (): ContactsState => useAppSelector((state) => state.contacts)
export const useConversation = (): ConversationState => useAppSelector((state) => state.conversation)
// export const useActiveMedia = () => useAppSelector((state) => state.activeMedia)
export const useSelection = (): SelectionState => useAppSelector((state) => state.selection)
export const useCall = (): CallState => useAppSelector((state) => state.call)
export const useApp = (): AppState => useAppSelector((state) => state.app)

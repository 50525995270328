enum LoggerType {
    debug = 'debug',
    info = 'info',
    error = 'error',
    warn = 'warn',
}

type PropType = 'debug' | 'info' | 'error' | 'warn';

const styles = {
    [LoggerType.debug]: 'background: #bada55; color: #222; font-size: 12px; padding: 2px; font-weight: bold;',
    [LoggerType.info]: 'background: dodgerblue; color: #fff; font-size: 12px; padding: 2px; font-weight: bold;',
    [LoggerType.error]: 'background: #ff0000; color: #fff; font-size: 12px; padding: 2px; font-weight: bold;',
    [LoggerType.warn]: 'background: #ffcc00; color: #222; font-size: 12px; padding: 2px; font-weight: bold;',
}

export const logger = (type: PropType, title: string, ...rest: any): void => {
    console.log(`%c ${title.toUpperCase()} `, styles[type], ...rest);
}

import { createSlice } from '@reduxjs/toolkit'
import type { Contact } from 'src/types/Contact';
import reducers from './reducers';

export type CreateGroupStep = 'participants' | 'info'

export interface CreateGroupState {
    active: boolean;
    step: CreateGroupStep;
    name: string;
    contacts: Contact[];
    avatar: File | null;
    avatarSrc: string | null;
}

export const initialState: CreateGroupState = {
    active: false,
    step: 'participants',
    name: '',
    contacts: [],
    avatar: null,
    avatarSrc: null,
}

export const createGroupSlice = createSlice({
    name: 'createGroup',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    setCreateGroupActive,
    setCreateGroupStep,
    setCreateGroupName,
    setCreateGroupContacts,
    setCreateGroupAvatar,
    setCreateGroupAvatarSrc,
    resetCreateGroup
} = createGroupSlice.actions

export default createGroupSlice.reducer

import { useCallback } from "react";
import { xmpp } from "src/constants/xmpp";
import { getNodeFromJid } from "src/helpers/contact";
import { useAppDispatch, useContacts } from "src/hooks/store";
import API from "src/services/Api";
import { forceRemoveContact, setActiveGroup, updateGroup } from "src/store/slices/contacts";
import { ContactTypes } from "src/types/Contact";
interface Props {
  onCancel: () => void;
  onLeave: () => void;
}
function LeaveGroup({
  onCancel,
  onLeave
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    activeGroup
  } = useContacts();
  const leaveGroup = useCallback(async () => {
    if (activeGroup === null) return;
    const groupId = getNodeFromJid(activeGroup.jid);
    const myId = getNodeFromJid(xmpp.connection.jid);
    try {
      await API.delete(`/muc/rooms/${groupId}/users/${myId}`);
    } catch {}
    dispatch(updateGroup({
      ...activeGroup,
      isLeft: true
    }));
    xmpp.leave(activeGroup.jid);
    if (activeGroup.type === ContactTypes.CHANNEL) {
      xmpp.connection.roster.remove(activeGroup.jid);
      xmpp.connection.roster.unsubscribe(activeGroup.jid);
      dispatch(forceRemoveContact(activeGroup.jid));
    }
    dispatch(setActiveGroup(null));
    onLeave();
  }, [activeGroup, dispatch, onLeave]);
  return <div className="absolute top-0 left-0 w-full h-screen z-80 bg-modal-overlay">

            <div className="absolute z-80 shadow rounded-xl overflow-hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96">

                <div className="relative bg-accent px-5 py-4">
                    <span className="text-lg font-semibold">Are you sure?</span>
                </div>

                <div className="px-10 py-8 bg-white flex items-center justify-end">
                    <span onClick={onCancel} className="font-semibold clickable">Cancel</span>
                    <button onClick={leaveGroup} className="clickable bg-red-500 text-white px-4 py-1.5 rounded ml-4">Leave</button>
                </div>

            </div>

        </div>;
}
export default LeaveGroup;
import LoadingImage from '../LoadingImage';
import { defaultAvatar } from 'src/constants/contact';
import { type GroupUser as IGroupUser } from 'src/types/Group';
interface Props {
  onPress?: () => void;
  user: IGroupUser;
  isSelected?: boolean;
}
function GroupUser({
  user,
  isSelected,
  onPress
}: Props): JSX.Element {
  return <div onClick={onPress} className={`group flex items-center px-3 py-2 transition rounded-lg hover:bg-hover-bg cursor-pointer [content-visibility: auto] ${isSelected === true ? 'bg-gray-200' : ''}`}>
            <LoadingImage url={user.photo ?? defaultAvatar} fallback={defaultAvatar} className='shadow bg-white p-0.5 w-16 h-16 rounded-xl shrink-0 object-cover' spinnerSize='16' alt="avatar" />

            <div className="flex flex-col w-full ml-3">
                <div className="flex justify-between">
                    <span className='text-primary font-semibold w-32'>{user.name ?? 'Group Member'}</span>
                    {user.affiliation !== 'member' && user.affiliation !== 'none' && <div className="center-flex text-2xs text-white bg-primary rounded-xl px-2 p-1">
                        {user.affiliation}
                    </div>}
                </div>
            </div>
        </div>;
}
export default GroupUser;
import { type PayloadAction } from "@reduxjs/toolkit";
import { getNodeFromJid } from "src/helpers/contact";
import { type RosterStateChange, RosterStateType } from "src/types/Roster";
import { type ContactsState } from "..";
import { EventBus } from "src/services/EventBus";
import { UupEvents } from "src/constants/events";
import { HOST } from "src/constants/xmpp";

const reducers = {
    setRosterState: (state: ContactsState, action: PayloadAction<RosterStateChange>) => {
        const { from, type, value } = action.payload;

        const id = getNodeFromJid(from)

        const rosterState = state.rosterState[id] ?? {
            online: false,
            typing: false,
            lastSeen: null
        }

        if (type === RosterStateType.ONLINE && typeof value === 'boolean') rosterState.online = value

        else if (type === RosterStateType.LAST_SEEN && typeof value === 'string') {
            rosterState.lastSeen = value
            rosterState.typing = false
        }

        else if (type === RosterStateType.TYPING && typeof value === 'boolean') rosterState.typing = value

        if (type === RosterStateType.ONLINE && !rosterState.online && !rosterState.typing) {
            const fullJid = `${from}@${HOST}`
            rosterState.lastSeen = null
            setTimeout(() => {
                EventBus.next({ type: UupEvents.FETCH_LAST_SEEN, payload: fullJid })
            }, 3000);
        }
        state.rosterState = { ...state.rosterState, [id]: rosterState }
    },
    removeContact: (state: ContactsState, action: PayloadAction<string>) => {
        const jid = getNodeFromJid(action.payload);
        state.activeContact = null
        state.contacts = state.contacts.map(contact => {
            if (contact.jid === action.payload) {
                contact.isHidden = true
            }
            return contact
        })
        delete state.messages[jid]
    },
    forceRemoveContact: (state: ContactsState, action: PayloadAction<string>) => {
        const id = getNodeFromJid(action.payload);
        state.activeContact = null
        state.contacts = state.contacts.filter(contact => contact.jid !== action.payload)
        delete state.messages[id]
    },
    resetPendingRosters:(state: ContactsState) => {
        state.pendingRosters = []
    },
}

export default reducers

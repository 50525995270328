import { useCallback, useState } from "react";
interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}
function Checkbox({
  checked,
  onChange
}: Props): JSX.Element {
  const [state, $state] = useState(checked ?? false);
  const toggle = useCallback(() => {
    $state(prev => !prev);
    if (onChange != null) onChange(!state);
  }, [onChange, state]);
  return <div onClick={toggle} className={`cursor-pointer transition hover:opacity-80 w-10 h-6 border border-primary ${state ? 'bg-primary' : 'bg-accent'} rounded-3xl flex items-center`}>
            <div className={`transition w-5 h-5 ${state ? 'bg-accent' : 'bg-primary'} rounded-full ${state ? 'translate-x-[85%]' : 'translate-x-[5%]'}`}></div>
        </div>;
}
export default Checkbox;
import { useCallback, useEffect, useRef } from "react";
interface Props {
  onClickOutside: () => void;
  children: React.ReactNode;
  [key: string]: any;
}
function OutsideClickDetector({
  children,
  onClickOutside,
  ...rest
}: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback((e: Event) => {
    const container = containerRef.current;
    const target = (e.target as HTMLElement);
    const isClickedTrigger = target.dataset.trigger ?? target.closest('[data-trigger]');
    const isClickedDismiss = target.dataset.dismiss ?? target.closest('[data-dismiss]');
    if (isClickedTrigger !== null) return;
    if (isClickedDismiss !== null) {
      onClickOutside();
    }
    if (container != null && !container.contains(target)) {
      onClickOutside();
    }
  }, [onClickOutside]);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);
  return <div ref={containerRef} {...rest}>
            {children}
        </div>;
}
export default OutsideClickDetector;
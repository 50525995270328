import { type DecryptedMessage } from "src/types/Message";
import MessageDeliveryTicks from "./MessageDeliveryTicks";
interface Props {
  message: DecryptedMessage;
  unreadMessagesLength: number;
}
function LastMessageDetails({
  message,
  unreadMessagesLength
}: Props): JSX.Element | null {
  return message.received ? unreadMessagesLength > 0 ? <div className="bg-primary flex items-center font-bold justify-center text-white text-2xs rounded-full w-4 h-4">
            {unreadMessagesLength}
        </div> : null : <MessageDeliveryTicks message={message} defaultColor='#121212' />;
}
export default LastMessageDetails;
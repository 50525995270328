import { generateGradientStyleFromId } from "src/helpers/common";
import { getNodeFromJid } from "src/helpers/contact";
import { type Contact } from "src/types/Contact";
interface Props {
  contact: Contact;
  size?: number | string;
}
export default function FallbackAvatar({
  contact,
  size = 16
}: Props): JSX.Element {
  return <div className={`shadow flex items-center justify-center bg-white p-2 w-${size} h-${size} rounded-xl shrink-0 object-cover`} style={generateGradientStyleFromId(getNodeFromJid(contact.jid))}>
            <span className='text-2xl text-white uppercase'>{contact.name.charAt(0)}</span>
        </div>;
}
/** File: strophe.pubsub.js
 *  A Strophe plugin for XMPP Publish-Subscribe.
 *
 *  Provides Strophe.Connection.pubsub object,
 *  parially implementing XEP 0060.
 *
 *  Strophe.Builder.prototype methods should probably move to strophe.js
 */

import { type CreateNodeOptions } from "src/types/XMPP";
import { v4 } from "uuid";

/** Function: Strophe.Builder.form
 *  Add an options form child element.
 *
 *  Does not change the current element.
 *
 *  Parameters:
 *    (String) ns - form namespace.
 *    (Object) options - form properties.
 *
 *  Returns:
 *    The Strophe.Builder object.
 */

class StrophePubSub {
    /*
    Extend connection object to have plugin name 'pubsub'.
    */
    _connection: Strophe.Connection;
    _autoService: boolean = true
    service: any = null
    jid: string | null = null
    handler: any = {}

    // The plugin must have the init function.
    init(conn: any): void {

        this._connection = conn;

        /*
        Function used to setup plugin.
        */

        /* extend name space
        *  NS.PUBSUB - XMPP Publish Subscribe namespace
        *              from XEP 60.
        *
        *  NS.PUBSUB_SUBSCRIBE_OPTIONS - XMPP pubsub
        *                                options namespace from XEP 60.
        */
        Strophe.addNamespace('PUBSUB', "http://jabber.org/protocol/pubsub");
        Strophe.addNamespace('PUBSUB_SUBSCRIBE_OPTIONS', Strophe.NS.PUBSUB + "#subscribe_options");
        Strophe.addNamespace('PUBSUB_ERRORS', Strophe.NS.PUBSUB + "#errors");
        Strophe.addNamespace('PUBSUB_EVENT', Strophe.NS.PUBSUB + "#event");
        Strophe.addNamespace('PUBSUB_OWNER', Strophe.NS.PUBSUB + "#owner");
        Strophe.addNamespace('PUBSUB_AUTO_CREATE', Strophe.NS.PUBSUB + "#auto-create");
        Strophe.addNamespace('PUBSUB_PUBLISH_OPTIONS', Strophe.NS.PUBSUB + "#publish-options");
        Strophe.addNamespace('PUBSUB_NODE_CONFIG', Strophe.NS.PUBSUB + "#node_config");
        Strophe.addNamespace('PUBSUB_CREATE_AND_CONFIGURE', Strophe.NS.PUBSUB + "#create-and-configure");
        Strophe.addNamespace('PUBSUB_SUBSCRIBE_AUTHORIZATION', Strophe.NS.PUBSUB + "#subscribe_authorization");
        Strophe.addNamespace('PUBSUB_GET_PENDING', Strophe.NS.PUBSUB + "#get-pending");
        Strophe.addNamespace('PUBSUB_MANAGE_SUBSCRIPTIONS', Strophe.NS.PUBSUB + "#manage-subscriptions");
        Strophe.addNamespace('PUBSUB_META_DATA', Strophe.NS.PUBSUB + "#meta-data");
        Strophe.addNamespace('ATOM', "http://www.w3.org/2005/Atom");

        if (conn.disco !== undefined)
            conn.disco.addFeature(Strophe.NS.PUBSUB);

    }

    // Called by Strophe on connection event
    statusChanged(status: any, condition: any): void {
        if (this._connection === null) return;

        if (this._autoService && status === Strophe.Status.CONNECTED) {
            this.service = 'pubsub.' + Strophe.getDomainFromJid(this._connection.jid);
            this.jid = this._connection.jid;
        }
    }

    /** *Function

    Parameters:
    (String) jid - The node owner's jid.
    (String) service - The name of the pubsub service.
    */
    connect(jid: any, service: any = undefined): void {
        if (this._connection === null) return;

        if (service === undefined) {
            service = jid;
            jid = undefined;
        }
        this.jid = jid ?? this._connection.jid;
        this.service = service ?? null;
        this._autoService = false;
    }

    /** *Function

     Parameters:
     (String) node - The name of node
     (String) handler - reference to registered strophe handler
     */
    storeHandler(node: any, handler: any): void {
        if (this.handler[node] === undefined) {
            this.handler[node] = [];
        }
        this.handler[node].push(handler);
    }

    /** *Function

     Parameters:
     (String) node - The name of node
     */
    removeHandler(node: any): void {

        const toberemoved = this.handler[node];
        this.handler[node] = [];

        // remove handler
        if (this._connection === null) return

        if (toberemoved !== undefined && toberemoved.length > 0) {
            for (let i = 0, l = toberemoved.length; i < l; i++) {
                this._connection.deleteHandler(toberemoved[i])
            }
        }
    }

    // TODO:
    createNode(nodeName: string, options: CreateNodeOptions = {}): void {

        const {
            deliverNotifications = true,
            deliverPayloads = true,
            maxItems = 10,
            persistItems = true,
            itemExpire = 604800,
            sendLastPublishedItem = 'never',
            accessModel = 'open',
            publishModel = 'publishers',
            notificationType = 'headline',
            presenceBasedDelivery = false,
            notifyConfig = false,
            notifyDelete = true,
            notifyRetract = true,
            notifySub = false,
            maxPayloadSize = 1028,
            purgeOffline = false
        } = options

        const iq = this.buildIQ(`
            <iq type='set'
                from='${this._connection.jid}'
                to='${this.service as string}'
                id='create1'>
                <pubsub xmlns='http://jabber.org/protocol/pubsub'>
                    <create node='${nodeName}'/>
                    <configure>
                    <x xmlns='jabber:x:data' type='submit'>
                        <field var='FORM_TYPE' type='hidden'>
                            <value>http://jabber.org/protocol/pubsub#node_config</value>
                        </field>
                        <field var='pubsub#title'><value>${nodeName}</value></field>
                        <field var='pubsub#deliver_notifications'><value>${deliverNotifications ? 1 : 0}</value></field>
                        <field var='pubsub#deliver_payloads'><value>${deliverPayloads ? 1 : 0}</value></field>
                        <field var='pubsub#persist_items'><value>${persistItems ? 1 : 0}</value></field>
                        <field var='pubsub#max_items'><value>${maxItems}</value></field>
                        <field var='pubsub#item_expire'><value>${itemExpire}</value></field>
                        <field var='pubsub#access_model'><value>${accessModel}</value></field>
                        <field var='pubsub#publish_model'><value>${publishModel}</value></field>
                        <field var='pubsub#purge_offline'><value>${purgeOffline ? 1 : 0}</value></field>
                        <field var='pubsub#send_last_published_item'><value>${sendLastPublishedItem}</value></field>
                        <field var='pubsub#presence_based_delivery'><value>${presenceBasedDelivery ? 'true' : 'false'}</value></field>
                        <field var='pubsub#notification_type'><value>${notificationType}</value></field>
                        <field var='pubsub#notify_config'><value>${notifyConfig ? 1 : 0}</value></field>
                        <field var='pubsub#notify_delete'><value>${notifyDelete ? 1 : 0}</value></field>
                        <field var='pubsub#notify_retract'><value>${notifyRetract ? 1 : 0}</value></field>
                        <field var='pubsub#notify_sub'><value>${notifySub ? 'true' : 'false'}</value></field>
                        <field var='pubsub#max_payload_size'><value>${maxPayloadSize}</value></field>
                        <field var='pubsub#type'><value>urn:example:e2ee:bundle</value></field>
                        <field var='pubsub#body_xslt'>
                            <value>http://jabxslt.jabberstudio.org/atom_body.xslt</value>
                        </field>
                    </x>
                    </configure>
                </pubsub>
            </iq>`)

        this._connection.sendIQ(iq)
    }

    /** Function: deleteNode
     *  Delete a pubsub node.
     *
     *  Parameters:
     *    (String) node -  The name of the pubsub node.
     *    (Function) call_back - Called on server response.
     *
     *  Returns:
     *    Iq id
     */
    deleteNode(node: any, callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubdeletenode");

        const iq = $iq({ from: this.jid, to: this.service, type: 'set', id: iqid })
            .c('pubsub', { xmlns: Strophe.NS.PUBSUB_OWNER })
            .c('delete', { node });

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /** Function
     *
     * Get all nodes that currently exist.
     *
     * Parameters:
     *   (Function) success - Used to determine if node creation was sucessful.
     *   (Function) error - Used to determine if node
     * creation had errors.
     */
    discoverNodes(success: any, error: any, timeout: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        // ask for all nodes
        const iq = $iq({ from: this.jid, to: this.service, type: 'get' })
            .c('query', { xmlns: Strophe.NS.DISCO_ITEMS });

        return this._connection.sendIQ(iq.tree(), success, error, timeout);
    }

    /** Function: getConfig
     *  Get node configuration form.
     *
     *  Parameters:
     *    (String) node -  The name of the pubsub node.
     *    (Function) call_back - Receives config form.
     *
     *  Returns:
     *    Iq id
     */
    getConfig(node: any, callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubconfigurenode");

        const iq = $iq({ from: this.jid, to: this.service, type: 'get', id: iqid })
            .c('pubsub', { xmlns: Strophe.NS.PUBSUB_OWNER })
            .c('configure', { node });

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /**
     *  Parameters:
     *    (Function) call_back - Receives subscriptions.
     *
     *  http://xmpp.org/extensions/tmp/xep-0060-1.13.html
     *  8.3 Request Default Node Configuration Options
     *
     *  Returns:
     *    Iq id
     */
    getDefaultNodeConfig(callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubdefaultnodeconfig");

        const iq = $iq({ from: this.jid, to: this.service, type: 'get', id: iqid })
            .c('pubsub', { 'xmlns': Strophe.NS.PUBSUB_OWNER })
            .c('default');

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    buildIQ(xml: string, type: DOMParserSupportedType = 'text/xml'): Element {
        const iq = new DOMParser().parseFromString(xml, type)
        return iq.getElementsByTagName('iq')[0]
    }

    subscribe(nodeName: string): void {
        if (this._connection === null || this.jid === null) return

        const iq = this.buildIQ(`
        <iq type='set'
            from='${this._connection.jid}'
            to='pubsub.uup-chat-dev'
            id='sub1'>
            <pubsub xmlns='http://jabber.org/protocol/pubsub'>
                <subscribe
                    node='${nodeName}'
                    jid='${this._connection.jid}'/>
            </pubsub>
        </iq>`)

        const hand = this._connection.addHandler((stanza: Element) => {
            console.log('[PubSub] Subscribe Response', stanza)

            return true
        }, '', 'message', '', '', '');

        this.storeHandler(nodeName, hand);

        this._connection.sendIQ(iq, (iq) => {
            console.log('[PubSub] Subscribe Success', iq)
        }, (iq) => {
            console.error('[PubSub] Subscribe Error', iq)
        })
    }

    /** *Function
        Unsubscribe from a node.

        Parameters:
        (String) node       - The name of the pubsub node.
        (Function) success  - callback function for successful node creation.
        (Function) error    - error callback function.

    */
    unsubscribe(node: any, jid: any, subid: any, success: any, error: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubunsubscribenode");

        const iq = $iq({ from: this.jid, to: this.service, type: 'set', id: iqid })
            .c('pubsub', { xmlns: Strophe.NS.PUBSUB })
            .c('unsubscribe', { node, jid });
        if (subid !== undefined) iq.attrs({ subid });

        this._connection.sendIQ(iq.tree(), success, error);
        this.removeHandler(node);
        return iqid;
    }

    /** *Function

    Publish and item to the given pubsub node.

    Parameters:
    (String) node -  The name of the pubsub node.
    (Array) items -  The list of items to be published.
    (Function) call_back - Used to determine if node
    creation was sucessful.
    */

    publish(node: string, items: any[], callback: any): string {
        const id = v4()

        const iq = this.buildIQ(`
        <iq type='set'
            from='${this._connection.jid}'
            to='${this.service as string}'
            id='${id}'>
                <pubsub xmlns='http://jabber.org/protocol/pubsub'>
                    <publish node='${node}'>
                        <item id="abc3">
                            <eventx>updated</eventx>
                        </item>
                    </publish>
                </pubsub>
            </iq>`)

        this._connection.addHandler((stanza: Element) => {
            console.log('[PubSub] Publish Success', stanza)

            return true
        }, '', 'iq', '', id);

        return this._connection.sendIQ(iq, callback, callback);
    }

    /* Function: items
    Used to retrieve the persistent items from the pubsub node.

    */
    items(node: any, success: any, error: any, timeout: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        // ask for all items
        const iq = $iq({ from: this.jid, to: this.service, type: 'get' })
            .c('pubsub', { xmlns: Strophe.NS.PUBSUB })
            .c('items', { node });

        return this._connection.sendIQ(iq.tree(), success, error, timeout);
    }

    /** Function: getSubscriptions
     *  Get subscriptions of a JID.
     *
     *  Parameters:
     *    (Function) call_back - Receives subscriptions.
     *
     *  http://xmpp.org/extensions/tmp/xep-0060-1.13.html
     *  5.6 Retrieve Subscriptions
     *
     *  Returns:
     *    Iq id
     */
    getSubscriptions(callback: any, timeout: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubsubscriptions");

        const iq = $iq({ from: this.jid, to: this.service, type: 'get', id: iqid })
            .c('pubsub', { 'xmlns': Strophe.NS.PUBSUB })
            .c('subscriptions');

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /** Function: getNodeSubscriptions
     *  Get node subscriptions of a JID.
     *
     *  Parameters:
     *    (Function) call_back - Receives subscriptions.
     *
     *  http://xmpp.org/extensions/tmp/xep-0060-1.13.html
     *  5.6 Retrieve Subscriptions
     *
     *  Returns:
     *    Iq id
     */
    getNodeSubscriptions(node: any, callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubsubscriptions");

        const iq = $iq({ from: this.jid, to: this.service, type: 'get', id: iqid })
            .c('pubsub', { 'xmlns': Strophe.NS.PUBSUB_OWNER })
            .c('subscriptions', { node });

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /** Function: getSubOptions
     *  Get subscription options form.
     *
     *  Parameters:
     *    (String) node -  The name of the pubsub node.
     *    (String) subid - The subscription id (optional).
     *    (Function) call_back - Receives options form.
     *
     *  Returns:
     *    Iq id
     */
    getSubOptions(node: any, subid: any, callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return

        const iqid = this._connection.getUniqueId("pubsubsuboptions");

        const iq = $iq({ from: this.jid, to: this.service, type: 'get', id: iqid })
            .c('pubsub', { xmlns: Strophe.NS.PUBSUB })
            .c('options', { node, jid: this.jid });
        if (subid !== undefined) iq.attrs({ subid });

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /**
     *  Parameters:
     *    (String) node -  The name of the pubsub node.
     *    (Function) call_back - Receives subscriptions.
     *
     *  http://xmpp.org/extensions/tmp/xep-0060-1.13.html
     *  8.9 Manage Affiliations - 8.9.1.1 Request
     *
     *  Returns:
     *    Iq id
     */
    getAffiliations(node: any, callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return
        const iqid = this._connection.getUniqueId("pubsubaffiliations");

        if (typeof node === 'function') {
            callback = node;
            node = undefined;
        }

        const attrs: any = {}; let xmlns = { 'xmlns': Strophe.NS.PUBSUB };
        if (node !== undefined) {
            attrs.node = node;
            xmlns = { 'xmlns': Strophe.NS.PUBSUB_OWNER };
        }

        const iq = $iq({ from: this.jid, to: this.service, type: 'get', id: iqid })
            .c('pubsub', xmlns).c('affiliations', attrs);

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /**
     *  Parameters:
     *    (String) node -  The name of the pubsub node.
     *    (Function) call_back - Receives subscriptions.
     *
     *  http://xmpp.org/extensions/tmp/xep-0060-1.13.html
     *  8.9.2 Modify Affiliation - 8.9.2.1 Request
     *
     *  Returns:
     *    Iq id
     */
    setAffiliation(node: any, jid: any, affiliation: any, callback: any): string | undefined {
        if (this._connection === null || this.jid === null) return
        const iqid = this._connection.getUniqueId("pubsubaffiliations");

        const iq = $iq({ from: this.jid, to: this.service, type: 'set', id: iqid })
            .c('pubsub', { 'xmlns': Strophe.NS.PUBSUB_OWNER })
            .c('affiliations', { node })
            .c('affiliation', { jid, affiliation });

        this._connection.addHandler(callback, '', 'iq', '', iqid);
        this._connection.send(iq.tree());

        return iqid;
    }

    /** Function: publishAtom
     */
    publishAtom(node: any, atoms: any, callback: any): string | undefined {
        if (!Array.isArray(atoms))
            atoms = [atoms];

        let i; let atom; const entries = [];
        for (i = 0; i < atoms.length; i++) {
            atom = atoms[i];

            atom.updated = atom.updated ?? (new Date()).toISOString();
            if (atom.published?.toISOString !== undefined)
                atom.published = atom.published.toISOString();

            entries.push({
                data: $build("entry", { xmlns: Strophe.NS.ATOM })
                    .children(atom).tree(),
                attrs: (atom.id !== undefined ? { id: atom.id } : {}),
            });
        }
        return this.publish(node, entries, callback);
    }
}

Strophe.Builder.prototype.form = function (ns: any, options: any) {
    this.cnode(Strophe.xmlElement('x', { "xmlns": "jabber:x:data", "type": "submit" }))
        .cnode(Strophe.xmlElement('field', { "var": "FORM_TYPE", "type": "hidden" }))
        .cnode(Strophe.xmlElement('value'))
        .t(ns);

    for (const i in options) {
        this.cnode(Strophe.xmlElement('field', { "var": i }))
            .cnode(Strophe.xmlElement('value'))
            .t(options[i]);
    }
    return this;
};

/** Function: Strophe.Builder.list
 *  Add many child elements.
 *
 *  Does not change the current element.
 *
 *  Parameters:
 *    (String) tag - tag name for children.
 *    (Array) array - list of objects with format:
 *          { attrs: { [string]:[string], ... }, // attributes of each tag element
 *             data: [string | XML_element] }    // contents of each tag element
 *
 *  Returns:
 *    The Strophe.Builder object.
 */
Strophe.Builder.prototype.list = function (tag: any, array: any) {
    for (let i = 0; i < array.length; ++i) {
        this.c(tag, array[i].attrs)

        const data = array[i].data.cloneNode !== undefined
            ? array[i].data.cloneNode(true)
            : Strophe.xmlTextNode(array[i].data)

        this.cnode(data);

        this.up();
    }
    return this;
};

Strophe.Builder.prototype.children = function (object: any) {
    let key, value;
    for (key in object) {
        if (!(key in object)) continue;
        value = object[key];
        if (Array.isArray(value)) {
            this.list(key, value);
        } else if (typeof value === 'string') {
            this.c(key, {}, value);
        } else if (typeof value === 'number') {
            this.c(key, {}, "" + value.toString());
        } else if (typeof value === 'object') {
            this.c(key).children(value).up();
        } else {
            this.c(key).up();
        }
    }
    return this;
};

export default StrophePubSub;

import { logger } from "src/helpers/logger";
import { type RosterItem } from "src/types/Search";
const { Strophe, $pres, $iq }: any = window

type Callback = (...args: any[]) => void;

class StropheRoster {
    connection: Strophe.Connection;
    callbacks: Callback[];
    requestCallbacks: Callback[];
    items: RosterItem[];
    ver: string;


    init(conn: Strophe.Connection): void {
        this.connection = conn;
        this.callbacks = [];
        this.requestCallbacks = [];

        let oldCallback: any;
        const _connect = conn.connect;
        const _attach = conn.attach;

        const newCallback = (status: Strophe.Status): void => {
            if (status === Strophe.Status.ATTACHED || status === Strophe.Status.CONNECTED) {
                try {
                    conn.addHandler(this._onReceivePresence.bind(this), '', 'presence');
                    conn.addHandler(this._onReceiveIQ.bind(this), Strophe.NS.ROSTER, 'iq', "set");
                }
                catch (e: any) {
                    console.error('StropheRoster', e);
                    Strophe.error(e);
                }
            }
            if (typeof oldCallback === "function") {
                oldCallback.apply(this, arguments);
            }
        }

        conn.connect = function (jid: any, pass: any, callback: any, wait: any, hold: any, route: any, authcid: any) {
            oldCallback = callback;
            if (typeof jid === "undefined")
                jid = null;
            if (typeof pass === "undefined")
                pass = null;
            callback = newCallback;
            _connect.apply(conn, [jid, pass, callback, wait, hold, route, authcid]);
        }

        conn.attach = function (jid: any, sid: any, rid: any, callback: any, wait: any, hold: any, wind: any) {
            oldCallback = callback;
            if (typeof jid === "undefined")
                jid = null;
            if (typeof sid === "undefined")
                sid = null;
            if (typeof rid === "undefined")
                rid = null;
            callback = newCallback;
            _attach.apply(conn, [jid, sid, rid, callback, wait, hold, wind]);
        };
    }

    /** Function: supportVersioning
     * return true if roster versioning is enabled on server
     */
    supportVersioning(): boolean {
        return (this.connection.features !== undefined && this.connection.features.getElementsByTagName('ver').length > 0);
    }

    get(userCallback: any, ver: any = null, items: any = []): string {
        const attrs: any = { xmlns: Strophe.NS.ROSTER };
        this.items = [];
        if (this.supportVersioning()) {
            // empty rev because i want an rev attribute in the result
            attrs.ver = ver ?? '';
            this.items = items ?? [];
        }
        const iq = $iq({ type: 'get', 'id': this.connection.getUniqueId('roster') }).c('query', attrs);
        return this.connection.sendIQ(iq,
            this._onReceiveRosterSuccess.bind(this, userCallback),
            this._onReceiveRosterError.bind(this, userCallback));
    }

    registerCallback(callback: any): void {
        this.callbacks.push(callback);
    }

    registerRequestCallback(callback: any): void {
        this.requestCallbacks.push(callback);
    }

    /** Function: findItem
     * Find item by JID
     *
     * Parameters:
     *     (String) jid
     */
    findItem(jid: any): any {
        if (typeof this.items !== 'object') return false
        return this.items.find((item: any) => item.jid === jid);
    }

    removeItem(jid: any): boolean {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i] !== undefined && this.items[i].jid === jid) {
                this.items.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    /** Function: subscribe
     * Subscribe presence
     *
     * Parameters:
     *     (String) jid
     *     (String) message (optional)
     *     (String) nick  (optional)
     */
    subscribe(jid: any, message: any = null, nick: any = null): void {
        const pres = $pres({ to: jid, type: "subscribe" });

        if (message !== null && message !== "") {
            pres.c("status").t(message).up();
        }

        if (nick !== null && nick !== "") {
            pres.c('nick', { 'xmlns': Strophe.NS.NICK }).t(nick).up();
        }
        this.connection.send(pres);
    }

    /** Function: unsubscribe
     * Unsubscribe presence
     *
     * Parameters:
     *     (String) jid
     *     (String) message
     */
    unsubscribe(jid: string, message: string | null = null): void {
        const pres = $pres({ to: jid, type: "unsubscribe" });
        if (message !== undefined && message !== "")
            pres.c("status").t(message);
        this.connection.send(pres);
    }

    /** Function: authorize
     * Authorize presence subscription
     *
     * Parameters:
     *     (String) jid
     *     (String) message
     */
    authorize(jid: any, message: any = undefined): void {
        const pres = $pres({ to: jid, type: "subscribed" });
        if (message !== undefined && message !== "")
            pres.c("status").t(message);
        this.connection.send(pres);
    }

    /** Function: unauthorize
     * Unauthorize presence subscription
     *
     * Parameters:
     *     (String) jid
     *     (String) message
     */
    unauthorize(jid: any, message: any): void {
        const pres = $pres({ to: jid, type: "unsubscribed" });
        if (message !== undefined && message !== "")
            pres.c("status").t(message);
        this.connection.send(pres);
    }

    /** Function: add
     * Add roster item
     *
     * Parameters:
     *   (String) jid - item jid
     *   (String) name - name
     *   (Array) groups
     *   (Function) call_back
     */
    add(jid: any, name: any, groups: any = [], callback: any = null): void {
        const iq = $iq({ type: 'set' })
            .c('query', { xmlns: Strophe.NS.ROSTER })
            .c('item', {
                jid,
                name
            });

        for (let i = 0; i < groups.length; i++) {
            iq.c('group').t(groups[i]).up();
        }

        this.connection.sendIQ(iq, callback, callback);
    }

    /** Function: update
     * Update roster item
     *
     * Parameters:
     *   (String) jid - item jid
     *   (String) name - name
     *   (Array) groups
     *   (Function) call_back
     */
    update(jid: any, name: any, groups: any = null, callback: any = null): string {
        const item = this.findItem(jid);
        if (item === false || item === undefined) {
            throw new Error("item not found");
        }
        const newName = name ?? item.name;
        const newGroups = groups ?? item.groups;
        const iq = $iq({ type: 'set' }).c('query', { xmlns: Strophe.NS.ROSTER }).c('item', {
            jid: item.jid,
            name: newName
        });
        for (let i = 0; i < newGroups.length; i++) {
            iq.c('group').t(newGroups[i]).up();
        }
        return this.connection.sendIQ(iq, callback, callback);
    }

    /** Function: remove
     * Remove roster item
     *
     * Parameters:
     *   (String) jid - item jid
     *   (Function) call_back
     */
    remove(jid: string, callback: any = null): void {
        console.log('removing roster', jid, this.items)

        // const item = this.findItem(jid);
        // if (item === false || item === undefined) {
        //     throw new Error("item not found");
        // }
        const iq = $iq({ type: 'set' }).c('query', { xmlns: Strophe.NS.ROSTER }).c('item', {
            jid,
            subscription: "remove"
        });
        this.connection.sendIQ(iq, callback, callback);
    }

    /** PrivateFunction: _onReceiveRosterSuccess
     *
     */
    _onReceiveRosterSuccess(userCallback: any, stanza: any): void {
        this._updateItems(stanza);
        this._call_backs(this.items);
        if (typeof userCallback === "function") {
            userCallback(this.items);
        }
    }

    /** PrivateFunction: _onReceiveRosterError
     *
     */
    _onReceiveRosterError(userCallback: any, stanza: any): void {
        userCallback(this.items);
    }

    /** PrivateFunction: _onReceivePresence
     * Handle presence
     */
    _onReceivePresence(presence: any): boolean {
        const jid = presence.getAttribute('from');
        const from = Strophe.getBareJidFromJid(jid);
        const item = this.findItem(from);
        const type = presence.getAttribute('type');

        // not in roster
        if (item === false || item === undefined) {
            // if 'friend request' presence
            if (type === 'subscribe') this._call_backs_request(from);
            return true;
        }

        // if (type === 'unavailable')
        //     this._rosterEvents.next({
        //         type: 'unavailable',
        //         jid: from
        //     })

        // else if (!type)
        //     this._rosterEvents.next({
        //         type: 'available',
        //         jid: from
        //     })

        this._call_backs(this.items, item);
        return true;
    }

    /** PrivateFunction: _call_backs_request
     * call all the callbacks waiting for 'friend request' presences
     */
    _call_backs_request(from: any): void {
        for (let i = 0; i < this.requestCallbacks.length; i++) // [].forEach my love ...
        {
            this.requestCallbacks[i](from);
        }
    }

    /** PrivateFunction: _call_backs
     * first parameter is the full roster
     * second is optional, newly added or updated item
     * third is otional, in case of update, send the previous state of the
     *  update item
     */
    _call_backs(items: any, item: any = null, previousItem: any = null): void {
        for (let i = 0; i < this.callbacks.length; i++) // [].forEach my love ...
        {
            this.callbacks[i](items, item, previousItem);
        }
    }

    /** PrivateFunction: _onReceiveIQ
     * Handle roster push.
     */
    _onReceiveIQ(iq: any): boolean {
        const id = iq.getAttribute('id');
        const from = iq.getAttribute('from');
        // Receiving client MUST ignore stanza unless it has no from or from = user's JID.
        if (from !== undefined && from !== "" && from !== this.connection.jid && from !== Strophe.getBareJidFromJid(this.connection.jid))
            return true;
        const iqresult = $iq({ type: 'result', id, from: this.connection.jid });
        this.connection.send(iqresult);
        this._updateItems(iq);
        return true;
    }

    /** PrivateFunction: _updateItems
     * Update items from iq
     */
    _updateItems(iq: any): void {
        const query = iq.getElementsByTagName('query');
        if (query.length !== 0) {
            this.ver = query.item(0).getAttribute('ver');
            Strophe.forEachChild(query.item(0), 'item',
                (item: any) => {
                    this._updateItem(item);
                }
            );
        }
    }

    /** PrivateFunction: _updateItem
     * Update internal representation of roster item
     */
    _updateItem(itemTag: any): void {
        const jid = itemTag.getAttribute("jid");
        const name = itemTag.getAttribute("name");
        const subscription = itemTag.getAttribute("subscription");
        const ask = itemTag.getAttribute("ask");
        const groups: any = [];

        Strophe.forEachChild(itemTag, 'group',
            function (group: any) {
                groups.push(Strophe.getText(group));
            }
        );

        let previousItem;

        if (subscription === "remove") {
            const hashBeenRemoved = this.removeItem(jid);
            if (hashBeenRemoved) {
                this._call_backs(
                    this.items,
                    { jid, subscription: 'remove' }
                );
            }
            return;
        }

        const item = this.findItem(jid);
        if (item === false || item === undefined) {
            const newItem = {
                name,
                jid,
                subscription,
                ask,
                groups,
                resources: {}
            };

            this.items = [
                ...this.items,
                newItem
            ]

            // if (this.items) {
            //     this.items.push(item);
            // }
        }
        else {
            previousItem = {
                name: item.name,
                subscription: item.subscription,
                ask: item.ask,
                groups: item.groups
            };

            // item.name = name;
            // item.subscription = subscription;
            // item.ask = ask;
            // item.groups = groups;

            let newItems = this.items.filter((i: any) => i.jid !== jid)
            newItems = [
                ...newItems,
                {
                    ...item,
                    name,
                    subscription,
                    ask,
                    groups
                }
            ]

            this.items = newItems

        }
        this._call_backs(this.items, item, previousItem);
    }
}

export default StropheRoster;

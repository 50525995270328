import { configureStore } from '@reduxjs/toolkit'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    persistStore,
} from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'

import { baseApi } from './api/base'

// import createSagaMiddleware from 'redux-saga'
// import conversationSaga from 'src/sagas/conversation'
import contacts from 'src/store/slices/contacts'
import conversation from './slices/conversation'
import user from './slices/user'
import createGroup from './slices/createGroup'
import createChannel from './slices/createChannel'
import selection from './slices/selection'
import call from './slices/call'
import app from './slices/app'
import { EventBus } from 'src/services/EventBus'
import { UupEvents } from 'src/constants/events'

const persistConfig = {
    key: 'uup',
    version: 1,
    storage: createIdbStorage({
        name: 'uup-db',
        storeName: 'uup-store'
    }),
    serialize: false,
    deserialize: false,
    blacklist: [
        'activeContact',
        'activeGroup',
        'loading',
        'uploadAbortController',
        'galleryItems',
        'mainGalleryItem',
        'threadGroupId',
        'threadMessageId',
        'rosterState',
    ]
}

const persistedUser = persistReducer(persistConfig, user)
const persistedContacts = persistReducer(persistConfig, contacts)

const middlewareOptions = {
    serializableCheck: {
        ignoredActions: [
            FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
        ]
    },
}

export const store = configureStore({
    // TODO: remove this before production
    devTools: true,

    reducer: {
        app,
        user: persistedUser,
        createGroup,
        createChannel,
        contacts: persistedContacts,
        conversation,
        selection,
        call,
        [baseApi.reducerPath]: baseApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(middlewareOptions).concat(baseApi.middleware)
})

export const storePersistor = persistStore(store, {}, () => {
    console.log('STORE', store.getState())
    EventBus.next({
        type: UupEvents.STORE_INITIALIZED
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

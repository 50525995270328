import { useCallback, useEffect, useState } from "react";
import { xmpp } from "src/constants/xmpp";
import { getNodeFromJid } from "src/helpers/contact";
import { generateTimestamp } from "src/helpers/message";
import { useAppDispatch, useContacts } from "src/hooks/store";
import API from "src/services/Api";
import { addGroupMember, setGroupAddMember } from "src/store/slices/contacts";
import { type Contact } from "src/types/Contact";
import { type GroupUser } from 'src/types/Group';
import { defaultAvatar } from 'src/constants/contact';
import ContactList from "../../Contact/List";
import HttpStatus from "src/constants/http";
function GroupAddMember(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const {
    activeGroup,
    contacts,
    groupAddMember
  } = useContacts();
  const [members, $members] = useState<Contact[]>([]);
  const close = useCallback(() => dispatch(setGroupAddMember(false)), [dispatch]);
  const onPressContact = useCallback((selectedContact: Contact) => {
    let newMembers = [...members];
    const contactItem = contacts.find(contact => contact.jid === selectedContact.jid);
    if (contactItem === undefined) return;
    const exists = newMembers.find(member => member.jid === selectedContact.jid);
    if (exists != null) newMembers = newMembers.filter(contact => contact.jid !== selectedContact.jid);else {
      const newItem = {
        ...contactItem,
        avatar: selectedContact.avatar
      };
      newMembers.push(newItem);
    }
    $members(newMembers);
  }, [contacts, members]);
  const inviteToGroup = useCallback((member: Contact) => {
    if (activeGroup === null) return;
    const id = getNodeFromJid(member.jid);
    const groupUser: GroupUser = {
      id,
      name: member.name,
      photo: member.avatar ?? defaultAvatar,
      affiliation: 'member'
    };
    const ts = generateTimestamp();
    xmpp.inviteToGroup(activeGroup.jid, member.jid, ts);
    dispatch(addGroupMember(groupUser));
  }, [activeGroup, dispatch]);
  const addMembers = useCallback(async () => {
    if (activeGroup === null) return;
    const id = getNodeFromJid(activeGroup.jid);
    const ids = members.map(member => getNodeFromJid(member.jid));
    const response = await API.post(`/muc/rooms/${id}/users`, {
      users: ids
    });
    if (response.status === HttpStatus.CREATED) {
      members.map(inviteToGroup);
      dispatch(setGroupAddMember(false));
    }
  }, [activeGroup, dispatch, inviteToGroup, members]);
  useEffect(() => {
    $members([]);
  }, [groupAddMember]);
  if (activeGroup === null || !groupAddMember) return null;
  return <div className="flex flex-col w-1/3 h-screen bg-accent z-50 border-l border-gray-100">
            <div className='mx-4 h-16 shrink-0 flex items-center'>
                <button onClick={close} className='transition hover:opacity-80'>
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25 7.27393L16.25 7.27393" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.2998 13.2985L1.2498 7.27451L7.2998 1.24951" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <span className='ml-5 font-bold text-xl'>Add Members to {activeGroup.name}</span>

                <button onClick={close} className='ml-auto transition hover:opacity-80'>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9959 1.00488L1.00586 12.9924" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13 13L1 1" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            <div className="w-full h-full overflow-y-scroll">
                <ContactList excludeGroups excludeIds={activeGroup.members?.map(member => member.id)} selectedContacts={members} onPressContact={onPressContact} />
            </div>
            <button onClick={addMembers} className="w-full py-3 bg-primary text-accent mt-auto transition hover:opacity-80">Add</button>
        </div>;
}
export default GroupAddMember;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from 'src/constants/api'
import { type RootState } from '..'

export const baseApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState, endpoint }) => {
            const token = (getState() as RootState).user.user?.apiToken
            const sessionId = (getState() as RootState).user.user?.sessionId
            if (token !== undefined)
                headers.set('Authorization', `Bearer ${token}`)

            if (sessionId !== undefined)
                headers.set('session-id', sessionId)

            return headers
        }
    }),
    endpoints: (builder) => ({}),
    tagTypes: ['SearchUser'],
})

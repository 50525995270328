import { clamp } from "src/helpers/common";
interface Props {
  emojis: {
    show: boolean;
    found: string[];
    selected: number | null;
  };
  caretPosition: number;
  onPressFoundEmoji: (emoji: string) => void;
}
function FoundEmojisPicker({
  emojis,
  caretPosition,
  onPressFoundEmoji
}: Props): JSX.Element | null {
  if (emojis.found.length === 0) return null;
  const leftPosition = clamp(caretPosition * 6.2 + 100, window.innerWidth - 600);
  return <div className={`absolute w-fit bg-white rounded p-1 bottom-14 z-[100]`} style={{
    left: leftPosition
  }}>
            {emojis.found.map((emoji: string, index: number) => {
      const isSelected = index === emojis.selected;
      return <button key={index} tabIndex={index} className={`cursor-pointer transition hover:bg-gray-100 rounded w-6 h-6 m-1 ${isSelected ? 'bg-blue-600' : ''}`} onClick={() => {
        onPressFoundEmoji(emoji);
      }}>
                    {emoji}
                </button>;
    })}
        </div>;
}
export default FoundEmojisPicker;
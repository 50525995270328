import { createSlice } from '@reduxjs/toolkit'
import { type User } from 'src/types/User';
import reducers from './reducers';

export interface UserState {
    user: User | null;
}

export const initialState: UserState = {
    user: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    setUser,
    setUserData,
    resetUserState
} = userSlice.actions

export default userSlice.reducer

import { MessageType } from "src/types/Ejabberd/MessageType";
import { type DecryptedMessage } from "src/types/Message";
import ContactMessage from "../ContactMessage";
import ImageMessage from "../ImageMessage";
import LocationShare from "../LocationShare";
import LiveLocationShare from "../LiveLocationShare";
import TextMessage from "../TextMessage";
import VideoMessage from "../VideoMessage";
import VoiceMessage from "../VoiceMessage";
import GroupEventMessage from "../GroupEvent";
import FileMessage from "../FileMessage";
import GIFMessage from "../GIFMessage";
import CallEventMessage from "../CallEventMessage";
interface Props {
  message: DecryptedMessage;
}
function Message({
  message
}: Props): JSX.Element {
  const {
    type
  } = message;
  if (type === MessageType.VOICE) return <VoiceMessage message={message} />;else if (type === MessageType.IMAGE) return <ImageMessage message={message} />;else if (type === MessageType.GIF) return <GIFMessage message={message} />;else if (type === MessageType.VIDEO) return <VideoMessage message={message} />;else if (type === MessageType.FILE) return <FileMessage message={message} />;else if (type === MessageType.LOCATION) return <LocationShare message={message} />;else if (type === MessageType.LIVE_LOCATION) return <LiveLocationShare message={message} />;else if (type === MessageType.CONTACT) return <ContactMessage message={message} />;else if (type === MessageType.TEXT) return <TextMessage message={message} />;else if (type === MessageType.GROUP_EVENT) return <GroupEventMessage message={message} />;else if (type === MessageType.CALL_EVENT) return <CallEventMessage message={message} />;else return <div>{JSON.stringify(message)}</div>;
}
export default Message;
import { type QueueTask } from "src/types/queue";

export class SyncQueue {
    public queue: QueueTask[] = [];

    async enqueue(fn: () => void, args?: any): Promise<any> {
        this.validate(fn, args);

        const shouldRun = this.queue.length === 0;
        const task = this.createTask(fn, args);

        this.queue.push(task);

        if (shouldRun) {
            this.run();
        }

        return await task.promise;
    }

    validate(fn: any, args?: any): void {
        if (typeof fn !== 'function') {
            throw new Error('Please provide a function to enqueue.');
        }

        if (args !== undefined && !Array.isArray(args)) {
            throw new Error('Please provide task arguments as an array to enqueue.');
        }
    }

    run(): void {
        const task = this.queue[0];

        Promise.resolve(task.fn.apply(undefined, (task.args != null) || []))
            .then(task.resolve)
            .catch(task.reject)
            .then(() => { this.handleResult(); })
            .catch(task.reject);
    }

    handleResult(): void {
        this.queue.shift();

        if (this.queue.length === 0) {
            return;
        }

        this.run();
    }

    createTask(fn: any, args?: any): QueueTask {
        let _resolve: (value?: any) => void = () => { };
        let _reject: (reason?: any) => void = () => { };

        const promise = new Promise(function (resolve, reject) {
            _resolve = resolve;
            _reject = reject;
        });

        return {
            fn,
            args,
            resolve: _resolve,
            reject: _reject,
            promise
        };
    }
}



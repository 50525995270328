import { useCallback } from "react";
import { useAppDispatch } from "src/hooks/store";
import { setCreateGroupActive } from "src/store/slices/createGroup";
interface Props {
  onClick: () => void;
}
function NewGroupChatButton({
  onClick
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const toggleCreateGroup = useCallback(() => {
    dispatch(setCreateGroupActive(true));
  }, [dispatch]);
  return <div onClick={onClick} className={`group flex items-center px-3 py-2 my-4 transition hover:bg-hover-bg cursor-pointer [content-visibility: auto]`}>
            <div className='w-16 h-16 rounded-xl shrink-0 cover bg-primary center-flex'>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 3.61543C8.26472 3.61543 3.61537 8.26478 3.61537 14C3.61537 19.7353 8.26472 24.3847 14 24.3847C19.7352 24.3847 24.3846 19.7353 24.3846 14C24.3846 8.26478 19.7352 3.61543 14 3.61543ZM1.30768 14C1.30768 6.99028 6.99022 1.30774 14 1.30774C21.0098 1.30774 26.6923 6.99028 26.6923 14C26.6923 21.0098 21.0098 26.6924 14 26.6924C6.99022 26.6924 1.30768 21.0098 1.30768 14Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M14 8.23077C14.6373 8.23077 15.1538 8.74737 15.1538 9.38462V18.6154C15.1538 19.2526 14.6373 19.7692 14 19.7692C13.3628 19.7692 12.8462 19.2526 12.8462 18.6154V9.38462C12.8462 8.74737 13.3628 8.23077 14 8.23077Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.23077 14C8.23077 13.3628 8.74737 12.8462 9.38462 12.8462H18.6154C19.2526 12.8462 19.7692 13.3628 19.7692 14C19.7692 14.6373 19.2526 15.1539 18.6154 15.1539H9.38462C8.74737 15.1539 8.23077 14.6373 8.23077 14Z" fill="white" />
                </svg>
            </div>

            <div onClick={toggleCreateGroup} className="flex flex-col w-full ml-3">
                <div className="flex justify-between">
                    <span className='text-primary font-semibold'>New group chat</span>
                </div>
            </div>
        </div>;
}
export default NewGroupChatButton;
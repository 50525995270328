import { useMemo, useCallback } from 'react';
import moment from 'moment';
import { type DecryptedMessage } from 'src/types/Message';
import { parseTimestamp } from 'src/helpers/message';
import { useAppDispatch, useContacts } from 'src/hooks/store';
import { setForwarding, setGroupForwarding, setGroupReplying, setReplying } from 'src/store/slices/conversation';
import { getNodeFromJid } from 'src/helpers/contact';
import { addDeletedMessage, setActiveContact, setGroupThread } from 'src/store/slices/contacts';
import ContextMenu, { type ContextMenuItem } from 'src/components/ContextMenu';
import Message from '../Message';
import { type GroupUser } from 'src/types/Group';
import clsx from 'clsx';
import { MessageType } from 'src/types/Ejabberd/MessageType';
import { xmpp } from 'src/constants/xmpp';
interface Props {
  message: DecryptedMessage;
  groupMember?: GroupUser;
  onReplyMessageClicked: (messageId: string) => void;
}
function ReceivedMessage({
  groupMember,
  message,
  onReplyMessageClicked
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    contacts,
    activeContact,
    activeGroup,
    messages
  } = useContacts();
  const {
    timestamp,
    quotedMessageId
  } = message;
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const threadMessages = useMemo(() => {
    if (activeGroup === null) return [];
    const currentGroupMessages = messages[getNodeFromJid(activeGroup.jid)];
    return currentGroupMessages.filter(msg => msg.thid === message.id);
  }, [activeGroup, messages, message.id]);
  const showThreadMessages = useCallback(() => {
    if (activeGroup === null) return;
    console.log('showThreadMessages', message.id, activeGroup.jid);
    dispatch(setGroupThread({
      messageId: message.id,
      groupId: activeGroup.jid
    }));
  }, [activeGroup, dispatch, message.id]);
  const handleReply = useCallback(() => {
    if (activeContact !== null) {
      dispatch(setReplying({
        contact: activeContact,
        message
      }));
    } else if (activeGroup !== null) {
      dispatch(setGroupReplying({
        contact: activeGroup,
        message
      }));
    }
  }, [activeContact, activeGroup, dispatch, message]);
  const handleForward = useCallback(() => {
    if (activeContact !== null) {
      dispatch(setForwarding({
        from: activeContact,
        message,
        contacts: []
      }));
    } else if (activeGroup !== null) {
      dispatch(setGroupForwarding({
        from: activeGroup,
        message,
        contacts: []
      }));
    }
  }, [activeContact, activeGroup, dispatch, message]);
  const handleDelete = useCallback(() => {
    if (activeContact !== null) {
      dispatch(addDeletedMessage({
        jid: activeContact.jid,
        messageId: message.id
      }));
    } else if (activeGroup !== null) {
      dispatch(addDeletedMessage({
        jid: activeGroup.jid,
        messageId: message.id
      }));
    }
  }, [activeContact, activeGroup, dispatch, message.id]);
  const renderQuotedMessage = useMemo(() => {
    let findMessage: DecryptedMessage | null | undefined = null;
    let userName = null;
    if (activeContact !== null) {
      const jid = getNodeFromJid(activeContact.jid);
      findMessage = messages[jid]?.find(message => message.id === quotedMessageId);
      if (findMessage === undefined) return;
      userName = getNodeFromJid(findMessage.fromId) === getNodeFromJid(activeContact.jid) ? activeContact.name : 'Me';
    } else if (activeGroup !== null) {
      const groupId = getNodeFromJid(activeGroup.jid);
      findMessage = messages[groupId].find(message => message.id === quotedMessageId);
      if (findMessage === undefined) return;
      const fromId = getNodeFromJid(findMessage.fromId);
      if (fromId === getNodeFromJid(xmpp.connection.jid)) userName = 'Me';else userName = activeGroup.members?.find(member => getNodeFromJid(member.id) === fromId)?.name;
    }
    return <div className='p-1 pb-0 text-2xs cursor-pointer' onClick={() => {
      onReplyMessageClicked(findMessage?.id ?? "");
    }}>
            <div className='border-l-4 border-l-primary-light p-1 bg-gray-200 text-primary flex flex-col'>
                <span className='font-semibold'>{userName}</span>
                <span className='text-primary-light'>{findMessage?.content ?? findMessage?.type}</span>
            </div>
        </div>;
  }, [activeContact, activeGroup, messages, onReplyMessageClicked, quotedMessageId]);
  const onClickMember = useCallback(() => {
    if (groupMember === undefined) return;
    const jid = getNodeFromJid(groupMember.id);
    const findContact = contacts.find(contact => getNodeFromJid(contact.jid) === jid && contact.isHidden !== true);
    if (findContact === undefined) {
      // TODO:
      alert('User not found');
      return;
    }
    dispatch(setActiveContact(findContact));
  }, [contacts, dispatch, groupMember]);
  const renderFrom = useMemo(() => {
    if (groupMember === undefined) return;
    return <div onClick={onClickMember} className={clsx('px-4 cursor-pointer', message.type === MessageType.TEXT ? 'pt-3' : 'py-3')}>
                <span className='text-sm text-hover-text'>{groupMember.name}</span>
            </div>;
  }, [groupMember, message.type, onClickMember]);
  const contextItems: ContextMenuItem[] = [{
    label: 'Reply',
    onClick: handleReply
  }, {
    label: 'Forward',
    onClick: handleForward
  }, {
    label: 'Delete',
    onClick: handleDelete
  }];
  if (activeGroup !== null) contextItems.push({
    label: 'Reply in Thread',
    onClick: showThreadMessages
  });
  if (message.isDeleted === true) return <div className='bg-primary shadow flex flex-col text-base my-1 w-fit'>
            <div className='flex items-center justify-between text-base w-full rounded-sm pl-2 pr-1 py-2'>
                <span className='text-gray-200 italic'>Message was deleted</span>
                <div className="relative -bottom-1 ml-2 self-end text-2xs">
                    <span className='relative text-gray-400'>{formattedTimestamp}</span>
                </div>
            </div>
        </div>;
  return <ContextMenu id={`msg-${message.id}`} className='bg-accent w-fit max-w-2/3 lg:max-w-1/2 shadow overflow-hidden flex rounded-tl-xl rounded-tr-xl rounded-br-xl flex-col text-sm my-2' width={48} items={contextItems}>
            {quotedMessageId !== undefined && renderQuotedMessage}
            {renderFrom}
            <Message message={message} />
            {threadMessages.length > 0 && <button className='bg-white text-2xs' onClick={showThreadMessages}>
                <div className='border-l-4 border-l-primary-light p-1 bg-gray-200 text-primary'>
                    <span className='font-semibold text-hover-text'>View {threadMessages.length} replies</span>
                </div>
            </button>}
        </ContextMenu>;
}
export default ReceivedMessage;
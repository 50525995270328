import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Modal from "../Modal";
import OutsideClickDetector from "../OutsideClickDetector";
import { EventBus, onEvent } from "src/services/EventBus";
import { UupEvents } from "src/constants/events";
import { v4 as uuidv4 } from 'uuid';
export interface ContextMenuItem {
  label: string;
  danger?: boolean;
  disabled?: boolean;
  onClick: () => void;
}
interface Props {
  width: number;
  children: React.ReactNode;
  className?: string;
  items: ContextMenuItem[];
  callback?: (ev: MouseEvent) => void;
  [key: string]: any;
}
function ContextMenu({
  children,
  width,
  callback,
  className,
  items,
  ...rest
}: Props): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const [show, $show] = useState<React.ReactNode>(false);
  const [position, $position] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0
  });
  const id = useMemo(() => uuidv4(), []);
  const xDiff = width * 4;
  const ydiff = items.length * 40;
  useEffect(() => {
    const subscribe = onEvent(UupEvents.CLOSE_CONTEXT_EXCEPT, (payload: string) => {
      if (show === true && payload !== id) $show(false);
    });
    if (show === true) EventBus.next({
      type: UupEvents.CLOSE_CONTEXT_EXCEPT,
      payload: id
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, [show, id]);
  const onContextMenu = useCallback((ev: MouseEvent): any => {
    ev.preventDefault();
    const {
      clientX,
      clientY
    } = ev;
    const maxXPoint = window.innerWidth - xDiff - 20;
    const maxYPoint = window.innerHeight - ydiff - 20;
    const x = clientX > maxXPoint ? maxXPoint : clientX;
    const y = clientY > maxYPoint ? maxYPoint : clientY;
    $position({
      x,
      y
    });
    $show(true);
    if (callback != null) callback(ev);
  }, [callback, xDiff, ydiff]);
  useEffect(() => {
    let currentContainerRef: HTMLDivElement | null = null;
    if (containerRef.current !== null) {
      currentContainerRef = containerRef.current;
      containerRef.current.addEventListener('contextmenu', onContextMenu);
    }
    return () => {
      if (currentContainerRef !== null) currentContainerRef.removeEventListener('contextmenu', onContextMenu);
    };
  }, [onContextMenu]);
  const renderMenu = useMemo(() => <div className='w-full bg-white rounded-lg shadow overflow-hidden'>
            {items.map((item, index) => <button key={index} className={clsx(item.disabled === true ? 'text-gray-500' : item.danger === true ? 'text-red-500' : 'text-sky-500', 'relative z-100 w-full px-4 py-2 h-10 text-left text-sm hover:bg-gray-100 transition')} onClick={item.onClick} data-dismiss>
                    {item.label}
                </button>)}
        </div>, [items]);
  const renderContextMenu = <OutsideClickDetector onClickOutside={() => {
    $show(false);
  }} className={clsx('absolute z-100', `w-${width}`)} style={{
    top: position.y,
    left: position.x
  }}>
            {renderMenu}
        </OutsideClickDetector>;
  return <>
            {show === true && <Modal>{renderContextMenu}</Modal>}
            <div ref={containerRef} className={className} {...rest}>
                {children}
            </div>
        </>;
}
export default ContextMenu;
export enum UupEvents {
    NEW_MESSAGE = 'NEW_MESSAGE',
    NEW_GROUP_MESSAGE = 'NEW_GROUP_MESSAGE',
    CLOSE_CONTEXT_EXCEPT = 'CLOSE_CONTEXT_EXCEPT',
    CONTACTS_LOADED = 'CONTACTS_LOADED',
    CANCEL_UPLOAD = 'CANCEL_UPLOAD',
    STORE_INITIALIZED = 'STORE_INITIALIZED',
    ADD_MISSING_VIRTUAL_CARD = 'ADD_MISSING_VIRTUAL_CARD',
    CALL_ENDED = 'CALL_ENDED',
    GROUP_CALL_ENDED = 'GROUP_CALL_ENDED',
    FETCH_LAST_SEEN = 'FETCH_LAST_SEEN',
}

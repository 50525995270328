import React, { createContext } from 'react'
import type WaveSurfer from 'wavesurfer.js/src/wavesurfer';

export interface ActiveMediaState {
    activeMedia: WaveSurfer | null;
    setActiveMedia: React.Dispatch<React.SetStateAction<WaveSurfer | null>>;
}

export const ActiveMediaContext = createContext<ActiveMediaState>({ activeMedia: null, setActiveMedia: () => { } });
export const useActiveMedia = (): ActiveMediaState => React.useContext<ActiveMediaState>(ActiveMediaContext);

import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
// import { setGroupAddMember, setGroupMenu } from "src/store/slices/contacts";
import Checkbox from "../Checkbox";
import { clearMessages, setShowContactInfo } from "src/store/slices/contacts";
import Modal from "../Modal";
import ClearChatModal from "../UI/Modals/ClearChat";
import { useKeyboardListener } from "src/hooks/useKeyboardListener";
import { type Contact } from "src/types/Contact";
import LoadingImage from "../LoadingImage";
import { type CallData, CallFormats, CallTypes } from "src/types/call";
import { getNodeFromJid } from "src/helpers/contact";
import { initializeContactCall } from "src/store/slices/call";
function ContactInfo(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const {
    activeContact,
    showContactInfo
  } = useAppSelector(state => state.contacts);
  const [confirmClearChat, $confirmClearChat] = useState(false);
  const getCommonGroups = useCallback<() => Contact[]>(() => {
    return [];
  }, []);
  const close = useCallback(() => {
    dispatch(setShowContactInfo(false));
  }, [dispatch]);
  const clearChat = useCallback(() => {
    $confirmClearChat(false);
    if (activeContact === null) return;
    dispatch(clearMessages(activeContact.jid));
  }, [activeContact, dispatch]);
  const callViaVoice = useCallback(async () => {
    if (activeContact === null) return;
    const callData: CallData = {
      format: CallFormats.VOICE,
      type: CallTypes.OUTGOING,
      caller: {
        id: getNodeFromJid(activeContact.jid),
        username: activeContact.name
      },
      channelId: null,
      callId: null
    };
    dispatch(initializeContactCall(callData));
  }, [activeContact, dispatch]);
  const callViaVideo = useCallback(async () => {
    if (activeContact === null) return;
    const callData: CallData = {
      format: CallFormats.VIDEO,
      type: CallTypes.OUTGOING,
      caller: {
        id: getNodeFromJid(activeContact.jid),
        username: activeContact.name
      },
      channelId: null,
      callId: null
    };
    dispatch(initializeContactCall(callData));
  }, [activeContact, dispatch]);

  // const renderCommonGroups = useMemo(() => (
  //     <div className="w-full flex flex-col">
  //         {getCommonGroups().map(group => (
  //             <div key={group.id} className='bg-gray-100 border-b flex justify-between items-center px-3 py-2 transition hover:bg-gray-200'>
  //                 <div className="flex items-center">
  //                     <img src={group.photo} alt="" className='w-8 h-8 rounded-full' />
  //                     <span className='ml-2'>{group.name}</span>
  //                 </div>
  //             </div>
  //         ))}
  //     </div>
  // ), [getCommonGroups])

  useKeyboardListener('Escape', () => {
    $confirmClearChat(false);
  });
  if (activeContact === null || !showContactInfo) return null;
  return <div className="relative shadow flex flex-col w-1/3 h-screen bg-accent z-50 border-l border-accent">

            {confirmClearChat && <Modal>
                <ClearChatModal onClose={() => {
        $confirmClearChat(false);
      }} callback={clearChat} />
            </Modal>}

            <div className="w-full pt-5 px-5 flex items-center justify-between">
                <div className="flex items-center">
                    <span className="font-semibold text-lg">Contact info</span>
                </div>

                <button onClick={close}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9959 1.005L1.00586 12.9925" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13 13.0002L1 1.00018" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            <div className="flex flex-col items-center justify-center w-full mt-4">
                <LoadingImage url={activeContact.avatar} alt="avatar" className='rounded-full w-28 h-28 object-cover' spinnerSize='28' />
                <span className="text-xl font-semibold mt-4">{activeContact.name}</span>
            </div>

            <div className="flex justify-center mt-4 items-center">
                <button onClick={callViaVoice} className='w-12 h-12 flex items-center justify-center rounded-lg mx-1 transition bg-primary hover:opacity-80'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M13.353 1.5C17.054 1.911 19.978 4.831 20.393 8.532" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.353 5.04297C15.124 5.38697 16.508 6.77197 16.853 8.54297" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0315 11.4724C14.0205 15.4604 14.9254 10.8467 17.4653 13.3848C19.9138 15.8328 21.3222 16.3232 18.2188 19.4247C17.8302 19.737 15.3613 23.4943 6.68447 14.8197C-1.99341 6.144 1.76157 3.67244 2.07394 3.28395C5.18377 0.173846 5.66682 1.58938 8.11539 4.03733C10.6541 6.5765 6.04254 7.48441 10.0315 11.4724Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>

                <button onClick={callViaVideo} className='w-12 h-12 flex items-center justify-center rounded-lg mx-1 transition bg-primary hover:opacity-80'>
                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M15.3 5.97984L18.593 3.28484C19.409 2.61684 20.633 3.19884 20.632 4.25184L20.62 11.6008C20.619 12.6538 19.394 13.2308 18.58 12.5628L15.3 9.86784" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.2969 11.5383C15.3778 13.3704 13.8992 14.9196 11.9946 14.9975C11.8543 15.0034 5.01531 14.9896 5.01531 14.9896C3.11996 15.1335 1.46115 13.7715 1.31165 11.9463C1.30039 11.8103 1.30346 4.47219 1.30346 4.47219C1.21949 2.63815 2.69604 1.08499 4.60163 1.00418C4.74396 0.997278 11.5738 1.01009 11.5738 1.01009C13.4783 0.868176 15.1423 2.24001 15.2897 4.07405C15.3 4.2061 15.2969 11.5383 15.2969 11.5383Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            <span className="mx-5 mt-8 text-sm text-hover-text">{getCommonGroups().length} groups in common</span>

            <div className="flex flex-col my-4">
                <div className="flex items-center justify-between px-5 mb-2">
                    <span className="text-sm font-semibold text-primary">Mute chat</span>
                    <Checkbox onChange={checked => {
          console.log(checked);
        }} />
                </div>
                <div className="flex items-center justify-between px-5">
                    <span className="text-sm font-semibold text-primary">Notifications</span>
                    <Checkbox checked onChange={checked => {
          console.log(checked);
        }} />
                </div>
            </div>

            <div className="flex flex-col my-4">

                <button onClick={() => {
        $confirmClearChat(true);
      }} className="clickable bg-contact-action-bg flex items-center mx-5 p-3 rounded-xl shadow">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.0835 6.49654C2.0835 5.89405 2.57191 5.40563 3.17441 5.40563H22.8108C23.4133 5.40563 23.9017 5.89405 23.9017 6.49654C23.9017 7.09903 23.4133 7.58745 22.8108 7.58745H3.17441C2.57191 7.58745 2.0835 7.09903 2.0835 6.49654Z" fill="#CC3169" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8108 3.22381C10.5214 3.22381 10.244 3.33875 10.0394 3.54333C9.8348 3.74792 9.71986 4.02539 9.71986 4.31472V5.40563H16.2653V4.31472C16.2653 4.02539 16.1504 3.74792 15.9458 3.54333C15.7412 3.33875 15.4637 3.22381 15.1744 3.22381H10.8108ZM18.4471 5.40563V4.31472C18.4471 3.44674 18.1023 2.61431 17.4886 2.00055C16.8748 1.3868 16.0424 1.04199 15.1744 1.04199H10.8108C9.94279 1.04199 9.11036 1.3868 8.4966 2.00055C7.88285 2.61431 7.53804 3.44674 7.53804 4.31472V5.40563H5.35622C4.75373 5.40563 4.26531 5.89405 4.26531 6.49654V21.7693C4.26531 22.6372 4.61012 23.4697 5.22387 24.0834C5.83763 24.6972 6.67006 25.042 7.53804 25.042H18.4471C19.3151 25.042 20.1475 24.6972 20.7613 24.0834C21.3751 23.4697 21.7199 22.6372 21.7199 21.7693V6.49654C21.7199 5.89405 21.2314 5.40563 20.629 5.40563H18.4471ZM6.44713 7.58745V21.7693C6.44713 22.0586 6.56207 22.3361 6.76665 22.5407C6.97124 22.7452 7.24872 22.8602 7.53804 22.8602H18.4471C18.7365 22.8602 19.0139 22.7452 19.2185 22.5407C19.4231 22.3361 19.538 22.0586 19.538 21.7693V7.58745H6.44713Z" fill="#CC3169" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8108 10.8602C11.4133 10.8602 11.9017 11.3486 11.9017 11.9511V18.4965C11.9017 19.099 11.4133 19.5874 10.8108 19.5874C10.2083 19.5874 9.71986 19.099 9.71986 18.4965V11.9511C9.71986 11.3486 10.2083 10.8602 10.8108 10.8602Z" fill="#CC3169" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M15.1744 10.8602C15.7769 10.8602 16.2653 11.3486 16.2653 11.9511V18.4965C16.2653 19.099 15.7769 19.5874 15.1744 19.5874C14.5719 19.5874 14.0835 19.099 14.0835 18.4965V11.9511C14.0835 11.3486 14.5719 10.8602 15.1744 10.8602Z" fill="#CC3169" />
                    </svg>
                    <span className="text-contact-action-text font-semibold text-md ml-4">Clear chat</span>
                </button>
            </div>
        </div>;
}
export default ContactInfo;
import { type DecryptedMessage } from "src/types/Message";
interface Props {
  message: DecryptedMessage;
  defaultColor?: string;
}
function MessageDeliveryTicks({
  message,
  defaultColor
}: Props): JSX.Element | null {
  const {
    received,
    isReceived,
    isDelivered,
    isRead
  } = message;
  if (received) return null;
  if (isDelivered !== true && isRead !== true) return <div className="ml-2">
            <svg width={10} height={10} xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 502 511.82">
                <path fillRule="nonzero" fill="#fff" d="M279.75 471.21c14.34-1.9 25.67 12.12 20.81 25.75-2.54 6.91-8.44 11.76-15.76 12.73a260.727 260.727 0 0 1-50.81 1.54c-62.52-4.21-118.77-31.3-160.44-72.97C28.11 392.82 0 330.04 0 260.71 0 191.37 28.11 128.6 73.55 83.16S181.76 9.61 251.1 9.61c24.04 0 47.47 3.46 69.8 9.91a249.124 249.124 0 0 1 52.61 21.97l-4.95-12.96c-4.13-10.86 1.32-23.01 12.17-27.15 10.86-4.13 23.01 1.32 27.15 12.18L428.8 68.3a21.39 21.39 0 0 1 1.36 6.5c1.64 10.2-4.47 20.31-14.63 23.39l-56.03 17.14c-11.09 3.36-22.8-2.9-26.16-13.98-3.36-11.08 2.9-22.8 13.98-26.16l4.61-1.41a210.71 210.71 0 0 0-41.8-17.12c-18.57-5.36-38.37-8.24-59.03-8.24-58.62 0-111.7 23.76-150.11 62.18-38.42 38.41-62.18 91.48-62.18 150.11 0 58.62 23.76 111.69 62.18 150.11 34.81 34.81 81.66 57.59 133.77 61.55 14.9 1.13 30.23.76 44.99-1.16zm-67.09-312.63c0-10.71 8.69-19.4 19.41-19.4 10.71 0 19.4 8.69 19.4 19.4V276.7l80.85 35.54c9.8 4.31 14.24 15.75 9.93 25.55-4.31 9.79-15.75 14.24-25.55 9.93l-91.46-40.2c-7.35-2.77-12.58-9.86-12.58-18.17V158.58zm134.7 291.89c-15.62 7.99-13.54 30.9 3.29 35.93 4.87 1.38 9.72.96 14.26-1.31 12.52-6.29 24.54-13.7 35.81-22.02 5.5-4.1 8.36-10.56 7.77-17.39-1.5-15.09-18.68-22.74-30.89-13.78a208.144 208.144 0 0 1-30.24 18.57zm79.16-69.55c-8.84 13.18 1.09 30.9 16.97 30.2 6.21-.33 11.77-3.37 15.25-8.57 7.86-11.66 14.65-23.87 20.47-36.67 5.61-12.64-3.13-26.8-16.96-27.39-7.93-.26-15.11 4.17-18.41 11.4-4.93 10.85-10.66 21.15-17.32 31.03zm35.66-99.52c-.7 7.62 3 14.76 9.59 18.63 12.36 7.02 27.6-.84 29.05-14.97 1.33-14.02 1.54-27.9.58-41.95-.48-6.75-4.38-12.7-10.38-15.85-13.46-6.98-29.41 3.46-28.34 18.57.82 11.92.63 23.67-.5 35.57zM446.1 177.02c4.35 10.03 16.02 14.54 25.95 9.96 9.57-4.4 13.86-15.61 9.71-25.29-5.5-12.89-12.12-25.28-19.69-37.08-9.51-14.62-31.89-10.36-35.35 6.75-.95 5.03-.05 9.94 2.72 14.27 6.42 10.02 12 20.44 16.66 31.39z" />
            </svg>
        </div>;
  return <div className="ml-2">
        <svg width="13" height="7" viewBox="0 0 13 7" fill="none">
            {(isDelivered === true || isRead === true) && <path fill={isRead === true ? '#3b82f6' : defaultColor ?? 'white'} fillRule="evenodd" clipRule="evenodd" d="M9.32431 0.165993C9.5548 0.391082 9.55918 0.760402 9.33409 0.990893L3.63748 6.82422C3.5227 6.94177 3.36367 7.00545 3.19948 6.99963C3.03529 6.99381 2.88117 6.91903 2.77499 6.79365L0.304943 3.87699C0.0967394 3.63114 0.127257 3.26305 0.373107 3.05485C0.618956 2.84665 0.987039 2.87716 1.19524 3.12301L3.25085 5.5503L8.49941 0.175774C8.7245 -0.0547165 9.09382 -0.0590958 9.32431 0.165993Z" />}
            {(isReceived === true || isRead === true) && <path fill={isRead === true ? '#3b82f6' : defaultColor ?? 'white'} fillRule="evenodd" clipRule="evenodd" d="M12.6576 0.165993C12.8881 0.391082 12.8924 0.760402 12.6673 0.990893L6.97073 6.82422C6.85595 6.94177 6.69692 7.00545 6.53273 6.99963C6.36854 6.99381 6.21442 6.91903 6.10824 6.79365L3.6382 3.87699C3.42999 3.63114 3.46051 3.26305 3.70636 3.05485C3.95221 2.84665 4.32029 2.87716 4.52849 3.12301L6.5841 5.5503L11.8327 0.175774C12.0577 -0.0547165 12.4271 -0.0590958 12.6576 0.165993Z" />}
        </svg>
    </div>;
}
export default MessageDeliveryTicks;
import { RecordRTCPromisesHandler, StereoAudioRecorder } from "recordrtc";

export class RTCAudioRecorderService {
    private stream: MediaStream;
    private recorder: RecordRTCPromisesHandler;

    async start(): Promise<void> {
        if (typeof navigator.mediaDevices === 'undefined' || typeof navigator.mediaDevices.getUserMedia === 'undefined') {
            await Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
        }

        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.recorder = new RecordRTCPromisesHandler(this.stream, {
            type: 'audio',
            mimeType: 'audio/wav',
            desiredSampRate: 16000,
            timeSlice: 1000,
            ondataavailable: (blob) => {
                console.log("RTC AUDIO BLOB", blob)
            },
            recorderType: StereoAudioRecorder,
            numberOfAudioChannels: 1
        });
        await this.recorder.startRecording();
    }


    async stop(): Promise<Blob> {
        await this.recorder.stopRecording();
        const blob = await this.recorder.getBlob();

        this.stream.getTracks().forEach(track => {
            track.stop()
        });
        return blob
    }

    async pause(): Promise<void> {
        await this.recorder.pauseRecording()
    }

    async play(): Promise<void> {
        await this.recorder.resumeRecording()
    }

    async cancel(): Promise<void> {
        await this.stop()
        await this.recorder.reset()
    }

}

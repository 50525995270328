import { type PayloadAction } from "@reduxjs/toolkit"
import { initialState, type SelectionState } from ".."

const reducers = {
    addSelectedMessage: (state: SelectionState, action: PayloadAction<string>) => {
        if (state.selectedMessages.includes(action.payload)) return

        state.selectedMessages.push(action.payload)
    },
    removeSelectedMessage: (state: SelectionState, action: PayloadAction<string>) => {
        state.selectedMessages = state.selectedMessages.filter((id) => id !== action.payload)
    },
    resetSelection: (state: SelectionState) => {
        return initialState
    }
}

export default reducers

import { type BaseMessage } from 'src/types/Ejabberd/XMPPMessage';
import { type EncryptedMessage } from 'src/types/Ejabberd/Message';

import { xmlToJson } from 'src/helpers/xmpp';
const { Strophe } = window

export class MessageConvertorService {

    convertXMPPMessageToMessage(messageXml: Element, jid: any, isGroup: any): EncryptedMessage {
        const baseMessage: BaseMessage = xmlToJson(messageXml);
        const message: any = {}

        message.messageId = baseMessage.result.id;
        message.content = baseMessage.result.forwarded.message.body;
        message.toId = baseMessage.result.forwarded.message.to;
        message.fromId = baseMessage.result.forwarded.message.from;
        message.group = isGroup;
        message.date = baseMessage.result.forwarded.delay.stamp;
        if (Strophe.getBareJidFromJid(message.fromId) === Strophe.getBareJidFromJid(jid)) {
            message.isSent = true;
        }
        // Todo: add other fields
        return message;

    }
}

import clsx from "clsx";
import { useCallback } from "react";
import { initializeMessaging } from "src/helpers/app";
import { useAppDispatch, useUser } from "src/hooks/store";
interface Props {
  onClose: () => void;
}
function RequestPermissionModal({
  onClose
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    user
  } = useUser();
  const request = useCallback(async () => {
    await initializeMessaging(user, dispatch, true);
    onClose();
  }, [dispatch, onClose, user]);
  const close = useCallback(() => {
    localStorage.setItem('ask-for-notification-permission', 'denied');
    onClose();
  }, [onClose]);
  return <div className={clsx('absolute top-8 right-4 z-100 animate-enter w-fit bg-white shadow-lg rounded-lg pointer-events-auto flex items-center ring-1 ring-black ring-opacity-5')}>
            <div className="p-4">
                <svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 13H12" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 17H16" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>

            <div className="border-l p-4 text-gray-600 flex items-center">
                <div className="flex flex-col">
                    <span className="text-sm font-light">Allow notifications</span>
                    <span className="text-xs text-gray-400 font-extralight mt-1">Get notified when you receive a new message</span>
                </div>

                <div className="flex items-center ml-8">
                    <button onClick={request} className="ml-2 px-2 py-1 bg-blue-500 rounded text-sm text-white hover:bg-blue-600">Yes</button>
                    <button onClick={close} className="ml-2 px-2 py-1 bg-gray-200 rounded text-sm text-gray-600 hover:bg-gray-300">No</button>
                </div>
            </div>
        </div>;
}
export default RequestPermissionModal;
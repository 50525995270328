import { isRouteErrorResponse, useRouteError } from "react-router-dom";
function NotFoundRoutePage(): JSX.Element {
  const error = useRouteError();
  const isRouteError = isRouteErrorResponse(error);
  if (isRouteError) {
    return <div className="flex flex-col items-center justify-center w-full h-screen">
            <span className='font-semibold text-2xl'>{error?.status ?? 404}</span>
            <span>Sorry, an unexpected error has occurred.</span>
            <i>{error.statusText}</i>
        </div>;
  } else if (error instanceof Error) {
    return <div className="flex flex-col items-center justify-center w-full h-screen">
            <span className='font-semibold text-2xl'>Oops!</span>
            <span>Sorry, an unexpected error has occurred.</span>
            <i>{error.message}</i>
        </div>;
  } else return <div className="flex flex-col items-center justify-center w-full h-screen">
            <span className='font-semibold text-2xl'>Oops!</span>
            <span>Sorry, an unexpected error has occurred.</span>
        </div>;
}
export default NotFoundRoutePage;
import { useCallback, useMemo } from 'react';
import { type DecryptedMessage } from 'src/types/Message';
import moment from 'moment';
import { parseTimestamp } from 'src/helpers/message';
import { useAppDispatch, useContacts } from 'src/hooks/store';
import { getNodeFromJid } from 'src/helpers/contact';
import { setEditing, setForwarding, setGroupEditing, setGroupForwarding, setGroupReplying, setReplying } from 'src/store/slices/conversation';
import { addDeletedMessage, setGroupThread } from 'src/store/slices/contacts';
import { xmpp } from 'src/constants/xmpp';
import Message from '../Message';
import ContextMenu, { type ContextMenuItem } from 'src/components/ContextMenu';
import { MessageType } from 'src/types/Ejabberd/MessageType';
interface Props {
  message: DecryptedMessage;
  onReplyMessageClicked: (messageId: string) => void;
}
function SentMessage({
  message,
  onReplyMessageClicked
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    timestamp,
    quotedMessageId
  } = message;
  const formattedTimestamp: string = moment.unix(parseTimestamp(timestamp)).format('HH:mm');
  const {
    activeContact,
    activeGroup,
    messages
  } = useContacts();
  const threadMessages = useMemo(() => {
    if (activeGroup === null) return [];
    const currentGroupMessages = messages[getNodeFromJid(activeGroup.jid)];
    return currentGroupMessages.filter(msg => msg.thid === message.id);
  }, [activeGroup, messages, message.id]);
  const showThreadMessages = useCallback(() => {
    if (activeGroup === null) return;
    dispatch(setGroupThread({
      messageId: message.id,
      groupId: activeGroup.jid
    }));
  }, [activeGroup, dispatch, message.id]);
  const handleReply = useCallback(() => {
    if (activeContact !== null) {
      dispatch(setReplying({
        contact: activeContact,
        message
      }));
    } else if (activeGroup !== null) {
      dispatch(setGroupReplying({
        contact: activeGroup,
        message
      }));
    }
  }, [activeContact, activeGroup, dispatch, message]);
  const handleEdit = useCallback(() => {
    if (activeContact !== null) {
      dispatch(setEditing({
        contact: activeContact,
        message
      }));
    } else if (activeGroup !== null) {
      dispatch(setGroupEditing({
        contact: activeGroup,
        message
      }));
    }
  }, [activeContact, activeGroup, dispatch, message]);
  const handleDelete = useCallback(() => {
    if (activeContact !== null) {
      xmpp.deleteMessage(activeContact.jid, message.id);
      dispatch(addDeletedMessage({
        jid: activeContact.jid,
        messageId: message.id
      }));
    } else if (activeGroup !== null) {
      xmpp.deleteGroupMessage(activeGroup.jid, message.id);
      dispatch(addDeletedMessage({
        jid: activeGroup.jid,
        messageId: message.id
      }));
    }
  }, [activeContact, activeGroup, dispatch, message.id]);
  const handleForward = useCallback(() => {
    if (activeContact !== null) {
      dispatch(setForwarding({
        from: activeContact,
        message,
        contacts: []
      }));
    } else if (activeGroup !== null) {
      dispatch(setGroupForwarding({
        from: activeGroup,
        message,
        contacts: []
      }));
    }
  }, [activeContact, activeGroup, dispatch, message]);
  const renderQuotedMessage = useMemo(() => {
    let findMessage: DecryptedMessage | null | undefined = null;
    let userName = null;
    if (activeContact !== null) {
      const jid = getNodeFromJid(activeContact.jid);
      findMessage = messages[jid]?.find(message => message.id === quotedMessageId);
      if (findMessage === undefined) return;
      userName = getNodeFromJid(findMessage.fromId) === getNodeFromJid(activeContact.jid) ? activeContact.name : 'Me';
    } else if (activeGroup !== null) {
      const groupId = getNodeFromJid(activeGroup.jid);
      findMessage = messages[groupId].find(message => message.id === quotedMessageId);
      if (findMessage === undefined) return;
      const fromId = getNodeFromJid(findMessage.fromId);
      if (fromId === getNodeFromJid(xmpp.connection.jid)) userName = 'Me';else userName = activeGroup.members?.find(member => getNodeFromJid(member.id) === fromId)?.name;
    }
    return <div className='p-1 pb-0 text-2xs cursor-pointer' onClick={() => {
      onReplyMessageClicked(findMessage?.id ?? "");
    }}>
            <div className='border-l-4 border-l-primary-light p-1 bg-gray-200 text-primary flex flex-col'>
                <span className='font-semibold'>{userName}</span>
                <span className='text-primary-light'>{findMessage?.content ?? findMessage?.type}</span>
            </div>
        </div>;
  }, [activeContact, activeGroup, messages, onReplyMessageClicked, quotedMessageId]);
  const contextItems: ContextMenuItem[] = [{
    label: 'Reply',
    onClick: handleReply
  }, {
    label: 'Delete',
    onClick: handleDelete
  }];
  if (message.type === MessageType.TEXT) {
    contextItems.push({
      label: 'Edit',
      onClick: handleEdit
    });
    contextItems.push({
      label: 'Forward',
      onClick: handleForward
    });
  } else if ([MessageType.AUDIO, MessageType.VIDEO, MessageType.FILE, MessageType.GIF, MessageType.IMAGE].includes(message.type)) {
    contextItems.push({
      label: 'Forward',
      onClick: handleForward
    });
  }
  if (activeGroup !== null) contextItems.push({
    label: 'Reply in Thread',
    onClick: showThreadMessages
  });
  if (message.isDeleted === true) return <div className='flex bg-primary items-center justify-between ml-auto text-sm w-fit p-4'>
            <span className='text-gray-200 italic'>Message was deleted</span>
            <div className="flex items-center relative -bottom-1 ml-4 self-end text-2xs">
                <span className='relative text-gray-400 mr-2'>{formattedTimestamp}</span>
            </div>
        </div>;
  return <ContextMenu id={`msg-${message.id}`} className='bg-primary w-fit break-all max-w-9/10 md:max-w-2/3 lg:max-w-1/2 ml-auto overflow-hidden rounded-tl-xl rounded-tr-xl rounded-bl-xl flex flex-col text-sm my-2 shadow' width={48} items={contextItems}>
            {quotedMessageId !== undefined && renderQuotedMessage}
            <Message message={message} />
            {threadMessages.length > 0 && <button className='bg-white text-2xs' onClick={showThreadMessages}>
                <div className='border-l-4 border-l-primary-light p-1 bg-gray-200 text-primary'>
                    <span className='font-semibold text-hover-text'>View {threadMessages.length} replies</span>
                </div>
            </button>}
        </ContextMenu>;
}
export default SentMessage;
import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import LoadingImage from "src/components/LoadingImage";
import SortedContactList from "src/components/SortedContactList";
import { defaultAvatar } from "src/constants/contact";
import { isGroup } from "src/helpers/group";
import { useAppDispatch, useContacts } from "src/hooks/store";
import { setCreateGroupContacts, setCreateGroupStep, resetCreateGroup } from "src/store/slices/createGroup";
import { type Contact } from "src/types/Contact";
function GroupCreateParticipants(): JSX.Element {
  const dispatch = useAppDispatch();
  const [search, $search] = useState<string>('');
  const [selectedParticipants, $selectedParticipants] = useState<Contact[]>([]);
  const {
    contacts
  } = useContacts();
  const filteredContacts = useMemo(() => contacts.filter(contact => !isGroup(contact.jid) && contact.isHidden !== true), [contacts]);
  const closeGroupCreate = useCallback(() => dispatch(resetCreateGroup()), [dispatch]);
  const onPressContact = useCallback(async (selectedContact: Contact) => {
    const index = selectedParticipants.findIndex(contact => contact.jid === selectedContact.jid);
    if (index === -1) {
      const newContact = {
        ...selectedContact,
        avatar: selectedContact.avatar
      };
      $selectedParticipants([...selectedParticipants, newContact]);
    } else {
      const newSelected = [...selectedParticipants];
      newSelected.splice(index, 1);
      $selectedParticipants(newSelected);
    }
  }, [selectedParticipants]);
  const removeContact = useCallback((jid: string) => {
    const newSelected = [...selectedParticipants];
    const index = newSelected.findIndex(contact => contact.jid === jid);
    newSelected.splice(index, 1);
    $selectedParticipants(newSelected);
  }, [selectedParticipants]);
  const onPressNext = useCallback(() => {
    dispatch(setCreateGroupContacts(selectedParticipants));
    dispatch(setCreateGroupStep('info'));
  }, [dispatch, selectedParticipants]);
  const renderSelectedParticipants = useMemo(() => selectedParticipants.length > 0 && <div className={clsx('flex flex-wrap items-center mx-3 h-24', selectedParticipants.length > 3 && 'overflow-y-scroll')}>
            {selectedParticipants.map(contact => <div key={contact.jid} className='flex shrink-0 items-center bg-hover-bg rounded-xl mr-3 mb-1 overflow-hidden transition hover:opacity-80'>
                    <LoadingImage url={contact.avatar} fallback={defaultAvatar} className='w-6 h-6 rounded-full' spinnerSize='16' alt="avatar" />
                    <span className='text-sm ml-2'>{contact.name}</span>
                    <button onClick={() => {
        removeContact(contact.jid);
      }} className="clickable mx-1">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z" fill="#1C1B1F" />
                        </svg>
                    </button>
                </div>)}
        </div>, [removeContact, selectedParticipants]);
  return <div className="flex flex-col w-[25%] min-w-[300px] h-screen shrink-0">

            <div className='mx-4 h-16 shrink-0 flex items-center'>
                <button onClick={closeGroupCreate} className='transition hover:opacity-80'>
                    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.25 7.27393L16.25 7.27393" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.2998 13.2985L1.2498 7.27451L7.2998 1.24951" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <span className='ml-5 font-bold text-xl'>Add group participants</span>
            </div>

            {renderSelectedParticipants}

            <div className="flex items-center rounded-3xl bg-white transition border border-hover-bg hover:border-light-blue py-1 px-4 mx-3 my-3">
                <svg width="18" height="18" viewBox="0 0 18 18" className='mr-1'>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.78234 0.666504C13.2573 0.666504 16.8973 4.3065 16.8973 8.7815C16.8973 10.8928 16.0871 12.8184 14.7612 14.2636L17.3702 16.8671C17.6143 17.1113 17.6152 17.5063 17.371 17.7504C17.2493 17.8738 17.0885 17.9346 16.9285 17.9346C16.7693 17.9346 16.6093 17.8738 16.4868 17.7521L13.8464 15.119C12.4574 16.2314 10.6962 16.8973 8.78234 16.8973C4.30734 16.8973 0.666504 13.2565 0.666504 8.7815C0.666504 4.3065 4.30734 0.666504 8.78234 0.666504ZM8.78234 1.9165C4.9965 1.9165 1.9165 4.99567 1.9165 8.7815C1.9165 12.5673 4.9965 15.6473 8.78234 15.6473C12.5673 15.6473 15.6473 12.5673 15.6473 8.7815C15.6473 4.99567 12.5673 1.9165 8.78234 1.9165Z" fill="#9393C1" />
                </svg>

                <input value={search} onChange={({
        target
      }) => {
        $search(target.value);
      }} type="text" placeholder='Search or start a new chat' className='p-2 w-full outline-none' />
            </div>

            <div className="flex flex-col w-full h-full overflow-y-scroll pb-28 px-3 shadow">
                <SortedContactList data={filteredContacts} search={search} onClick={onPressContact} />
            </div>

            {selectedParticipants.length > 0 && <div onClick={onPressNext} className="self-end clickable w-full h-20 shrink-0 center-flex">
                    <div className="center-flex bg-primary w-12 h-12 rounded-full">
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 7.72559L0.75 7.72559" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.7002 1.70124L15.7502 7.72524L9.7002 13.7502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <span className="text-xl ml-5">Create group</span>
                </div>}
        </div>;
}
export default GroupCreateParticipants;
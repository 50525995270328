import { SERVER_PUBLIC_KEY } from "src/constants/api";
import { GROUP_HOST, xmpp } from "src/constants/xmpp";
import API from "src/services/Api";
import { ContactTypes, type Contact } from "src/types/Contact";
import { type GroupEvent, type GroupEventType, GroupEventTypes, type EncryptedGroupMessage } from "src/types/Ejabberd/Message";
import { MessageType } from "src/types/Ejabberd/MessageType";
import { type GroupDetailsResponse, type GroupMessage, GroupAffiliations, type GroupUser, type GroupMetaResponse } from "src/types/Group";
import { type User } from "src/types/User";
import { getNodeFromJid } from "src/helpers/contact";
import { decryptAES, decryptRSA, encryptAES, encryptRSA, fixTimestamp, generateAes, generateGroupMessage, generateIv, generateTimestamp, parseMessageParameters } from "src/helpers/message";

import { v4 as uuidv4 } from 'uuid';
import { type MessageAdditionalData, type DecryptedMessage, type MessageParameters, type VoiceAttachment, type VideoAttachment, type FileAttachment, type ImageAttachment, type GIFAttachment, type LocationShareAttachment, type ContactAttachment } from "src/types/Message";
import { type AppDispatch } from "src/store";
import { addContact, addGroupEvent, addGroupMessage, fixGroupDateHeaders, sortContacts, updateMessageDeliveryStatusesForGroup, leaveGroup, addExistingGroup, updateGroup, updateGroupContact, updateGroupMembers, addGroup, removeContact, setActiveGroup } from "src/store/slices/contacts";
import { isJSON } from "./common";
import HttpStatus from 'src/constants/http';

export function isGroup(jid: string): boolean {
    return jid.includes(`@${GROUP_HOST}`);
}

export function generateGroupSecret(): string | false {
    const aes = generateAes()
    const iv = generateIv()

    const fullSecret = `${iv},${aes}`

    return encryptRSA(fullSecret, SERVER_PUBLIC_KEY)
}

export async function fetchGroupMeta(groupId: string, dispatch: AppDispatch): Promise<GroupMetaResponse | false> {
    try {
        const response = await API.get<GroupMetaResponse>(`/muc/rooms/${groupId}/meta`)
        if (response.status === HttpStatus.BAD_REQUEST) {
            // group deleted
            dispatch(leaveGroup(groupId))
            xmpp.connection.roster.remove(groupId)
            xmpp.connection.roster.unsubscribe(groupId)
            dispatch(removeContact(groupId))
        }
        else if (response.status !== HttpStatus.OK) {
            dispatch(leaveGroup(groupId))
            return false
        }

        return response.data
    }
    catch (error) {
        return false
    }

}


export async function fetchGroupDetails(groupId: string, user: User, dispatch: AppDispatch): Promise<Contact | false> {
    try {
        const response = await API.get<GroupDetailsResponse>(`/muc/rooms/${groupId}`)
        if (response.status === HttpStatus.BAD_REQUEST) {
            // group deleted
            dispatch(leaveGroup(groupId))
            xmpp.connection.roster.remove(groupId)
            xmpp.connection.roster.unsubscribe(groupId)
            dispatch(removeContact(groupId))
        }
        else if (response.status !== HttpStatus.OK) {
            //  left from group
            dispatch(leaveGroup(groupId))
            return false
        }

        const { name, photo, type, secret } = response.data

        const decryptedSecret = decryptRSA(secret, user.privateKey)
        if (decryptedSecret === false) return false

        const myAffiliation = GroupAffiliations.NONE
        return {
            jid: groupId + "@" + GROUP_HOST,
            avatar: photo,
            name,
            key: decryptedSecret,
            myAffiliation,
            members: [],
            type
        }
    }
    catch (error) {
        return false
    }

}

export async function updateGroupDetails(user: User, groupId: string, dispatch: AppDispatch): Promise<void> {
    const group = await fetchGroupDetails(groupId, user, dispatch)

    if (group === false) {
        dispatch(leaveGroup(groupId))
        return
    }

    const decryptedSecret = decryptRSA(group.key, user.privateKey)
    if (decryptedSecret === false) return

    group.key = decryptedSecret
    dispatch(updateGroup(group))
    dispatch(updateGroupContact({
        jid: group.jid,
        name: group.name,
    }))
}

const fetchGroupById = async (groupId: string, dispatch: AppDispatch): Promise<any> => {
    const response = await API.get<GroupDetailsResponse>(`/muc/rooms/${groupId}`)
    return response.data;
}

export async function fetchGroupMembers(groupId: string, dispatch: AppDispatch): Promise<GroupUser[]> {
    try {
        let response
        const group = await fetchGroupById(groupId, dispatch)
        if(group.type === ContactTypes.CHANNEL){
            const res = await Promise.all([API.get(`/muc/rooms/${groupId}/users`), API.get(`/muc/rooms/${groupId}/users?page=1`)])
            if(res[0].status !== HttpStatus.OK || res[1].status !== HttpStatus.OK){
                dispatch(leaveGroup(groupId))
                return []
            }
            response = [...res[0].data.data, ...res[1].data.data]
        } else {
           const res = await API.get(`/muc/rooms/${groupId}/users`)
              if(res.status !== HttpStatus.OK){
                dispatch(leaveGroup(groupId))
                return []
              }
           response = res.data.data
        }

        return response
    }
    catch (error) {
        console.error(error)
        dispatch(leaveGroup(groupId))
        return []
    }
}

export async function refreshGroupMembers(groupId: string, dispatch: AppDispatch): Promise<void> {
    try {
        const groupMeta = await fetchGroupMeta(groupId, dispatch)
        if (groupMeta === false) return 

        if (['owner', 'admin', 'member'].includes(groupMeta.affiliation)){
            const members = await fetchGroupMembers(groupId, dispatch)
            dispatch(updateGroupMembers({ groupId, members }))
        }
    }
    catch (error) {
        console.error(error)
    }
}

// export const generateEncryptedGroupMessage = (
//     type: MessageType,
//     content: string,
//     group: Group,
//     me: User,
//     additional: object = {}
// ): EncryptedGroupMessage | false => {
//     const [iv, aes] = group.secret.split(',')

//     const encryptedMessage = encryptAES(content, aes, iv)
//     const timestamp = fixTimestamp(moment().utc().unix().toString())
//     const messageId = uuidv4()

//     if (type === MessageType.TEXT) {
//         return {
//             mid: messageId,
//             fid: me.uid,
//             tid: group.id,
//             c: encryptedMessage,
//             t: type,
//             ts: timestamp.toString(),
//             ...additional
//         }
//     } else if (type === MessageType.VOICE) {
//         return {
//             mid: messageId,
//             fid: me.uid,
//             tid: group.id,
//             t: type,
//             at: content,
//             ts: timestamp.toString(),
//             ...additional
//         }
//     } else if (type === MessageType.IMAGE) {

//         return {
//             mid: messageId,
//             fid: me.uid,
//             tid: group.id,
//             t: type,
//             at: content,
//             ts: timestamp.toString(),
//             ...additional
//         }
//     } else return false
// }

export function insertGroupMessage(body: EncryptedGroupMessage, user: User, dispatch: AppDispatch, additional?: MessageParameters): void {
    if (xmpp.connection === undefined) return
    const content = prepareGroupMessageForUI(xmpp.connection.jid, body, additional ?? {})

    if (content === undefined) return

    dispatch(addGroupMessage({
        message: content,
        user
    }))

    console.log('message inserted', content.groupId, content.data.mid)

    if ([MessageType.TEXT, MessageType.CONTACT, MessageType.GIF, MessageType.LOCATION].includes(body.t)) {
        const groupId = body.fid === user.uid ? body.tid : body.fid
        dispatch(updateMessageDeliveryStatusesForGroup(groupId))
    }

    dispatch(fixGroupDateHeaders({ groupId: content.groupId }))
}

export const generateEncryptedMessageContentForGroup = (
    type: MessageType,
    content: string,
    group: Contact,
    me: User,
    additional: MessageAdditionalData = {}
): EncryptedGroupMessage | false => {
    const groupId = getNodeFromJid(group.jid)
    const [iv, aes] = group.key.split(',')

    const encryptedMessage = encryptAES(content, aes, iv)
    const timestamp = generateTimestamp()
    const messageId = uuidv4()

    if (type === MessageType.TEXT) {
        return {
            mid: messageId,
            fid: me.uid,
            tid: groupId,
            c: encryptedMessage,
            t: type,
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.VOICE) {
        return {
            mid: messageId,
            fid: me.uid,
            tid: groupId,
            t: type,
            at: content,
            sk: "null",
            ts: timestamp.toString(),
            ...additional
        }
    } else if (type === MessageType.IMAGE) {
        return {
            mid: messageId,
            fid: me.uid,
            tid: groupId,
            t: type,
            at: content,
            sk: "null",
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.GIF) {
        return {
            mid: messageId,
            fid: me.uid,
            tid: groupId,
            t: type,
            at: content,
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.VIDEO) {

        return {
            mid: messageId,
            fid: me.uid,
            tid: groupId,
            t: type,
            at: content,
            sk: "null",
            ts: timestamp.toString(),
            ...additional
        }
    }
    else if (type === MessageType.FILE) {

        return {
            mid: messageId,
            fid: me.uid,
            tid: groupId,
            t: type,
            at: content,
            sk: "null",
            ts: timestamp.toString(),
            ...additional
        }
    }

    else return false
}

export function decryptGroupMessage(message: EncryptedGroupMessage, groupSecret: string, received: boolean): DecryptedMessage | false {
    const [iv, aes] = groupSecret.split(',')
    const timestamp = fixTimestamp(message.ts)

    const commonParams = {
        id: message.mid,
        type: message.t,
        fromId: message.fid,
        toId: message.tid,
        quotedMessageId: message.qid ?? undefined,
        timestamp,
        received,
        mamId: message.mamId ?? null,
        isUploaded: message.isUploaded ?? true,
        thid: message.thid ?? null,
        isRead: false
    }
    if (message.t === MessageType.TEXT && message.c !== undefined) {
        if (message.c === undefined) return false
        const sentContent = decryptAES(message.c, aes, iv)
        if (sentContent === false) return false

        if (message.thid !== undefined && message.thid !== null) {
            console.log('sentContent', sentContent)
        }

        return {
            ...commonParams,
            content: sentContent,
            attachment: message.at
        }
    }
    else if (message.at === undefined) return false
    else if (message.t === MessageType.VOICE) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            d: 0,
            s: 0
        }

        const attachment: VoiceAttachment = {
            data: attachmentData,
            metaData: {
                duration: parsedMetaData.d,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment
        }
    }
    else if (message.t === MessageType.VIDEO) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            d: 0,
            s: 0
        }

        const attachment: VideoAttachment = {
            data: attachmentData,
            thumbnail: message.at.tb,
            metaData: {
                duration: parsedMetaData.d,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.FILE) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            n: '',
            s: 0
        }

        const attachment: FileAttachment = {
            data: attachmentData,
            metaData: {
                name: parsedMetaData.n,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.IMAGE) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            s: 0
        }

        const attachment: ImageAttachment = {
            data: attachmentData,
            thumbnail: message.at.tb,
            metaData: {
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.GIF) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const parsedMetaData = isJSON(message.at.md) ? JSON.parse(message.at.md) : {
            s: 0
        }

        const attachment: GIFAttachment = {
            data: attachmentData,
            metaData: {
                width: parsedMetaData.w,
                height: parsedMetaData.h,
                size: parsedMetaData.s,
            }
        }
        return {
            ...commonParams,
            attachment,
            abortControllerId: message.abortControllerId
        }
    }
    else if (message.t === MessageType.LOCATION || message.t === MessageType.LIVE_LOCATION) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const { lat, lng, name } = JSON.parse(attachmentData)

        const attachment: LocationShareAttachment = {
            latitude: lat,
            longitude: lng,
            name,
            thumbnail: message.at.tb
        }
        return {
            ...commonParams,
            attachment,
        }
    }
    else if (message.t === MessageType.CONTACT) {
        const attachmentData = decryptAES(message.at.dt, aes, iv)
        if (attachmentData === false) return false

        const { numbers, name } = JSON.parse(attachmentData)

        const attachment: ContactAttachment = {
            name,
            numbers
        }
        return {
            ...commonParams,
            attachment,
        }
    }
    else return false
}

export function parseGroupEvent(message: GroupEvent, group: Contact, contacts: Contact[], received: boolean, user: User): DecryptedMessage | false {
    if (message.c === undefined) return false

    // myId: f4fb67ef-a5de-4726-a081-1441d871e0a2
    // groupId: 7f521e84-8b27-44dc-8340-8dab1a47d9ee

    // const fromId = eventData[0]
    // const fromMember = group.members.find(member => Strophe.getNodeFromJid(member.id) === Strophe.getNodeFromJid(message.fid))
    const eventType = message.c as GroupEventType
    // const toId = message.tid
    let content;
    let fromUserName = "User"
    let targetUserName = "User"

    const targetGroupMember = group.members?.find(member => member.id === message.tgid)
    const targetContact = contacts.find(contact => getNodeFromJid(contact.jid) === message.tgid)
    if (targetGroupMember !== undefined) targetUserName = targetGroupMember.name
    else if (targetContact !== undefined) targetUserName = targetContact.name

    const fromGroupMember = group.members?.find(member => member.id === message.fid)
    const fromContact = contacts.find(contact => getNodeFromJid(contact.jid) === message.fid)
    if (fromGroupMember !== undefined) fromUserName = fromGroupMember.name
    else if (fromContact !== undefined) fromUserName = fromContact.name
    const groupType = group.type === ContactTypes.CHANNEL ? 'channel' : 'group';
    const capitalGroupType = groupType.charAt(0).toUpperCase() + groupType.slice(1);
    switch (eventType) {
        case GroupEventTypes.GROUP_CREATION:
            content = `${capitalGroupType} created`
            break;
        case GroupEventTypes.USER_REMOVED_BY_ADMIN:
            content = `You have been removed from the ${groupType}`
            break;
        case GroupEventTypes.USER_ADDED:
            content = `${targetUserName} has been added to the ${groupType}`
            break;
        case GroupEventTypes.GROUP_CALL_STARTED:
            content = `${fromUserName} started ${groupType} call`
            break;
        case GroupEventTypes.GROUP_CALL_ENDED:
            content = `${fromUserName} ended ${groupType} call`
            break;
        case GroupEventTypes.ADMIN_SET:
            content = `${targetUserName} added as Admin`
            break;
        case GroupEventTypes.USER_LEFT_GROUP:
            content = `User left the ${groupType}`
            break;
        case GroupEventTypes.GROUP_SETTINGS_CHANGED:
            content = `${fromUserName} changed ${groupType} settings`
            break;
        case GroupEventTypes.ADMIN_REMOVED:
            content = `${targetUserName} removed from Admin`
            break;
        case GroupEventTypes.JOINED_VIA_LINK:
            content = "Joined via link"
            break;
        case GroupEventTypes.JOINED:
            content = `${targetUserName} joined to the ${groupType}`
            break;
        case GroupEventTypes.USER_KICKED:
            if (message.fid === message.tgid && user.uid === message.fid) {
                content = `You left the ${groupType}`
                break;
            }
            if (message.fid === message.tgid) {
                content = `${fromUserName} left the ${groupType}`
            }
            else content = `${targetUserName} has been kicked from the ${groupType}`
            break;
        case GroupEventTypes.CREATED:            
            content = `${fromUserName} created this ${groupType}`
            break;
        case GroupEventTypes.UNKNOWN:
            content = "Unknown Event"
            break;
        default:
            console.error('Unknown group event type', eventType)
            return false
    };

    return {
        type: message.t,
        id: message.mid,
        fromId: message.fid,
        toId: message.tid,
        content,
        timestamp: parseInt(message.ts),
        received
    }
}


export const prepareGroupMessageForUI = (myJid: string, body: EncryptedGroupMessage, params: MessageParameters): GroupMessage => {
    const myId = getNodeFromJid(myJid)
    const fromId = getNodeFromJid(body.fid)
    const toId = getNodeFromJid(body.tid)

    const received = fromId !== myId
    const attachment = body.at !== undefined ? JSON.parse(body.at) : null

    const messageContent = { ...body, at: attachment, ...params }

    const messageData = {
        groupId: toId,
        received,
        data: messageContent
    }

    return messageData
}

export async function addPendingGroup(dispatch: AppDispatch, user: User, groupId: string, isDelayed: boolean = false): Promise<Contact | undefined> {
    if (xmpp.connection === undefined || user === null) return

    const fullId = `${groupId}@${GROUP_HOST}`
    const rosterExists = xmpp.connection.roster.items.find(item => item.jid === fullId)

    if (isDelayed || rosterExists !== undefined) {
        dispatch(addExistingGroup(groupId))
        return
    }
    const groupMeta = await fetchGroupMeta(groupId, dispatch)
    if (groupMeta === false) {
        dispatch(leaveGroup(groupId))
        return
    }
    const group = await fetchGroupDetails(groupId, user, dispatch)
    if (group === false) {
        dispatch(leaveGroup(groupId))
        return
    }
    var groupMembers: GroupUser[] = []
    if (['owner', 'admin', 'member'].includes(groupMeta.affiliation)){
        groupMembers = await fetchGroupMembers(groupId, dispatch)
    }
    group.members = groupMembers

    console.log('GROUP MEMBERS', groupMembers)

    const contact: Contact = {
        ...group,
        isGroup: true,
        members: group.members,
    }

    dispatch(addContact(contact))
    dispatch(addGroup({
        group,
        user,
        addAsContact: true
    }))
    dispatch(sortContacts(groupId))

    // xmpp.join(group.id)

    if (rosterExists === undefined) {
        xmpp.connection.roster.add(fullId, contact.name, [])
        xmpp.connection.roster.subscribe(fullId, null, contact.name)
    }

    return group
}

export function xmppGroupMessageHandler(json: EncryptedGroupMessage, xml: Element, user: User | null, dispatch: AppDispatch): void {
    if (json.fid === null || user === null) return

    const params = parseMessageParameters(xml)
    const messageData = generateGroupMessage(json, params)

    dispatch(addGroupMessage({
        message: messageData,
        user
    }))

    dispatch(updateMessageDeliveryStatusesForGroup(messageData.groupId))
    dispatch(fixGroupDateHeaders({ groupId: messageData.groupId }))
}

export function xmppGroupEventHandler(isArchived: boolean, json: GroupEvent, xml: Element, user: User | null, dispatch: AppDispatch): void {
    if (json.fid === null || user === null) return

    const isInvitation = (json.c === GroupEventTypes.USER_ADDED && json.tgid === getNodeFromJid(xmpp.connection.jid)) || json.c === GroupEventTypes.CREATED
    const isRemoved = (json.c === GroupEventTypes.USER_KICKED || json.c === GroupEventTypes.USER_LEFT_GROUP) && json.tgid === getNodeFromJid(xmpp.connection.jid)

    if (isRemoved && !isArchived){ 
        dispatch(leaveGroup(json.tid))
        dispatch(setActiveGroup(null))
    }

    const addMessage = (): void => {
        const params = parseMessageParameters(xml)
        const messageData = generateGroupMessage(json, params)

        if (user === null) return

        dispatch(addGroupEvent({
            message: messageData,
            user
        }))
        dispatch(fixGroupDateHeaders({ groupId: messageData.groupId }))
    }

    if (!isArchived && isInvitation)
        addPendingGroup(dispatch, user, json.tid).then(group => {
            if (group === undefined) return

            if (group.type === ContactTypes.CHANNEL) {
                xmpp.getChannelMessages(group.jid)
            }
            addMessage()
        }).catch(console.error)
    else addMessage()


    const isDetailsUpdated = json.c === GroupEventTypes.GROUP_SETTINGS_CHANGED
    const isMembersUpdated = [GroupEventTypes.ADMIN_REMOVED, GroupEventTypes.ADMIN_SET, GroupEventTypes.USER_KICKED, GroupEventTypes.USER_ADDED].includes(json.c as GroupEventTypes)

    if (!isArchived && isDetailsUpdated) updateGroupDetails(user, json.tid, dispatch).catch(console.error)
    if (!isArchived && isMembersUpdated) refreshGroupMembers(json.tid, dispatch).catch(console.error)
}

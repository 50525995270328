import { useCallback } from 'react';
import { changeContactChatSetting } from 'src/helpers/contact';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { type RootState } from 'src/store';
import { setActiveChatSettings } from 'src/store/slices/contacts';
function BlockedContactChatActions(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    activeContact,
    activeChatSettings
  } = useAppSelector((state: RootState) => state.contacts);
  const unblock = useCallback(async () => {
    console.log('unblocking', activeChatSettings, activeContact);
    if (activeContact === null || activeChatSettings === null) return;
    const response = await changeContactChatSetting(activeContact, "BLOCK", false);
    if (response !== false && response.status === 201) {
      const newChatSettings = {
        ...activeChatSettings,
        from: {
          ...activeChatSettings.from,
          BLOCK: false
        }
      };
      dispatch(setActiveChatSettings(newChatSettings));
    }
  }, [activeChatSettings, activeContact, dispatch]);
  return <div className='absolute flex items-center justify-center bottom-0 w-full bg-primary border-t p-2 px-4'>

            <span className='text-white'>You have blocked {activeContact?.name}</span>
            <button className='bg-accent text-primary ml-4 px-2 py-1 transition hover:opacity-80' onClick={unblock}>
                Unblock
            </button>
        </div>;
}
export default BlockedContactChatActions;
import { createSlice } from '@reduxjs/toolkit'
import { type DecryptedMessage, type ForwardingMessage } from 'src/types/Message'
import { type Contact } from 'src/types/Contact'
import reducers from './reducers'

export interface EditingMessage<T> {
    message: DecryptedMessage
    contact: T
}

export interface ReplyingMessage<T> {
    message: DecryptedMessage
    contact: T
}

export interface ConversationState {
    editing: EditingMessage<Contact> | null,
    replying: ReplyingMessage<Contact> | null,
    forwarding: ForwardingMessage<Contact, undefined> | null,

    groupEditing: EditingMessage<Contact> | null,
    groupReplying: ReplyingMessage<Contact> | null,
    groupForwarding: ForwardingMessage<Contact, Contact> | null,
}

export const initialState: ConversationState = {
    editing: null,
    replying: null,
    forwarding: null,

    groupEditing: null,
    groupReplying: null,
    groupForwarding: null,
}

export const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers,
    extraReducers: (builder) => {
        builder.addCase('PURGE', (state) => initialState)
    }
})

export const {
    setReplying,
    setGroupReplying,
    setGroupForwarding,
    setEditing,
    setGroupEditing,
    updateGroupEditingMessage,
    updateEditingMessage,
    setForwarding,
    cancelReplying,
    cancelEditing,
    cancelGroupEditing,
    cancelGroupReplying,
    resetConversationState
} = conversationSlice.actions

export default conversationSlice.reducer

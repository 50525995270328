import LoadingSpinner from 'src/components/LoadingSpinner';
import GroupMessages from './Messages';
import GroupChatActions from './Actions';
import GroupNavigation from './Navigation';
import { useContacts, useUser } from 'src/hooks/store';
import Gallery from 'src/components/Contact/Chat/Gallery';
import { useMemo } from 'react';
import { ContactTypes } from 'src/types/Contact';
import { GroupAffiliations } from 'src/types/Group';
function GroupChat(): JSX.Element {
  const {
    activeGroup,
    threadGroupId
  } = useContacts();
  const {
    user
  } = useUser();
  const renderLeftGroupNotice = useMemo(() => <div className='absolute bottom-0 h-24 py-10 w-full bg-chat-bottom-bg'>
            <span className='flex justify-center text-context-text text-sm'>You cannot send messages to this group because you are no longer a participant.</span>
        </div>, []);
  const shouldShowActions = useMemo(() => {
    if (activeGroup === null || activeGroup.isLeft === true || threadGroupId !== null) return false;
    if (activeGroup.type === ContactTypes.CHANNEL) {
      const me = activeGroup.members?.find(member => member.id === user?.uid);
      console.log('me', me);
      return me?.affiliation === GroupAffiliations.ADMIN || me?.affiliation === GroupAffiliations.OWNER;
    } else return true;
  }, [activeGroup, threadGroupId, user?.uid]);
  const renderChannelNotice = useMemo(() => activeGroup?.type === ContactTypes.CHANNEL && !shouldShowActions && <div className='absolute bottom-0 flex justify-center items-center h-16 w-full bg-chat-bottom-bg'>
            <span className='flex justify-center text-context-text text-sm'>You cannot send messages to this group because you are not an admin.</span>
        </div>, [activeGroup?.type, shouldShowActions]);
  if (activeGroup === null) return <LoadingSpinner />;
  return <div className='relative w-full h-screen'>
            <GroupNavigation />
            <Gallery />
            <div className="flex flex-col w-full h-full">
                <GroupMessages />
                {activeGroup.isLeft === true && renderLeftGroupNotice}
                {shouldShowActions && <GroupChatActions />}
                {renderChannelNotice}
            </div>
        </div>;
}
export default GroupChat;
import React, { useEffect, useRef } from "react";
import { Picker } from 'emoji-mart';
function EmojiPicker(props: any): JSX.Element {
  const ref = useRef(null);
  const instance = useRef<typeof Picker>(null);
  if (instance.current !== null) {
    instance.current.update(props);
  }
  useEffect(() => {
    instance.current = new Picker({
      ...props,
      ref
    });
    return () => {
      instance.current = null;
    };
  }, [props]);
  return React.createElement('div', {
    ref
  });
}
export default EmojiPicker;
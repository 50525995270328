import localforage from 'localforage';

const uupStorage = localforage.createInstance({
    name: "uup-storage"
})

uupStorage.config({
    driver: localforage.INDEXEDDB,
    name: 'uup-storage',
    version: 1.0,
    storeName: 'uup-storage-db'
})

export default uupStorage;

import { type PayloadAction } from "@reduxjs/toolkit"
import { ContactTypes, type Contact, type ParsedChatSettings } from "src/types/Contact"
import { initialState, removeContact, type ContactsState } from ".."
import { getNodeFromJid, sortContactsByLastMessage } from "src/helpers/contact"
import { HOST, xmpp } from "src/constants/xmpp"
import { EventBus } from "src/services/EventBus"
import { UupEvents } from "src/constants/events"

const reducers = {
    setContacts: (state: ContactsState, action: PayloadAction<Contact[]>) => {
        const oldHiddenContacts = state.contacts.filter(contact => contact.isHidden === true)        
        let newContacts = action.payload
        newContacts = [...oldHiddenContacts, ...newContacts]
        if (newContacts.length !== 0) state.contacts = newContacts
    },
    setContactData: (state: ContactsState, action: PayloadAction<Contact[]>) => {
        const contacts = action.payload

        console.log('[CONTACTS DATA]', contacts)

        contacts.forEach(contact => {
            const index = state.contacts.findIndex(existingContact => existingContact.jid === contact.jid)

            if (index === -1) return

            if(state.contacts[index].name === undefined || state.contacts[index].name === '' || state.contacts[index].name === null){
                state.contacts[index].name = contact.name
            }

            state.contacts[index].avatar = contact.avatar
            state.contacts[index].key = contact.key
            state.contacts[index].type = contact.type
            if (contact.members !== undefined) state.contacts[index].members = contact.members
        })
    },
    fetchContactMessages: (state: ContactsState, action: PayloadAction<Contact[]>) => {
        const contacts = action.payload
        const lastMessagesByContact: Record<string, number> = {}
        const mostRecentClearedAt: Record<string, number> | undefined = {}
        contacts.forEach((contact) => {
            const uid = getNodeFromJid(contact.jid)
            let lastMessages = state.messages[uid]
            if (contact.isClearedAt !== undefined) mostRecentClearedAt[uid] = contact.isClearedAt
            if (lastMessages === undefined || lastMessages.length === 0) return

            lastMessages = lastMessages.filter(message => message.isDelivered === true)

            if (lastMessages.length <= 0) return

            const maxTimestamp = Math.max(...lastMessages.map(message => message.timestamp))
            lastMessagesByContact[uid] = maxTimestamp
        })

        let mostRecentMessageTimestamp: number | undefined = Math.max(...Object.values(lastMessagesByContact))
        if (mostRecentMessageTimestamp === -Infinity) mostRecentMessageTimestamp = undefined
        if(contacts.length === 0){
            EventBus.next({
                type: UupEvents.CONTACTS_LOADED,
                payload: contacts
            })
        }
        xmpp.getOfflineMessages()
        contacts.forEach((contact, idx) => {
            const uid = getNodeFromJid(contact.jid)

            const hasMsg = state.messages[uid] !== undefined && state.messages[uid].length > 0
            if (!hasMsg) state.messages[uid] = []

            if (!hasMsg && contact.type === ContactTypes.CHANNEL) {
                xmpp.getChannelMessages(contact.jid)
            }

            let _timestamp = mostRecentMessageTimestamp
            if (mostRecentClearedAt[uid] !== undefined) {
                state.messages[uid] = state.messages[uid].filter(message => message.timestamp > mostRecentClearedAt[uid])
                _timestamp = mostRecentClearedAt[uid]
            }
            
            xmpp.getLastMessagesByJid(contact.jid, _timestamp, () => {
                if (idx === contacts.length - 1) {
                    xmpp.getLastMessagesByJid(process.env.REACT_APP_SERVER_UID as string, _timestamp, () => {
                        EventBus.next({
                            type: UupEvents.CONTACTS_LOADED,
                            payload: contacts
                        })
                    })
                }
            })
        })
    },
    sendReceivedEvent: (state: ContactsState, action: PayloadAction<Contact[]>) => {
        const contacts = action.payload
        contacts.forEach((contact) => {
            if (contact.isGroup === true) return
            const uid = getNodeFromJid(contact.jid)
            let newlyReceivedMessages = state.messages[uid]

            newlyReceivedMessages = newlyReceivedMessages.filter(message => message.received && message.isRead === false)
            newlyReceivedMessages.forEach(message => {
                xmpp.sendReceivedEvent(`${uid}@${HOST}`, message.id)
            })
        })

    },
    addContact: (state: ContactsState, action: PayloadAction<Contact>) => {
        const newContact = action.payload
        const contactId = getNodeFromJid(newContact.jid);
        state.contacts = state.contacts.filter(contact => {
            return getNodeFromJid(contact.jid) !== contactId
        })
        state.contacts.unshift(newContact)
        if (!(contactId in state.messages))
            state.messages[contactId] = []
        state.pendingRosters = state.pendingRosters.filter( contactJid => getNodeFromJid(contactJid) !== contactId)
    },
    setActiveContact: (state: ContactsState, action: PayloadAction<Contact | null>) => {
        state.activeContact = action.payload
        state.activeGroup = null
        state.galleryItems = []
        state.mainGalleryItem = null
    },
    setActiveChatSettings(state: ContactsState, action: PayloadAction<ParsedChatSettings>) {
        state.activeChatSettings = action.payload
    },
    updateContact: (state: ContactsState, action: PayloadAction<Contact>) => {
        const { jid } = action.payload
        const index = state.contacts.findIndex(existingContact => existingContact.jid === jid)

        if (index === -1) return
        state.contacts[index] = action.payload
    },
    updateGroupContact: (state: ContactsState, action: PayloadAction<{
        jid: string,
        name: string,
    }>) => {
        const { jid, name } = action.payload
        const group = state.contacts.find(existingContact => existingContact.jid === jid)
        if (group === undefined) return
        group.name = name
    },
    addPaginationLimitedContact: (state: ContactsState, action: PayloadAction<string>) => {
        state.paginationLimitedContacts.push(action.payload)
    },
    removePaginationLimitedContacts: (state: ContactsState, action: PayloadAction<string[]>) => {
        state.paginationLimitedContacts = state.paginationLimitedContacts.filter(contact => !action.payload.includes(contact))
    },
    setShowContactInfo: (state: ContactsState, action: PayloadAction<boolean>) => {
        state.showContactInfo = action.payload
    },
    sortContacts: (state: ContactsState, action: PayloadAction<string | undefined>) => {
        const firstId = action.payload

        if (firstId === undefined) {
            state.contacts = sortContactsByLastMessage(state.contacts, state.messages)
        }
        else {
            const firstIndex = state.contacts.findIndex(contact => getNodeFromJid(contact.jid) === getNodeFromJid(firstId))
            const firstContact = state.contacts[firstIndex]
            const otherContacts = state.contacts.filter(contact => getNodeFromJid(contact.jid) !== getNodeFromJid(firstId))

            state.contacts = [firstContact, ...sortContactsByLastMessage(otherContacts, state.messages)]
        }
    },
    syncContacts: (state: ContactsState) => {
        state.contacts.forEach(contact => {
            const contactUid = getNodeFromJid(contact.jid)
            if(state.messages[contactUid] === undefined) return
            const isClearedAt = contact.isClearedAt
            if(isClearedAt !== undefined){
                state.messages[contactUid] = state.messages[contactUid].filter(message => message.timestamp > isClearedAt)
            }
            state.messages[contactUid].forEach(message => {
                if(message.isDelivered !== true) {
                    return
                }
                if(contact.isReadAt !== undefined && message.timestamp <= contact.isReadAt){
                    message.isRead = true
                    message.isDelivered = true
                }
                if(contact.isReceivedAt !== undefined && message.timestamp <= contact.isReceivedAt){
                    message.isDelivered = true
                }
            })
        })
    },
    resetContacts: (state: ContactsState) => {
        state.contacts = []
    },
    resetContactState: (state: ContactsState) => {
        return initialState
    }
}

export default reducers

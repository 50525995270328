import { useEffect, useState } from "react";
import { defaultAvatar } from "src/constants/contact";
import { getNodeFromJid, sortContactsByAlphabet } from "src/helpers/contact";
import { type Contact } from "src/types/Contact";
import LoadingImage from "../LoadingImage";
import FallbackAvatar from "../Group/FallbackAvatar";
interface Props {
  data: Contact[];
  onClick: (user: Contact) => void;
  search?: string;
  sortingDisabled?: boolean;
}
interface SortedData {
  letter: string;
  items: Contact[];
}
function SortedContactList({
  data,
  search,
  onClick,
  sortingDisabled
}: Props): JSX.Element | null {
  const [sortedData, $sortedData] = useState<SortedData[]>([]);
  useEffect(() => {
    const items = data.filter(item => search !== undefined && item.name !== undefined ? item.name?.toLowerCase().includes(search.toLowerCase()) : true);
    $sortedData(sortContactsByAlphabet(items));
  }, [data, search]);
  const renderUser = (user: Contact): JSX.Element => {
    return <div key={user.jid} onClick={() => {
      onClick(user);
    }} className={`group flex items-center px-3 py-2 transition rounded-lg hover:bg-hover-bg cursor-pointer [content-visibility: auto]`}>
                <LoadingImage url={user.avatar} className='shadow bg-white p-0.5 w-16 h-16 object-cover rounded-xl shrink-0' spinnerSize="16" alt="avatar" {...user.isGroup === true ? {
        fallbackElement: <FallbackAvatar contact={user} />
      } : {
        fallback: defaultAvatar
      }} />

                <div className="flex flex-col w-full ml-3">
                    <span className='text-primary font-semibold'>{user.name ?? 'Uup User'}</span>
                </div>
            </div>;
  };
  if (sortedData.length === 0) return null;
  if (sortingDisabled === true) return <>
            {data.map(renderUser)}
        </>;
  return <>
            {sortedData.map(({
      letter,
      items
    }) => <div key={letter} className="mb-6">
                    <div className="text-hover-text border-b border-gray-200 pb-3 ml-4 mb-2">{letter.toUpperCase()}</div>
                    {items.map(renderUser)}
                </div>)}
        </>;
}
export default SortedContactList;
import { useCallback, useEffect } from "react";
interface Props<T> {
  items: T[];
  onReachEnd: () => void;
  render: (item: T) => JSX.Element;
}
function PaginatedList<T>({
  items,
  render,
  onReachEnd
}: Props<T>): JSX.Element {
  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) onReachEnd();
    });
  }, [onReachEnd]);
  const initRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const observer = new IntersectionObserver(intersectionCallback, {
        root: null,
        rootMargin: "0px",
        threshold: 0.4
      });
      observer.observe(node);
    }
  }, [intersectionCallback]);
  const renderList = items.map((item, index) => {
    if (index === items.length - 1) {
      return <div key={index} ref={initRef}>
                {render(item)}
            </div>;
    }
    return <div key={index}>{render(item)}</div>;
  });
  return <div>
        {renderList}
    </div>;
}
export default PaginatedList;
async function generateKey(alg: AlgorithmIdentifier, scope: any): Promise<CryptoKeyPair | CryptoKey> {
    return await new Promise(function (resolve) {
        const genkey = crypto.subtle.generateKey(alg, true, scope)
        genkey.then(function (pair) {
            resolve(pair)
        }).catch(console.error)
    })
}

function arrayBufferToBase64String(arrayBuffer: ArrayBuffer): string {
    const byteArray = new Uint8Array(arrayBuffer)
    let byteString = ''
    for (let i = 0; i < byteArray.byteLength; i++) {
        byteString += String.fromCharCode(byteArray[i])
    }
    return btoa(byteString)
}

// function base64StringToArrayBuffer(b64str: string): ArrayBuffer {
//     const byteStr = atob(b64str)
//     const bytes = new Uint8Array(byteStr.length)
//     for (let i = 0; i < byteStr.length; i++) {
//         bytes[i] = byteStr.charCodeAt(i)
//     }
//     return bytes.buffer
// }

// function textToArrayBuffer(str: any): Uint8Array {
//     const buf = unescape(encodeURIComponent(str)) // 2 bytes for each char
//     const bufView = new Uint8Array(buf.length)
//     for (let i = 0; i < buf.length; i++) {
//         bufView[i] = buf.charCodeAt(i)
//     }
//     return bufView
// }

// function arrayBufferToText(arrayBuffer: ArrayBuffer): string {
//     const byteArray = new Uint8Array(arrayBuffer)
//     let str = ''
//     for (let i = 0; i < byteArray.byteLength; i++) {
//         str += String.fromCharCode(byteArray[i])
//     }
//     return str
// }


// function arrayBufferToBase64(arr: any): string {
//     return btoa(String.fromCharCode.apply(null, new Uint8Array(arr) as any))
// }

function convertBinaryToPem(binaryData: ArrayBuffer, label: string): string {
    const base64Cert = arrayBufferToBase64String(binaryData)
    let pemCert = "-----BEGIN " + label + "-----\r\n"
    let nextIndex = 0
    while (nextIndex < base64Cert.length) {
        if (nextIndex + 64 <= base64Cert.length) {
            pemCert += base64Cert.substr(nextIndex, 64) + "\r\n"
        } else {
            pemCert += base64Cert.substr(nextIndex) + "\r\n"
        }
        nextIndex += 64
    }
    pemCert += "-----END " + label + "-----\r\n"
    return pemCert
}

// function convertPemToBinary(pem: any): ArrayBuffer {
//     const lines = pem.split('\n')
//     let encoded = ''
//     for (let i = 0; i < lines.length; i++) {
//         if (lines[i].trim().length > 0 &&
//             lines[i].indexOf('-BEGIN RSA PRIVATE KEY-') < 0 &&
//             lines[i].indexOf('-BEGIN RSA PUBLIC KEY-') < 0 &&
//             lines[i].indexOf('-END RSA PRIVATE KEY-') < 0 &&
//             lines[i].indexOf('-END RSA PUBLIC KEY-') < 0) {
//             encoded += lines[i].trim() as string
//         }
//     }
//     return base64StringToArrayBuffer(encoded)
// }

// async function importPublicKey(pemKey: any): Promise<CryptoKey> {
//     return await new Promise(function (resolve) {
//         const importer = crypto.subtle.importKey("spki", convertPemToBinary(pemKey), signAlgorithm, true, ["verify"])
//         importer.then(function (key) {
//             resolve(key)
//         }).catch(console.error)
//     })
// }

// async function importPrivateKey(pemKey: any) {
//     return await new Promise(function (resolve) {
//         const importer = crypto.subtle.importKey("pkcs8", convertPemToBinary(pemKey), signAlgorithm, true, ["sign"])
//         importer.then(function (key) {
//             resolve(key)
//         })
//     })
// }

export async function exportPublicKey(keys: any): Promise<string> {
    return await new Promise(function (resolve) {
        window.crypto.subtle.exportKey('spki', keys.publicKey)
            .then(function (spki) {
                resolve(convertBinaryToPem(spki, "PUBLIC KEY"))
            }).catch(console.error)
    })
}

export async function exportPrivateKey(keys: any): Promise<string> {
    return await new Promise(function (resolve) {
        const expK = window.crypto.subtle.exportKey('pkcs8', keys.privateKey)
        expK.then(function (pkcs8) {
            resolve(convertBinaryToPem(pkcs8, "PRIVATE KEY"))
        }).catch(console.error)
    })
}

// async function exportPemKeys(keys: any): Promise<{ publicKey: string, privateKey: string }> {
//     return await new Promise(function (resolve) {
//         exportPublicKey(keys).then(function (pubKey) {
//             exportPrivateKey(keys).then(function (privKey) {
//                 resolve({ publicKey: pubKey, privateKey: privKey })
//             }).catch(console.error)
//         })
//     })
// }

// async function signData(key: any, data: any): Promise<ArrayBuffer> {
//     return await window.crypto.subtle.sign(signAlgorithm, key, textToArrayBuffer(data))
// }

// async function testVerifySig(pub: any, sig: any, data: any) {
//     return await crypto.subtle.verify(signAlgorithm, pub, sig, data)
// }

// async function encryptData(vector: any, key: any, data: any) {
//     return await crypto.subtle.encrypt(
//         {
//             name: "RSA-OAEP",
//             iv: vector
//         },
//         key,
//         textToArrayBuffer(data)
//     )
// }

export function str2ab(str: any): ArrayBuffer {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

export function b642ab(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export async function decryptData(
    iv: BufferSource,
    key: any,
    data: any
): Promise<ArrayBuffer> {
    return await crypto.subtle.decrypt(
        {
            name: "AES-CBC",
            iv
        },
        key,
        data
    )
}

// const signAlgorithm = {
//     name: "RSASSA-PKCS1-v1_5",
//     hash: {
//         name: "SHA-256"
//     },
//     modulusLength: 2048,
//     extractable: false,
//     publicExponent: new Uint8Array([1, 0, 1])
// }

const encryptAlgorithm = {
    name: "RSA-OAEP",
    modulusLength: 2048,
    publicExponent: new Uint8Array([1, 0, 1]),
    extractable: false,
    hash: {
        name: "SHA-256"
    }
}

export async function generatePublicPrivateKey(): Promise<CryptoKeyPair | CryptoKey | null> {
    const { crypto } = window

    if (crypto.subtle === undefined) return null

    const scopeEncrypt = ["encrypt", "decrypt"]
    return await generateKey(encryptAlgorithm, scopeEncrypt)
}
